import validator from "validator";
import { createObjectToValidate } from "../../../../utils/validations/helpers";

const handleValidation = (formData) => {
    let errors = {};
    let formIsValid = true;

    if ( createObjectToValidate(formData.firstName).isEmpty ) {
      formIsValid = false;
      errors["firstName"] = "Please fill in this field";
    }
    
    if ( createObjectToValidate(formData.lastName).isEmpty ) {
      formIsValid = false;
      errors["lastName"] = "Please fill in this field";
    }
    
    if (createObjectToValidate(formData.position).isEmpty) {
      formIsValid = false;
      errors["position"] = "Please fill in this field";
    }
    const userEmailValidationObj = createObjectToValidate(formData.userEmail);
    
    if (userEmailValidationObj.isEmpty) {
      formIsValid = false;
      errors["userEmail"] = "Please fill in this field";
    }
    else {
      const userEmail = userEmailValidationObj.value;
      if (!validator.isEmail(userEmail)) {
        formIsValid = false;
        errors["userEmail"] = "Please add fill in a valid email address";
      }
    } 
    const passwordValidationObj = createObjectToValidate(formData.password);
    const password = passwordValidationObj.value;
    const passwordRepeatValidationObj = createObjectToValidate(formData.passwordRepeat);
    const passwordRepeat = passwordRepeatValidationObj.value;
    if (passwordValidationObj.isEmpty) {
        formIsValid = false;
        errors["password"] = "Please confirm your password";
      }
        else if (
          !validator.isStrongPassword(password, {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minSymbols: 1,
            minNumbers: 1,
          })
        ) {
          formIsValid = false;
          errors["password"] =
            "Passwords must include at least one uppercase, lowercase letter, number and special character";
        }
    
        if (password !== passwordRepeat) {
          formIsValid = false;
          errors["passwordRepeat"] = "Password must match";
        }
    
        if (validator.isEmpty(passwordRepeat)) {
          formIsValid = false;
          errors["passwordRepeat"] = "Please confirm your password";
        }
      
    const termsAndConditions = formData.termsAndConditions;    
    if (termsAndConditions !== true) {
      formIsValid = false;
      errors["form"] =
        "Please read and accept our terms and conditions to continue";
    }

    return { formIsValid, errors };
  };

  export default handleValidation;