import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
import Heading from "../../../components/Heading";
import Text from "../../../components/Text";

const AuthedSubNavigation = () => {
  const navigationLinks = [
    { name: "Company Profile", to: "/settings" },
    
    // TODO: cleanup the component
    {
      name: "Account Information",
      to: "/settings/account-information",
    },
    {
      name: "ATS Integration",
      to: "/settings/ats-integration",
    },
  ];

  return (
    <Box>
      <Box
        backgroundColor="#1F2227"
        padding={{ xs: "140px 10px 10px", md: "160px 72px 10px" }}
      >
        <Heading styles={{ marginBottom: "18px" }}>Settings</Heading>
      </Box>
      <Box
        backgroundColor="#1F2227"
        padding={{ xs: "20px 10px", md: "8px 72px" }}
        sx={{ display: "flex", alignItems: "center", marginTop: "2px" }}
      >
        {navigationLinks.map((link, idx) => (
          <NavLink
            end
            className="authed-subnavigation-link"
            key={idx}
            to={link.to}
            style={({ isActive }) => ({
              background: isActive
                ? "rgba(233, 185, 43, 0.16) 0% 0% no-repeat padding-box"
                : "",
              borderRadius: "4px",
              padding: "8px",
              marginRight: "12px",
            })}
          >
            <Box sx={{width: 'max-content'}}>
              <Text className="authed-subnavigation-text">{link.name}</Text>
            </Box>
          </NavLink>
        ))}
      </Box>
    </Box>
  );
};

export default AuthedSubNavigation;
