import { Box } from "@mui/system";
import React from "react";
import { NavLink } from "react-router-dom";
import Heading from "./Heading";
import Section from "./Section";
import Text from "./Text";

const InsightsCard = (data) => {
  const { logoImageUrl, title, description, link } = data;

  return (
    <Box
      sx={{
        // maxWidth: "300px",
        margin: { xs: "10px", md: "40px 20px" },
        // padding: "20px",
        scrollSnapAlign: "start",
      }}
    >
      <img
        style={{
          height: "400px",
          width: "100%",
          borderRadius: "16px",
          objectFit: "cover",
          margin: "auto",
          marginBottom: "16px",
        }}
        src={logoImageUrl}
        alt={`Insight from ${title}, on Deep Tech Jobs`}
      />
      <Box sx={{ padding: "17px", textAlign: "center" }}>
        <Heading
          variant="h2"
          style={{ marginBottom: "12px", textAlign: "center" }}
          fontSize="calc((1.2 - 1) * 1.2vw + 1rem)"
        >
          {title}
        </Heading>
        <Text
          fontSize="16px"
          margin="0 auto 12px"
          width="fit-content"
          textAlign="center"
        >
          {description}
        </Text>
        <a href={link} target="_blank" rel="noreferrer">
          <Text
            color="#edbb2a;"
            fontSize="16px"
            margin="auto"
            width="fit-content"
            textAlign="center"
          >
            View Post
          </Text>
        </a>
      </Box>
    </Box>
  );
};

const InsightsContainer = (data) => {
  const { backgroundColor } = data;
  const insights = [
    {
      imageUrl:
        "https://static1.squarespace.com/static/5fd7ec9140f9b820fac20b67/t/61df53935aa28d09c7598a49/1642025875274/oliver-hardick-portrait.jpg",
      title: "Oliver Hardick",
      description: "Founder Puridify",
      link: "https://insights.deeptechjobs.com/insights/oliver-hardick",
    },
    {
      imageUrl:
        "https://static1.squarespace.com/static/5fd7ec9140f9b820fac20b67/t/61df4dd4150c032c3db15f97/1642024404554/james-arnold-portrait.jpg",
      title: "James Arnold",
      description: "Head of Strategy and Process Arrival",
      link: "https://insights.deeptechjobs.com/insights/james-arnold",
    },
    {
      imageUrl:
        "https://static1.squarespace.com/static/5fd7ec9140f9b820fac20b67/t/61a77cce329c082a4944120f/1638366415290/3.png",
      title: "Karandeep S.Bhogal",
      description: "Chief Programmes Officer Nexeon",
      link: "https://insights.deeptechjobs.com/insights/karandeep-s-bhogal",
    },
  ];
  return (
    <Section backgroundColor={backgroundColor || "DARK"} padding="0">
      <Box
        sx={{
          padding: { xs: "20px", md: "72px" },
          overflowX: "hidden",
        }}
      >
        <Box>
          <Box sx={{ textAlign: "center" }}>
            <Heading
              variant="h2"
              style={{ marginBottom: "12px", textAlign: "center" }}
              fontSize="calc((2.2 - 1) * 1.2vw + 1rem)"
            >
              Insights
            </Heading>
            <Text
              fontSize="calc((1.6 - 1) * 1.2vw + 1rem)"
              margin="auto"
              width="fit-content"
              textAlign="center"
            >
              Hear from leading Scientists and Engineers shaping Deep Tech
              industries.
            </Text>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 1fr" },
              width: "fit-content",
              margin: "auto",
              overflowX: 'hidden'
            }}
          >
            {insights.map((insight, idx) => (
              <InsightsCard {...insight} key={idx} />
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              href="https://insights.deeptechjobs.com/"
              target="_blank"
              rel="noreferrer"
            >
              <button className="button">Check Out Insights</button>
            </a>
          </Box>
        </Box>
      </Box>
    </Section>
  );
};

export default InsightsContainer;
