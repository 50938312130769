/*
  Maps Data that comes from rest Api
*/
const mapCompanyToFormData = (company) => (
    {
      id: company.id,
      companyName: company.companyName,
      logo: company.logoImageUrl,
      logoImageFile: company.logoImageUrl,
      technologyClassification: company.basicInformation.technologyClassification1,
      companyLocation: company.basicInformation.companyLocation,
      companyCity: company.basicInformation.companyCity,
      mediaUrl: company.profileMedia.MediaUrl,
      companyProfileImage: company.profileMedia.CompanyImageUrl,
      companyImageFile: company.profileMedia.CompanyImageUrl,
      companyImage: company.profileMedia.CompanyImageUrl,
      websiteUrl: company.profileMedia.Website || '',
      linkedInUrl: company.profileMedia.LinkedIn || '',
      twitterUrl: company.profileMedia.Twitter || '',
      problemStatement: company.basicInformation?.problemStatement,
      status: company.status,
      atsName: company.atsDetails.name || '',
      atsUserName: company.atsDetails.userName || '',
      atsApiKey: company.atsDetails.token || ''
    }
  );

  const mapUserToFormData = (userData) => {
    return {
      firstName: userData.firstName || '',
      lastName: userData.lastName || '',
      position: userData.position || '',
      userEmail: userData.email || ''
    }
  };

  export { mapCompanyToFormData, mapUserToFormData }