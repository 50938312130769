import { Box, Container, Grid } from "@mui/material";
import React from "react";

const CardList = (data) => {
  const { loadmore, list, type, isLoading, loadmoreFunction, component, eventHandlers, listLength } =
    data;
  const direction = type.toLowerCase();

  const Component = component;

  return (
    <Container>
      <Grid
        container
        direction={direction}
        alignItems="center"
        spacing="38px"
        rowSpacing={type === "ROW" ? "80px" : "38px"}
        columns={{ xs: 1, sm: 8, md: 12 }}
      >
        {list.map((item, key) => (
          <Grid item sx={{ width: "100%" }} xs={1} sm={8} md={4} key={key}>
            <Component item={item} isLoading={isLoading} eventHandlers={eventHandlers} />
          </Grid>
        ))}
      </Grid>
      {!isLoading && (
        <Box sx={{ marginTop: "40px" }} className="container-content">
          {(list.length < listLength || loadmore) && (
            <button
              onClick={loadmoreFunction}
              className="content-loadmore-button"
              style={{ fontSize: "18px", fontFamily: "Work Sans" }}
            >
              Load More
            </button>
          )}
        </Box>
      )}
    </Container>
  );
};

export default CardList;
