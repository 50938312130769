import HeaderComponent from "../HeaderComponent";
import CustomAutoComplete from "./AutoComplete";
import { Box } from "@mui/material";
import { popupBodyBoxStyle } from '../styles';
import { SIGNUP_STAGES } from "../constants";

const FindCompanyStep = (props) => (
  
  <>
    <HeaderComponent 
        arrowBackComponent = { null } 
        title='Add Your Company' 
    />
    <Box sx={ popupBodyBoxStyle }>
      <CustomAutoComplete
        isLoading={props.isLoading}
        setCompany= { (args) => {
          props.updateCompany(args);
          props.assignNextStep(SIGNUP_STAGES.CREATE_PROFILE);
        }}
        companies={props.companies}  
        updateForm={ (args) => { 
          props.updateForm(args);
          props.assignNextStep(SIGNUP_STAGES.CREATE_PROFILE); 
        }
      }
      />
    </Box>
  </>
);

export default FindCompanyStep;
