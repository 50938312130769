import React, { useState } from "react";
import HorizontalScroll from "../../components/HorizontalScroll";
import JobCard from "../../components/JobCard/JobCard";
import JobDetailsModal from "../../components/JobCard/JobDetailsModal";

const JobListComponent = (props) => {
    const { jobs, isLoading } = props;
    const [openModal, setOpenModal] = useState(false);
    const [viewedJob, setViewedJob] = useState({});
    return (
        <> 
            <HorizontalScroll
                        list={jobs}
                        type={"JOB_CARD"}
                        scrollForMore={true}
                        isLoading={isLoading}
                        searchAll={true}
                        component={JobCard}
                        componentClasses={["company-profile-job-card"]}
                        eventHandlers = { { viewJobClick: (job) => { 
                            setViewedJob(job);
                            setOpenModal(true); 
                          } } }
            ></HorizontalScroll>
            <JobDetailsModal open={openModal} job={viewedJob} closeHandler={() =>   setOpenModal(false)  }  />
        </>
    )
}

export default JobListComponent;