import Link from '@mui/material/Link';
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import Text from "../../components/Text";
import { mergeObjects } from "../../utils/utils";


const SuccessfulSignUpModal = (props) => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      maxWidth: "660px",
      width: "calc(100% - 40px)",
      transform: "translate(-50%, -50%)",
      background: "#161A21 0% 0% no-repeat padding-box",
      borderRadius: "16px",
      opacity: 1,
      padding: { xs: "32px", lg: "72px" },
    };
  
    const paragraphStyle = {
      marginBottom: "32px",
    };
  
    const closeHandler = () => props.closeHandler();
  
    return (
      <Modal sx={{ outline: "none" }} open={props.open}>
        <Box sx={style}>
          <Text
            fontSize="24px"
            styles={mergeObjects({ justifyContent: "center", fontWeight: 600 }, paragraphStyle)}
          >
            Welcome to Deep Tech Jobs!
          </Text>
  
          <Box sx={paragraphStyle}>
            <Text>
              There is one last step before your jobs and company profile are visible on the platform:
            </Text>
          </Box>
          <Box sx={paragraphStyle}> 
            <Text
            fontSize="24px"
            styles={mergeObjects({ justifyContent: "center", fontWeight: 400 }, paragraphStyle)}
          >
            Verify Your Email
          </Text>
         </Box> 
          <Box sx={paragraphStyle}>
            <Text>
              We have sent an email to the email associated with your acccount. 
              Once you have clicked the link in the email, your account will be verified.
            </Text>
          </Box>
          
          <Box color="#fff">
            Didn't receive the email?{" "}
            <Link component="button" underline="hover" style={{ color: "#E9B82A", fontSize: "inherit" }} 
                    onClick={props.sendEmailHandler}>
                    Send a new one.
            </Link>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "center", marginTop: "55px" }}
          >
            <Link component="button" underline="hover" onClick={closeHandler}
                style={{ 
                    cursor: "pointer", 
                    fontWeight: 600, 
                    fontSize: "18px",
                    color: "#E9B82A"
                    }}>
                   Dismiss         
            </Link>
          </Box>
        </Box>
      </Modal>
    );
  };

  export  default SuccessfulSignUpModal;