import _ from 'lodash';

const convertMedia = (html) => {
  var pattern1 = /^.+vimeo.com\/(.*\/)?([^#\?]*)/;
  var pattern2 = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;

  if (!html.match(pattern1) && !html.match(pattern2)) return '';

  if (html.match(pattern1)) {
    const match = html.match(pattern1);
    const id = match ? match[2] : null;

    html = "https://player.vimeo.com/video/" + id;
  }

  if (html.match(pattern2)) {
    const match = html.match(pattern2);
    const id = match && match[2].length === 11 ? match[2] : null;

    html = "https://www.youtube.com/embed/" + id;
  }

  return html;
};

const mergeObjects = (...argObjects) => {
  const resultingObj = {};
  for (const obj of argObjects) {
    Object.assign(resultingObj, obj);
  }
  return resultingObj;
}

const objectToQueryString = (obj) => {
  const kvPairs = Object.entries(obj) 
  const qsStart = '?';
  const qsParts = [];
  for (const [key, value] of kvPairs) {
      if(value || !_.isEmpty(value))
        qsParts.push(`${key}=${value}`);
  }
  const qs = qsStart+qsParts.join("&");
  return qs;
}

export {
    convertMedia,
    mergeObjects,
    objectToQueryString
}