import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import agents from "../agents";
import { Hero, InsightsBanner, MembershipBanner } from "../components/Banners";
import CompanyCard from "../components/CompanyCard";
import Heading from "../components/Heading";
import HorizontalScroll from "../components/HorizontalScroll";
import InsightsContainer from "../components/InsightFeatures.js";
import Section from "../components/Section";

const CompanySection = (data) => {
  const { idx, companies, isLoading } = data;
  const showAllCompanies = idx === 3 || idx === 9;

  return (
    <HorizontalScroll
      list={companies}
      type={"COMPANY_CARD"}
      scrollForMore={true}
      searchAll={showAllCompanies}
      isLoading={isLoading}
      component={CompanyCard}
    ></HorizontalScroll>
  );
};

const FooterHero = () => {
  const parameters = {
    type: "BANNER",
    title: "Do bigger things.",
    backgroundImg:
      "https://images.squarespace-cdn.com/content/v1/5fd7ec9140f9b820fac20b67/8184064a-9b3f-44d6-b3f4-53d6286b28dc/footer-background-image-rear-shot-of-engineer.png?format=2500w",
  };

  return parameters;
};

const Home = (data) => {
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadingData = new Array(10).fill(1);

  useEffect(() => {
    const fetchData = async () => {
      const response = await agents.Companies.getHomeCompanies();
      for(const key in response.data.data) {
        if(!response.data.data[key]?.length)
          delete response.data.data[key];
      }
      setCompanies(response.data);
      setIsLoading(false);
    };

    fetchData();

    return () => setCompanies({ data: {} });
  }, []);

  return (
    <>
      <Helmet>
        <title>Deep Tech Jobs - Home</title>
      </Helmet>
      <Hero />
      {isLoading
        ? loadingData.map((value, idx) => {
            let backgroundColor;

            backgroundColor = idx % 2 === 0 ? "DARK_LIGHT" : "DARK";

            if (idx > 2) {
              backgroundColor = idx % 2 === 0 ? "DARK" : "DARK_LIGHT";
            }

            return (
              <Box key={idx}>
                {idx === 3 && <InsightsContainer />}
                <Section
                  isLoading={isLoading}
                  backgroundColor={backgroundColor}
                  title={""}
                >
                  <CompanySection
                    isLoading={isLoading}
                    companies={loadingData}
                    idx={idx}
                  ></CompanySection>
                </Section>
              </Box>
            );
          })
        : Object.keys(companies.data)
            .sort(() => Math.random - 0.5)
            .map((key, idx) => {
              let backgroundColor;

              backgroundColor = idx % 2 === 0 ? "DARK_LIGHT" : "DARK";

              if (idx > 2) {
                backgroundColor = idx % 2 === 0 ? "DARK" : "DARK_LIGHT";
              }

              return (
                <Box key={idx}>
                  {idx === 3 && <InsightsBanner />}
                  {idx === 6 && (
                    <MembershipBanner
                      elevatedTitle="Company Membership"
                      title="Your company, all of your jobs, listed. For free."
                      subtitle="Start building your company profile today."
                      buttonText="Learn More About Membership"
                    />
                  )}
                  <Section
                    isLoading={isLoading}
                    backgroundColor={backgroundColor}
                    title={key}
                  >
                    <CompanySection
                      isLoading={isLoading}
                      companies={companies.data[key]}
                      idx={idx}
                    ></CompanySection>
                  </Section>
                  {idx === 8 && (
                    <MembershipBanner
                      elevatedTitle="Company Membership"
                      title="Join the world’s largest Deep Tech job community."
                      subtitle="Register your company profile."
                      buttonText="Learn More About Membership"
                    />
                  )}
                </Box>
              );
            })}
      {/* <Section {...FooterHero()}></Section> */}
    </>
  );
};

export default Home;
