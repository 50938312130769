import React, { useEffect, useState } from "react";

import { Autocomplete, Box, CircularProgress, TextField, Paper } from "@mui/material";
import Text from "../../../../components/Text";

const backgroudColors = {
  listbox: "#1F2227",
  optionHover: "#161A21"
}

const _Button = (data) => {
  const { updateForm, setOpen, value } = data;

  return (
    <button
      onClick={() => {
        updateForm({ companyName: value, isNewCompany: true });
        setOpen(false);
      }}
      style={{
        width: "100%",
        height: "100%",
        background: "transparent",
        padding: "10px 16px",
        border: "none",
        cursor: "pointer"
      }}
    >
      <Text color="#FFFFFF" styles={{ justifyContent: "center" }}>
        Create a new company?
      </Text>
    </button>
    
  );
};

const _AutoComplete = (data) => {
  const {
    isLoading,
    setCompany,
    companies,
    updateForm,
  } = data;

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  const setOnClose = () => {
    setOpen(false);
  };

  
  const listBoxStyles = { 
    backgroundColor: backgroudColors.listbox 
  }
  const paperStyles = {
    backgroundColor: backgroudColors.listbox,
    cursor: "pointer"
  }

  return (
    <Autocomplete
     ListboxProps={{
      style: listBoxStyles
    }}
    PaperComponent={({ children }) => (
      <Paper style={ paperStyles }>{ children }</Paper>
    )}
    
      sx={{
        width: "100%",
        background: "#FFFFFF14 0% 0% no-repeat padding-box",
        border: "1px solid #888888",
        borderColor: "#888888 !important",
        borderRadius: "8px",
        opacity: "1",
        letterSpacing: "0px",
        color: "#FFFFFF !important",
        opacity: "1",
        "& .MuiAutocomplete-endAdornment": {
          display: "none"
        }
      }}
      onInputChange={(_value) => {
        setValue(_value.target.value);
      }}
      open={value.length && value.length > 1}
      onOpen={() => setOpen(true)}
      onClose={() => setOnClose()}
      options={companies}
      autoHighlight
      loading={isLoading}
      noOptionsText={
        <Box sx={{ "&:hover": { backgroundColor: backgroudColors.optionHover } }}><_Button value={value} updateForm={updateForm} setOpen={setOpen} /></Box>
      }
      getOptionLabel={(option) => option.companyName}
      renderOption={(props, option) => {
        try {
          const classification = option.technologyClassification
          .toLowerCase()
          .replace("&", "")
          .replace(".", "-")
          .replace("/", "-")
          .replace("  ", " ")
          .trim()
          .split(" ")
          .join("-");

          return (
            <Box
              key={props.key}
              onClick={() => {
               setCompany({...option, ...{ isNewCompany: false }});
              }}
              >
              <Box
                component="li"
                sx={{
                  "& > img": { mr: 2, flexShrink: 0 },
                  "&.MuiAutocomplete-option": {
                    "&:hover": {
                      backgroundColor: backgroudColors.optionHover
                  }}
                }}
                {...props}
              >
                <Box
                  component="img"
                  loading="lazy"
                  sx={{
                    borderRadius: "50%",
                    width: { xs: "38px", md: "48px" },
                    height: { xs: "38px", md: "48px" },
                  }}
                  src={`${option.logo}`}
                  srcSet={`${option.logo} 2x`}
                  alt=""
                />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Box>
                    <Text
                      color="#FFFFFF"
                      sx={{ fontSize: { xs: "12px", md: "16px" } }}
                    >
                      {option.companyName}
                    </Text>
                    <Text
                      color="#FFFFFF"
                      fontSize="11px"
                      sx={{ display: { xs: "none", md: "block" } }}
                    >
                      {option.website}
                    </Text>
                  </Box>
                  <Box
                    sx={{
                      flex: { xs: "100%", md: "unset" },
                      marginLeft: "auto",
                    }}
                  >
                    {classification && option.technologyClassification && (
                      <Text id={classification}>
                        {option.technologyClassification}
                      </Text>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        } catch (e) {
          console.log(e);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Your company name"
          inputProps={{
            ...params.inputProps,
            autoComplete: "off",
            endadornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endadornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default _AutoComplete;
