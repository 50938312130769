import React, { useRef, useState, useEffect } from "react"; 
import { useNavigate, redirect } from 'react-router-dom'
import { Box, MenuItem, Select, Button } from "@mui/material";
import Text from "../../../../components/Text";
import HeaderComponent from "../HeaderComponent";
import ArrowBackComponent from "../ArrowBackComponent";
import { SIGNUP_STAGES } from "../constants";
import { popupBodyBoxStyle } from "../styles";
import handleValidation from "./formValidation";
import SuccessfulSignUpModal from '../../../CompanyProfile/SuccessfulSignUpModal';
//import FieldTooltip from './FieldTooltip';
import FieldTooltip from "../../../../components/FieldTooltip/FieldTooltip";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Collapse from '@mui/material/Collapse';
import { modalActions } from "../../constants";
import editCompanyProfile from "../../../CompanyProfile/editProfileActions";
import { selectCompanyFormData } from "../helpers";
import agents from "../../../../agents";
import { COUNTRY_CODES } from "../../../../constants/countries";
import { ATS_NAMES } from "../../../../constants/atsNames";
import { Company_Profile_Upload } from "../../../../constants/company";

const dropDownMenuStyles = {
  "& .MuiMenu-list": {
    backgroundColor: "#1F2227",
    color: "#d2d3d8",
    "& .MuiMenuItem-root": {
      "&:hover": { backgroundColor: "#161A21" },
    },
  },
};


const getCountryMenuItems = () => { 
    const items = [];
    for(const key in COUNTRY_CODES) {
      items.push(
        <MenuItem key={key} value={COUNTRY_CODES[key]}>{COUNTRY_CODES[key]}</MenuItem>
      )
    }
    return items; 
};

const CreateCompanyProfileStep = (props) => {
  const navigate = useNavigate();
  
  //useRef
  const fileInputLogo = useRef(null);
  const fileInputCompanyImage = useRef(null);

  // useState
  const [formData, setFormData] = useState(props.formData);
  const [errors, setErrors] = useState({});
  const [requestIsLoading, setRequestIsLoading] = useState(false);

  // useEffect
  useEffect( () => {
    return () =>  { 
      if(formData.logoPreview)
        URL.revokeObjectURL(formData.logoPreview);
      if(formData.companyImagePreview)
        URL.revokeObjectURL(formData.companyImagePreview);   
    };
  }, [] );

  // event handlers
  const onArrowBackClick = () =>
    props.assignNextStep(SIGNUP_STAGES.FIND_COMPANY);
  
  const onSelectFile = (e) => {
    //setErrors({});
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file.size > 307200) {
      setErrors({ logo: "File size cannot exceed 300kb" });
      return;
    }
    const logoPreview = URL.createObjectURL(file);
    setFormData({ ...formData, logoImageFile: file, logoPreview: logoPreview });
  };

  const onSelectCompanyImageFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if(formData.companyImagePreview)
      URL.revokeObjectURL(formData.companyImagePreview);
    const companyImagePreview = URL.createObjectURL(file);
    setFormData({ ...formData, companyImageFile: file, companyImagePreview: companyImagePreview });  
  };
  
  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  };

  const handleRadioButtonChange = (prop) => (event) => {
    const newData = {
      [prop]: event.target.value
    };
    if(event.target.value === Company_Profile_Upload.Video) {
       newData.companyImageFile = '';
       URL.revokeObjectURL(formData.companyImagePreview);
       newData.companyImagePreview = null;
    }
      
    else if (event.target.value === Company_Profile_Upload.Image) {
      newData.mediaUrl = '';
    }
      
    setFormData({ ...formData, ...newData });  
  };

  const sendAtsRequest = async (atsName, atsUserName, atsApiKey) => {
    try {
      const responseAts = await agents.CompanyProfile.checkAtsDetails({
        atsName: atsName,
        atsUserName: atsUserName,
        atsApiKey: atsApiKey,
      });
      if (!responseAts.data?.isRequestSuccessfull) {
        return {success: false, message: "Please check the ats userName or token." }
      }
      return {success: true};   
    } catch (ex) {
      return {success: false, message: `Can't perform the ats check.` };
    }
  }

  const handleSubmit = async (e) => {
    setErrors({});
    setRequestIsLoading(true);
    
    const { formIsValid, convertedMediaUrl, errors } = await handleValidation(
      formData
    );

    if (!formIsValid) {
      setErrors(errors);
      setRequestIsLoading(false);
      return;
    }

    const atsCheckResult = await sendAtsRequest(formData.atsName, formData.atsUserName, formData.atsApiKey);
    if(!atsCheckResult.success) {
       setErrors({
        serverError: atsCheckResult.message
       });
       setRequestIsLoading(false);
       return; 
    }


    props.updateCompany({ ...formData, mediaUrl: convertedMediaUrl });
    // need this line to send the converted link to the server.
    formData.mediaUrl = convertedMediaUrl;
    switch (props.action) {
      case modalActions.create:
        props.assignNextStep(SIGNUP_STAGES.CREATE_ACCOUNT);
        setRequestIsLoading(false);
        break;
      case modalActions.edit:
        const { success } = await editCompanyProfile( selectCompanyFormData(formData), props.companyStatus); 
        if(success) {
          setRequestIsLoading(false);
          navigate(`/companies/profile${window.location.search}`, {
            state: { updateTime: Date.now() },
          });
          props.onSuccessFlow();
        }
        else {
          setErrors({
            serverError: "Failed to perform the updates.",
          });
          setRequestIsLoading(false);
        }
        break;
    }
  };
  
  return (
    <>
      <HeaderComponent
        arrowBackComponent={ 
          props.action == modalActions.create ? <ArrowBackComponent onClick={onArrowBackClick} /> : null 
        }
        title="Add Your Company"
      />
      <Box sx={popupBodyBoxStyle}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ marginTop: "16px" }}
        >
          <Box
            className="membership-flex"
            sx={{
              marginBottom: "16px",
            }}
          >
            <input
              type="text"
              name="search"
              placeholder="Company Name"
              onChange={handleChange("companyName")}
              value={formData.companyName}
              className="membership-input"
              autoComplete="off"
            />
            {errors["companyName"] && (
              <Text
                styles={{ flex: "0 0 100%" }}
                fontSize="14px"
                color="#ffcccc"
              >
                {errors["companyName"]}
              </Text>
            )}
          </Box>
          <Box className="membership-flex" sx={{ marginBottom: "16px" }}>
            <Box
              className="membership-flex"
              sx={{
                flex: { xs: "100%", sm: "1" },
                marginRight: { xs: "0", sm: "16px" },
                marginBottom: { xs: "16px", md: "0" },
              }}
            >
              <input
                type="text"
                name="search"
                placeholder="City"
                onChange={handleChange("companyCity")}
                value={formData.companyCity}
                className="membership-input"
                autoComplete="off"
              />
              {errors["companyCity"] && (
                <Text
                  styles={{ flex: "0 0 100%" }}
                  fontSize="14px"
                  color="#ffcccc"
                >
                  {errors["companyCity"]}
                </Text>
              )}
            </Box>

            <Box
              className="membership-flex"
              sx={{
                flex: { xs: "100%", sm: "1" },
                marginRight: { xs: "0", sm: "16px" },
                marginBottom: { xs: "16px", md: "0" },
              }}
            >
              <Select
                className="membership-input"
                MenuProps={{ sx: dropDownMenuStyles }}
                sx={{ paddingLeft: "8px !important" }}
                value={formData.companyLocation || ""}
                onChange={handleChange("companyLocation")}
                displayEmpty
                renderValue={
                  formData.companyLocation === ""
                    ? () => (
                        <Box
                          sx={{
                            fontFamily: "Work Sans",
                            color: "#d2d3d8",
                          }}
                        >
                          Location
                        </Box>
                      )
                    : undefined
                }
              >
                { getCountryMenuItems() }
                
              </Select>
              {errors["companyLocation"] && (
                <Text
                  styles={{ flex: "0 0 100%" }}
                  fontSize="14px"
                  color="#ffcccc"
                >
                  {errors["companyLocation"]}
                </Text>
              )}
            </Box>

            <Box className="membership-flex">
              {/* <FormControl fullWidth> */}
              <Select
                className="membership-input"
                MenuProps={{ sx: dropDownMenuStyles }}
                sx={{ paddingLeft: "8px !important" }}
                value={formData.technologyClassification}
                onChange={handleChange("technologyClassification")}
                displayEmpty
                renderValue={
                  formData.technologyClassification === ""
                    ? () => (
                        <Box
                          sx={{
                            fontFamily: "Work Sans",
                            color: "#d2d3d8",
                          }}
                        >
                          Technology
                        </Box>
                      )
                    : undefined
                }
              >
                <MenuItem value="Advanced Engineering">
                  Advanced Engineering
                </MenuItem>
                <MenuItem value="Advanced Materials">
                  Advanced Materials
                </MenuItem>
                <MenuItem value="Aerospace &amp; Drones">
                  Aerospace &amp; Drones
                </MenuItem>
                <MenuItem value="AI/ML">AI/ML</MenuItem>
                <MenuItem value="Biotech">Biotech</MenuItem>
                <MenuItem value="Cybersecurity">Cybersecurity</MenuItem>
                <MenuItem value="Energy &amp; Climate Tech">
                  Energy &amp; Climate Tech
                </MenuItem>
                <MenuItem value="Food &amp; AgTech">Food &amp; AgTech</MenuItem>
                <MenuItem value="Hardware &amp; Electronics">
                  Hardware &amp; Electronics
                </MenuItem>
                <MenuItem value="Immersive Tech">Immersive Tech</MenuItem>
                <MenuItem value="Industry 4.0 &amp; Robotics">
                  Industry 4.0 &amp; Robotics
                </MenuItem>
                <MenuItem value="MedTech &amp; Healthtech">
                  MedTech &amp; Healthtech
                </MenuItem>
                <MenuItem value="Mobility Tech">Mobility Tech</MenuItem>
                <MenuItem value="Quantum Computing">Quantum Computing</MenuItem>
                <MenuItem value="Web 3 &amp; Blockchain">
                  Web 3 &amp; Blockchain
                </MenuItem>
              </Select>
              {errors["technologyClassification"] && (
                <Text
                  styles={{ flex: "0 0 100%" }}
                  fontSize="14px"
                  color="#ffcccc"
                >
                  {errors["technologyClassification"]}
                </Text>
              )}
            </Box>
          </Box>
          <Box className="membership-flex" sx={{ marginBottom: "16px" }}>
            <Box
              className="membership-flex"
              sx={{
                flex: { xs: "100%", sm: "1" },
                marginRight: { xs: "0", sm: "16px" },
                marginBottom: { xs: "16px", md: "0" },
              }}
            >
              {formData.logoPreview || formData.logo ? (
                <img
                  src={formData.logoPreview || formData.logo}
                  alt=""
                  style={{
                    width: "48px",
                    height: "48px",
                    /* UI Properties */
                    background:
                      "transparent url('img/Image 150.png') 0% 0% no-repeat padding-box",
                    borderRadius: "48px",
                    opacity: "1",
                    marginRight: "16px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: "48px",
                    height: "48px",
                    background: "#888888",
                    borderRadius: "48px",
                    opacity: "1",
                    marginRight: "16px",
                  }}
                ></Box>
              )}
              
              <button
                type="button"
                onClick={() => fileInputLogo.current.click()}
                className="membership-button"
              >
                <Text styles={{marginLeft: '10px'}}>Company Logo</Text>
                <FieldTooltip title="Upload Your Company Logo [png/jpeg]" buttonStyle={{padding: '2px'}} />
              </button>
              <input
                hidden
                ref={fileInputLogo}
                type="file"
                accept="image/png, image/jpeg"
                onChange={onSelectFile}
              />
              {errors["logo"] && (
                <Text
                  styles={{ flex: "0 0 100%" }}
                  fontSize="14px"
                  color="#ffcccc"
                >
                  {errors["logo"]}
                </Text>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                flexWrap: "wrap",
                flex: 1,
              }}
            >
              <Collapse sx={{width: '100%'}} in={formData.companyProfileUpload === Company_Profile_Upload.Video || !formData.companyProfileUpload}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    
                  }}
                >
                  <input
                    type="text"
                    name="search"
                    placeholder="Video Url"
                    onChange={handleChange("mediaUrl")}
                    value={formData.mediaUrl}
                    autoComplete="off"
                    className="membership-input"
                    style={{paddingRight: '36px', marginRight: '-34px'}}
                  />
                  <FieldTooltip title="Add the link to a YouTube or Vimeo video." />
                  {errors["mediaUrl"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["mediaUrl"]}
                    </Text>
                  )}
                  {!errors["mediaUrl"] && (
                    <Text
                      fontSize="11px"
                      color="#98999D"
                      styles={{
                        marginTop: "5px",
                        justifyContent: "flex-start",
                        flex: "0 0 100%",
                      }}
                    >
                    </Text>
                  )}
                  
                </Box>
              </Collapse>
              <Collapse sx={{width: '100%'}} in={formData.companyProfileUpload === Company_Profile_Upload.Image}>
                  <Box
                  className="membership-flex"
                  sx={{
                    flex: { xs: "100%", sm: "1" },
                    marginRight: { md: '0', xs: "0", sm: "16px" },
                    marginBottom: { xs: "16px", md: "0" },
                  }}
                  >
                  {formData.companyImagePreview || formData.companyImage ? (
                    <img
                      src={formData.companyImagePreview || formData.companyImage}
                      alt=""
                      style={{
                        width: "48px",
                        height: "48px",
                        /* UI Properties */
                        background:
                          "transparent url('img/Image 150.png') 0% 0% no-repeat padding-box",
                        borderRadius: "48px",
                        opacity: "1",
                        marginRight: "16px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "48px",
                        height: "48px",
                        background: "#888888",
                        borderRadius: "48px",
                        opacity: "1",
                        marginRight: "16px",
                      }}
                    ></Box>
                  )}
                  
                  <button
                    type="button"
                    onClick={() => fileInputCompanyImage.current.click()}
                    className="membership-button"
                  >
                    <Text styles={{marginLeft: '10px'}}>Company Image</Text>
                    <FieldTooltip title="Upload an image to be displayed on your profile [jpeg/png]" buttonStyle={{padding: '2px'}} />
                  </button>
                  <input
                    hidden
                    ref={fileInputCompanyImage}
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={onSelectCompanyImageFile}
                  />
                  {errors["companyImage"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["companyImage"]}
                    </Text>
                  )}
                </Box>    
              </Collapse>
              
              <RadioGroup defaultValue={ formData.companyProfileUpload || Company_Profile_Upload.Video } row onChange={ handleRadioButtonChange('companyProfileUpload') }>
                  <FormControlLabel label={<Text fontSize='16px'>Add Video</Text>} value={Company_Profile_Upload.Video} control={<Radio size="small" sx={{color: '#fff'}} />} />
                  <FormControlLabel label={<Text fontSize='16px'>Upload Image</Text>}  value={Company_Profile_Upload.Image} control={<Radio size="small" sx={{color: '#fff'}} />} />          
              </RadioGroup>
            </Box>
          </Box>

          <Box sx={{ marginBottom: "16px" }}>
            <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
              <textarea name="search" style={{ marginRight: '-34px'}} 
                  value={formData.problemStatement}
                  placeholder="Company Description" rows="3" 
                  autoComplete="off" className="membership-textarea" 
                  onChange={handleChange("problemStatement")}>
              </textarea>
              <FieldTooltip title="Tell us about your company and the technology it is using 
              to solve a specific problem." />
              
              {errors["problemStatement"] && (
                <Text
                  styles={{ flex: "0 0 100%" }}
                  fontSize="14px"
                  color="#ffcccc"
                >
                  {errors["problemStatement"]}
                </Text>
              )}

              {!errors["problemStatement"] && (
                <Text
                  fontSize="11px"
                  color="#98999D"
                  styles={{
                    marginTop: "5px",
                    justifyContent: "flex-start",
                    flex: "0 0 100%",
                  }}
                >
                </Text>
              )}
            </Box>
          </Box>
          <Box className="membership-flex" sx={{ marginBottom: "16px" }}>
            <Box
              className="membership-flex"
              sx={{
                flex: { xs: "100%", sm: "1" },
                marginRight: { xs: "0", sm: "16px" },
                marginBottom: { xs: "16px", md: "0" },
              }}
            >
              <input
                type="text"
                name="websiteUrl"
                placeholder="Website Url"
                onChange={handleChange("websiteUrl")}
                value={formData.websiteUrl}
                className="membership-input"
                autoComplete="off"
              />
              {errors["websiteUrl"] && (
                <Text
                  styles={{ flex: "0 0 100%" }}
                  fontSize="14px"
                  color="#ffcccc"
                >
                  {errors["websiteUrl"]}
                </Text>
              )}
            </Box>
            <Box
              className="membership-flex"
              sx={{
                flex: { xs: "100%", sm: "1" },
                marginRight: { xs: "0", sm: "16px" },
                marginBottom: { xs: "16px", md: "0" },
              }}
            >
              <input
                type="text"
                name="linkedInUrl"
                placeholder="LinkedIn Url"
                onChange={handleChange("linkedInUrl")}
                value={formData.linkedInUrl}
                className="membership-input"
                autoComplete="off"
              />
              {errors["linkedInUrl"] && (
                <Text
                  styles={{ flex: "0 0 100%" }}
                  fontSize="14px"
                  color="#ffcccc"
                >
                  {errors["linkedInUrl"]}
                </Text>
              )}
            </Box>
            <Box
              className="membership-flex"
              sx={{
                flex: { xs: "100%", sm: "1" },
                marginRight: { xs: "0", sm: "0" },
                marginBottom: { xs: "16px", md: "0" },
              }}
            >
              <input
                type="text"
                name="twitterUrl"
                placeholder="Twitter Url"
                onChange={handleChange("twitterUrl")}
                value={formData.twitterUrl}
                className="membership-input"
                autoComplete="off"
              />
              {errors["twitterUrl"] && (
                <Text
                  styles={{ flex: "0 0 100%" }}
                  fontSize="14px"
                  color="#ffcccc"
                >
                  {errors["twitterUrl"]}
                </Text>
              )}
            </Box>    
          </Box> 
          <HeaderComponent title="Ats Integration" /> 
          <Box className="membership-flex" sx={{ marginBottom: "16px" }}>
          <Box
              className="membership-flex"
              sx={{
                flex: { xs: "100%", sm: "1" },
                marginRight: { xs: "0", sm: "16px" },
                marginBottom: { xs: "16px", md: "0" },
              }}
            >
              <Select
                className="membership-input"
                MenuProps={{ sx: dropDownMenuStyles }}
                sx={{ paddingLeft: "8px !important" }}
                value={formData.atsName || ""}
                onChange={handleChange("atsName")}
                displayEmpty
                renderValue={
                  !formData.atsName 
                    ? () => (
                        <Box
                          sx={{
                            fontFamily: "Work Sans",
                            color: "#d2d3d8",
                          }}
                        >
                          Applicant Tracking System
                        </Box>
                      )
                    : undefined
                }
              >
                <MenuItem value={ATS_NAMES.GREEN_HOUSE}>Greenhouse</MenuItem>
                <MenuItem value={ATS_NAMES.LEVER}>Lever</MenuItem>
                <MenuItem value={ATS_NAMES.PERSONIO}>Personio</MenuItem>
                <MenuItem value={ATS_NAMES.RECRUITEE}>Recruitee</MenuItem>
                <MenuItem value={ATS_NAMES.SMART_RECRUITERS}>Smartrecruiters</MenuItem>
                <MenuItem value={ATS_NAMES.TEAM_TAILOR}>Teamtailor</MenuItem>
                <MenuItem value={ATS_NAMES.WORKABLE}>Workable</MenuItem>
              </Select>
              {errors["atsName"] && (
              <Text
                styles={{ flex: "0 0 100%" }}
                fontSize="14px"
                color="#ffcccc"
              >
                {errors["atsName"]}
              </Text>
            )}
            </Box>
            <Collapse in={formData.atsName != 'teamtailor' || !formData.atsName}>
            <Box
              className="membership-flex"
              sx={{
                flex: { xs: "100%", sm: "1" },
                marginRight: { xs: "0", sm: "0px" },
                
                marginBottom: { xs: "16px", md: "0" },
              }}
            >
              <input
                type="text"
                name="atsUserName"
                placeholder="ATS username"
                onChange={handleChange("atsUserName")}
                value={formData.atsUserName}
                className="membership-input"
                autoComplete="off"
                style={{ marginRight: '-34px'}}
              />
              <FieldTooltip 
                title={ <div style={{ whiteSpace: 'pre-line' }}>
                {`Provide the username to your selected applicant system.\n
                This can also be found in the URL for your company on the ATS:\n
                https://apply.workable.com/{USERNAME}/
                https://jobs.lever.co/{USERNAME}/
                https://boards.greenhouse.io/{USERNAME}/
                https://careers.{USERNAME}.com/jobs
                https://{USERNAME}.recruitee.com
                https://{USERNAME}.bamboohr.com/jobs/
                https://{USERNAME}.jobs.personio.de/
                `}</div>} buttonStyle={{marginTop: '8px'}}/>
                 {errors["atsUserName"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["atsUserName"]}
                    </Text>
                  )}
            </Box>
            </Collapse>
            </Box>
            <Collapse in={formData.atsName == 'teamtailor'}>
              <Box className="membership-flex" sx={{ marginBottom: "16px" }}> 
              <Box
                className="membership-flex"
                sx={{
                  flex: { xs: "100%", sm: "1" },
                  marginRight: { xs: "0", sm: "0px" },
                  marginBottom: { xs: "16px", md: "0" },
                }}
              >
                <input
                  type="text"
                  name="atsApiKey"
                  placeholder="API Key"
                  onChange={handleChange("atsApiKey")}
                  value={formData.atsApiKey}
                  className="membership-input"
                  autoComplete="off"
                  style={{ marginRight: '-34px'}}
                />
                <FieldTooltip 
                  title={<div style={{ whiteSpace: 'pre-line' }}>
                  {`Only necessary for users of Teamtailor.\n
                  Your API key can be found `} <a href="https://app.teamtailor.com/app/settings/integrations/api_keys">here</a></div>} 
                  buttonStyle={{marginTop: '8px'}}/>
                  {errors["atsApiKey"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["atsApiKey"]}
                    </Text>
                  )}
              </Box>
            </Box>
            </Collapse>
            
          
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <button
              type="button"
              onClick={() => handleSubmit()}
              style={{
                background: "transparent",
                border: "none",
                margin: "auto",
              }}
            >
              <Text
                style={{ justifyContent: "center", cursor: "pointer" }}
                fontSize="18px"
                color="#E9B82A"
                isLoading={requestIsLoading}
              >
                { props.action === modalActions.create ? 'Preview Profile' : 'Make Changes' }
              </Text>
            </button>
            </Box>
            <Box>
              {errors["serverError"] && (
                  <Text
                    styles={{ justifyContent: "center" }}
                    fontSize="14px"
                    color="#ffcccc"
                    marginTop="10px"
                  >
                    {errors["serverError"]}
                  </Text>
                )}
              </Box>
        </Box>
      </Box>
    </>
  );
  
};

export default CreateCompanyProfileStep;
