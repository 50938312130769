import _ from "lodash"

const createObjectToValidate = (value) => {
    if(_.isString(value))
        value = value.trim();
    if(_.isEmpty(value))
        return {
            value: '',
            isEmpty: true
        };
    return {
        value: value,
        isEmpty: false
    }        
};

export { createObjectToValidate }