const STORAGE_API_NAMES = {
    WORKABLE: 'workable',
    LEVER: 'lever',
    GREEN_HOUSE: 'greenhouse',
    RECRUITEE: 'recruitee',
    PERSONIO: 'personio',
    SMART_RECRUITERS: 'smartrecruiters',
    TEAM_TAILOR: 'teamtailor'
};

export { STORAGE_API_NAMES as ATS_NAMES  };