import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

import agents from "../agents";
import CardList from "../components/CardList";
import JobCard from "../components/JobCard/JobCard";
import Section from "../components/Section";
import Text from "../components/Text";
import { searchQuery } from "../utils/searchQuery";
import Heading from "../components/Heading";
import { Helmet } from "react-helmet";
import { objectToQueryString } from "../utils/utils";
import JobDetailsModal from '../components/JobCard/JobDetailsModal';
import { getCountryOptions } from "../utils/ui/htmlGenerators";

const JobsSearch = (data) => {
  const { isLoading, clearSearch, query, querySubmitted } = data;
  const emptyFormData = {
    search: "",
    city: "",
    location: "",
    technology: "",
    onlyRemoteJobs: false
  };

  const [formData, setFormData] = useState(emptyFormData);

  const handleClearSearch = () => {
    setFormData(emptyFormData);
    clearSearch();
  };

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  };

  const handleOnlyRemoteCheckboxClick = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.checked });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // const response = searchQuery(query, formData);
    querySubmitted(objectToQueryString(formData));
  };

  const technology = [
    "Advanced Engineering",
    "Advanced Materials",
    "Aerospace & Drones",
    "AI/ML",
    "Biotech",
    "Cybersecurity",
    "Energy & Climate Tech",
    "Food & AgTech",
    "Hardware & Electronics",
    "Immersive Tech",
    "Industry 4.0 & Robotics",
    "MedTech & Healthtech",
    "Mobility Tech",
    "Quantum Computing",
    "Web 3 & Blockchain",
  ];
  return (
    <>
      <form name="query" id="search-filter" onSubmit={onSubmit}>
        <div className="search-filter-flex-container">
          <Box
            sx={{ display: "flex", width: { xs: "100%", lg: "50%" } }}
            className="search-filter-flex-container-component"
          >
            <Text>
              <SearchIcon style={{ marginRight: "10px" }} />
            </Text>
            <input
              type="text"
              id="position-search"
              name="search"
              placeholder="Search keyword"
              onChange={handleChange("search")}
              value={formData.search}
            />
          </Box>
          <Box
            sx={{ display: "flex", width: { xs: "100%", lg: "50%" } }}
            className="search-filter-flex-container-component"
          >
            <input
              type="text"
              name="city"
              placeholder="City"
              onChange={handleChange("city")}
              value={formData.city}
            />
          </Box>
          <div className="search-filter-flex-container-component">
            <select
              name="location"
              id="location-select-dark"
              onChange={handleChange("location")}
              value={formData.location}
            >
              <option value="">Location</option>
              {getCountryOptions()}
            </select>
          </div>
          <div className="search-filter-flex-container-component">
            <select
              name="technology"
              id="technology-select-dark"
              onChange={handleChange("technology")}
              value={formData.technology}
            >
              <option value="">Technology</option>
              {technology.map((value, key) => (
                <option value={value} key={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <Box width={{ xs: "100%", lg: "15%" }}>
            <button
              type="submit"
              id="submit-search"
              style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
              disabled={isLoading}
            >
              Search
            </button>
          </Box>
        </div>
        <Box style={{ marginTop: "10px", marginLeft: "10px" }}>
          {(() =>
            formData.onlyRemoteJobs ? (
              <input
                type="checkbox"
                id="chOnlyRemoteJobs"
                name="chOnlyRemoteJobs"
                onChange={handleOnlyRemoteCheckboxClick("onlyRemoteJobs")}
                checked
              />
            ) : (
              <input
                type="checkbox"
                id="chOnlyRemoteJobs"
                name="chOnlyRemoteJobs"
                onChange={handleOnlyRemoteCheckboxClick("onlyRemoteJobs")}
              />
            ))()}
          <label
            style={{ marginLeft: "10px", color: "#FFF", fontSize: "0.86rem" }}
            for="chOnlyRemoteJobs"
          >
            Search for Remote Jobs
          </label>
        </Box>
      </form>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <p
          style={{
            width: "fit-content",
            cursor: "pointer",
            color: "#edbb2a",
            fontSize: "16px",
            padding: "3px 4px",
            fontFamily: "Work Sans",
            marginBottom: "10px",
          }}
          onClick={handleClearSearch}
        >
          Clear search
        </p>
        {/* <Text>Number of results: {isLoading ? 0 : jobsLength}</Text> */}
      </Box>
    </>
  );
};

const JobsSection = (data) => {
  const { loadmore, jobs, loadmoreFunction, isLoading, jobsLength } = data;
  const [openModal, setOpenModal] = useState(false);
  const [viewedJob, setViewedJob] = useState({});
  return (
    <>
      <CardList
        type="COLUMN"
        list={jobs}
        loadmore={loadmore}
        loadmoreFunction={loadmoreFunction}
        component={JobCard}
        isLoading={isLoading}
        eventHandlers = { { viewJobClick: (job) => { 
          setViewedJob(job);
          setOpenModal(true); 
        } } }
      ></CardList>
      <JobDetailsModal open={openModal} job={viewedJob} closeHandler={() =>   setOpenModal(false)  }  />
    </>
  );
};

const Jobs = () => {
  const [jobs, setJobs] = useState(new Array(10).fill(""));
  const [loadmore, setLoadmore] = useState(false);
  let [jobsRequestCounter, setJobsRequestCounter] = useState(1);

  const [isLoading, setIsLoading] = useState(true);
  const firstLoadQuery = { page: 1 };
  const [query, setQuery] = useState(firstLoadQuery);

  //  appendMore
  const loadmoreFunction = async () => {};

  const scrollHandlerObj = getScrollHandlerFunction();
  const querySubmitted = async (response) => {
    setQuery((v) => ({ ...v, query: response }));
    setJobsRequestCounter(1);
    document.dispatchEvent(
      new CustomEvent("scroll", { detail: { resetCounter: true } })
    );
    const jobsResponse = await agents.Jobs.getJobs(response);
    setJobs(jobsResponse.data.data);
    setIsLoading(false);
  };

  const clearSearch = async () => {
    setIsLoading(true);
    setQuery(firstLoadQuery);
    setJobsRequestCounter(1);
    document.dispatchEvent(
      new CustomEvent("scroll", { detail: { resetCounter: true } })
    );
    const response = await agents.Jobs.getJobs(objectToQueryString(firstLoadQuery)) 
    setJobs(response.data.data);
    setIsLoading(false);
  };

  function getScrollHandlerFunction() {
    var lastScroll = 0;
    var loadJobsCounter = 0;
    const getCurrentScrollPosition = () =>
      document.documentElement.scrollTop || document.body.scrollTop;
    // checks scroll direction, the function uses last lastScroll variable
    const isScrollDirectionDown = () => {
      const currentScroll = getCurrentScrollPosition(); // Get Current Scroll Value
      if (currentScroll > 0 && lastScroll <= currentScroll) {
        lastScroll = currentScroll;
        return true;
      } else {
        lastScroll = currentScroll;
        return false;
      }
    };
    return {
      handler: (e) => {
        if (e.detail && e.detail.resetCounter) {
          loadJobsCounter = 0;
        } else {
          if (isScrollDirectionDown()) {
            const currentScrollPosition = getCurrentScrollPosition();
            if (currentScrollPosition > 5000 * loadJobsCounter) {
              loadJobsCounter++;
              setJobsRequestCounter(loadJobsCounter);
            }
          }
        }
      },
    };
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const qs = objectToQueryString(query);
    (async () => {
      const response = await agents.Jobs.getJobs(qs);
      setJobs(response.data.data);
      setIsLoading(false);
    })();
    const scrollHandler = scrollHandlerObj.handler;
    document.addEventListener("scroll", scrollHandler);
    return () => {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  useEffect(() => {
    if (jobsRequestCounter > 1) {
      const pagePartQs = `page=${jobsRequestCounter}`;
      let queryString;
      if (query.query) queryString = `${query.query}&${pagePartQs}`;
      else queryString = `?${pagePartQs}`;
      (async () => {
        const response = await agents.Jobs.getJobs(queryString);
        setJobs(jobs.concat(response.data.data));
        setIsLoading(false);
      })();
    }
  }, [jobsRequestCounter]);

  return (
    <>
      <Helmet>
        <title>Deep Tech Jobs - Jobs</title>
      </Helmet>
      <Box
        backgroundColor="DARK"
        padding={{ xs: "140px 10px 20px", md: "160px 72px 72px" }}
      >
        <Container>
          <JobsSearch
            query={query}
            isLoading={isLoading}
            querySubmitted={querySubmitted}
            clearSearch={clearSearch}
          />
        </Container>
      </Box>
      <Section padding="0">
        {jobs.length ? (
          <JobsSection
            isLoading={isLoading}
            loadmore={loadmore}
            jobs={jobs}
            loadmoreFunction={loadmoreFunction}
          />
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", paddingTop: '20px' }}>
            <Heading
              variant="h2"
              style={{
                display: "block",
                textAlign: "center",
                margin: "auto",
                padding: "40px 8px",
              }}
            >
              No Search Results Found
            </Heading>
          </Box>
        )}
      </Section>
    </>
  );
};

export default Jobs;
