import { Box } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";

import Section from "./Section";
import Text from "./Text";

const Footer = () => {
  return (
    <footer>
      <div>
        <Section padding="0">
          <Box
            sx={{
              padding: { xs: "20px 20px 120px", lg: "80px 72px" },
              display: "flex",
              flexDirection: { xs: "column", md: "unset" },
              justifyContent: { xs: "center", md: "unset" },
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                margin: { xs: "10px 0", md: 0 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  <NavLink to="/">
                    <img
                      src="/DTJ-Logo-Footer.png"
                      style={{
                        width: "42px",
                      }}
                      alt="Deep Tech Jobs Logo"
                    />
                  </NavLink>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="https://www.linkedin.com/company/deep-tech-jobs/"
                    target="_blank"
                  >
                    <Text marginRight="10px">
                      <LinkedInIcon />
                    </Text>
                  </a>
                  <a
                    href="https://www.instagram.com/deeptechjobs/"
                    target="_blank"
                  >
                    <Text marginRight="10px">
                      <InstagramIcon />
                    </Text>
                  </a>

                  <a href="https://twitter.com/deeptechjobs" target="_blank">
                    <Text>
                      <TwitterIcon />
                    </Text>
                  </a>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: { xs: "10px 0", md: 0 },
                  justifyContent: { xs: "flex-start", md: "center" },
                }}
              >
                <Box>
                  <Text>&copy; Deep Tech Jobs Limited.</Text>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                alignItems: "flex-start",
                margin: { xs: "10px 0", md: 0 },
                justifyContent: { xs: "flex-start", md: "flex-end" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  marginRight: "80px",
                }}
              >
                <NavLink to="/contact">
                  <Text>Contact</Text>
                </NavLink>
                <NavLink to="/companies">
                  <Text>Companies</Text>
                </NavLink>
                <NavLink to="/jobs">
                  <Text>Jobs</Text>
                </NavLink>
                <a href="https://insights.deeptechjobs.com" target="_blank">
                  <Text>Insights</Text>
                </a>
                <NavLink to="/join">
                  <Text>Membership</Text>
                </NavLink>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <NavLink to="/terms-of-use">
                  <Text>Terms of Use</Text>
                </NavLink>
                <NavLink to="/terms-of-business">
                  <Text>Terms of Business</Text>
                </NavLink>
                <NavLink to="/privacy-policy">
                  <Text>Privacy Policy</Text>
                </NavLink>
              </Box>
            </Box>
          </Box>
        </Section>
      </div>
    </footer>
  );
};

export default Footer;
