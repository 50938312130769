import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Heading from "../../../../components/Heading";
import AuthedSubNavigation from "../AuthedSubNavigation";
import Collapse from '@mui/material/Collapse';
import FieldTooltip from "../../../../components/FieldTooltip/FieldTooltip";
import {  MenuItem, Select } from "@mui/material";
import { mapCompanyToFormData } from "../../../../data/mappers";
import { useNavigate } from "react-router-dom";

import agents from "../../../../agents";
import Text from "../../../../components/Text";
import Section from "../../../../components/Section";
import { useAuth } from "../../../../context/AuthContext";
import { Helmet } from "react-helmet";
import validateAtsIntegration from "./formValidation";
import CompleteCommandModal from "../CompleteCommandModal";
import { useModal } from "../../../../context/ModalContext";
import { notAuthorizedHandler } from "../../../../utils/errors/responseErrorHandlers";
import { RESPONSE_STATUS_CODES } from "../../../../constants/response";
import { ATS_NAMES } from "../../../../constants/atsNames";

const sendAtsRequest = async (atsName, atsUserName, atsApiKey) => {
  try {
    const responseAts = await agents.CompanyProfile.checkAtsDetails({
      atsName: atsName,
      atsUserName: atsUserName,
      atsApiKey: atsApiKey,
    });
    if (!responseAts.data?.isRequestSuccessfull) {
      return {success: false, message: "Please check the ats userName or token." }
    }
    return {success: true};   
  } catch (ex) {
    return {success: false, message: `Can't perform the ats check.` };
  }
}


const AuthedAtsIntegration = () => {
  const [formData, setFormData] = useState({});
  const [passwordData, setPasswordData] = useState({});
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [modalErrorProp, setModalErrorProp] = useState({});
  const { updateModal } = useModal();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [textIsLoading, setTextIsLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState({});

  const { updatePassword, updateEmail } = useAuth();

  const dropDownMenuStyles = {
    "& .MuiMenu-list": {
      backgroundColor: "#1F2227",
      color: "#d2d3d8",
      "& .MuiMenuItem-root": {
        "&:hover": { backgroundColor: "#161A21" },
      },
    },
  };

  const style = {
    maxWidth: "800px",
    width: { xs: "100%", sm: "calc(100% - 40px)" },
    margin: "0 auto 0",
    borderRadius: "16px",
    overflow: "hidden",
    opacity: 1,
    marginTop: "32px",
    marginBottom: "32px"
  };

  const topBottomStyle = {
    background: "#1F2227 0% 0% no-repeat padding-box",
    padding: { xs: "20px", sm: "20px 32px" },
  };

  const boxStyle = {
    padding: { xs: "20px", sm: "20px 32px" },
    marginTop: "2px",
    background: "#1F2227 0% 0% no-repeat padding-box",
  };

  const fetchData = async () => {
    try {
      const response = await agents.CompanyProfile.getCompanyProfile();
      const atsDetails = response.data;
      setFormData(mapCompanyToFormData(response.data));
      //updateCompany(response.data);
      setIsLoading(false);
    }  
    catch(e) {
      if(e.response?.status === RESPONSE_STATUS_CODES.NOT_AUTHORIZED) {
        notAuthorizedHandler();
        // this opens login popup.
        updateModal(true);
      }
      else if(e.response?.status === RESPONSE_STATUS_CODES.ACCESS_FORBIDDEN) {
        navigate('/404');
      }
      else {
        setModalErrorProp({ message: `Failed to Load Company Data.
          Please sign-out and sign-in, then try again. 
          If this issue persists, please contact support.` });
        setOpenSuccessModal(true);
      }   
    }
    };

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  };

  

  const handleUpdateAts = async () => {
    setErrors({});
    const { formIsValid, errors } = validateAtsIntegration(formData);

    if (!formIsValid) {
      setErrors(errors);
      return;
    }
    try {
      const atsCheckResult = await sendAtsRequest(formData.atsName, formData.atsUserName, formData.atsApiKey);
      if(!atsCheckResult.success) {
        setErrors({
          atsCheckError: atsCheckResult.message
        });
        setIsLoading(false);
        return; 
     }
    }
    catch(e) {
      setErrors({
        atsCheckError: "The Ats username wasn't checked."
      });
      return;
    }
    try {
      const response = await agents.CompanyProfile.updateCompanyProfileSendJson(formData);
      setOpenSuccessModal(true);
    }
    catch(e){
      setModalErrorProp({ message: `Failed to Update Company Data.
          Please sign-out and sign-in, then try again. 
          If this issue persists, please contact support.` });
      setOpenSuccessModal(true);
    }



  }
  // const handleUpdateUserInfo = async () => {
  //   setUserInfoErrors({});
      
  //   const { formIsValid, errors } = validateUserInfo(formData);

  //   if (!formIsValid) {
  //     setUserInfoErrors(errors);
  //     return;
  //   }
  //   try {
  //       const result = await agents.CompanyUser.putCompanyUser(formData);
  //       setOpenSuccessModal(true);
  //   }
  //   catch(ex){
  //       setModalErrorProp({ message: `Failed to Update Company Data.
  //         Please sign-out and sign-in, then try again. 
  //         If this issue persists, please contact support.` });
  //       setOpenSuccessModal(true);
  //   }
  // };

  // const handleUpdatePassword = async () => {
  //   setPasswordErrors({});
      
  //   const { formIsValid, errors } = await validatePassword(passwordData);

  //   if (!formIsValid) {
  //     setPasswordErrors(errors);
  //     return;
  //   }
  //   try {
  //       const result = await agents.CompanyUser.putCompanyUser({ password: passwordData.password });
  //       setOpenSuccessModal(true);
  //   }
  //   catch(ex){
  //       setModalErrorProp({ message: `Failed to Update Company Data.
  //         Please sign-out and sign-in, then try again. 
  //         If this issue persists, please contact support.` });
  //       setOpenSuccessModal(true);
  //   }
  // }

  useEffect(async () => {
    await fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Deep Tech Jobs - ATS Integration</title>
      </Helmet>
      <AuthedSubNavigation />
      <Section
        backgroundColor="DARK"
        padding={{ xs: "32px 10px 10px", md: "32px 72px 0" }}
      >
        {!isLoading && (
          <Box sx={{ ...style }}>
            <Box sx={{ ...topBottomStyle }}>
              <Heading fontSize="18px" styles={{ marginBottom: "8px" }}>
                ATS Integration
              </Heading>
            </Box>
            <Box sx={{ ...boxStyle }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "12px",
                }}
              >
                <Text>Applicant Tracking System</Text>
                <Text onClick={handleUpdateAts}>
                  <Box
                    color="#E9B82A"
                    component="span"
                    sx={{ cursor: "pointer !important" }}
                  >
                    Update API Connection
                  </Box>
                </Text>
              </Box>
              <Box className="membership-flex" sx={{ marginBottom: "16px" }}>
          <Box
              className="membership-flex"
              sx={{
                flex: { xs: "100%", sm: "1" },
                marginRight: { xs: "0", sm: "16px" },
                marginBottom: { xs: "16px", md: "0" },
              }}
            >
              <Select
                className="membership-input"
                MenuProps={{ sx: dropDownMenuStyles }}
                sx={{ paddingLeft: "8px !important" }}
                value={formData.atsName || ""}
                onChange={handleChange("atsName")}
                displayEmpty
                renderValue={
                  !formData.atsName 
                    ? () => (
                        <Box
                          sx={{
                            fontFamily: "Work Sans",
                            color: "#d2d3d8",
                          }}
                        >
                          Applicant Tracking System
                        </Box>
                      )
                    : undefined
                }
              >
                {!formData.atsName && <MenuItem value=''>Applicant Tracking System</MenuItem>}
                <MenuItem value={ATS_NAMES.GREEN_HOUSE}>Greenhouse</MenuItem>
                <MenuItem value={ATS_NAMES.LEVER}>Lever</MenuItem>
                <MenuItem value={ATS_NAMES.PERSONIO}>Personio</MenuItem>
                <MenuItem value={ATS_NAMES.RECRUITEE}>Recruitee</MenuItem>
                <MenuItem value={ATS_NAMES.SMART_RECRUITERS}>Smartrecruiters</MenuItem>
                <MenuItem value={ATS_NAMES.TEAM_TAILOR}>Teamtailor</MenuItem>
                <MenuItem value={ATS_NAMES.WORKABLE}>Workable</MenuItem>
              </Select>
              {errors["atsName"] && (
              <Text
                styles={{ flex: "0 0 100%" }}
                fontSize="14px"
                color="#ffcccc"
              >
                {errors["atsName"]}
              </Text>
            )}
            </Box>
            <Collapse in={formData.atsName != 'teamtailor' || !formData.atsName}>
            <Box
              className="membership-flex"
              sx={{
                flex: { xs: "100%", sm: "1" },
                marginRight: { xs: "0", sm: "0px" },
                
                marginBottom: { xs: "16px", md: "0" },
              }}
            >
              <input
                type="text"
                name="atsUserName"
                placeholder="ATS username"
                onChange={handleChange("atsUserName")}
                value={formData.atsUserName}
                className="membership-input"
                autoComplete="off"
                style={{ marginRight: '-34px'}}
              />
              <FieldTooltip 
                title={ <div style={{ whiteSpace: 'pre-line' }}>
                {`Provide the username to your selected applicant system.\n
                This can also be found in the URL for your company on the ATS:\n
                https://apply.workable.com/{USERNAME}/
                https://jobs.lever.co/{USERNAME}/
                https://boards.greenhouse.io/{USERNAME}/
                https://careers.{USERNAME}.com/jobs
                https://{USERNAME}.recruitee.com
                https://{USERNAME}.bamboohr.com/jobs/
                https://{USERNAME}.jobs.personio.de/
                `}</div>} buttonStyle={{marginTop: '8px'}}/>
                 
            </Box>
            
            {errors["atsUserName"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["atsUserName"]}
                    </Text>
                  )}
                  
            </Collapse>
            </Box>
            <Collapse in={formData.atsName == 'teamtailor'}>
              <Box className="membership-flex" sx={{ marginBottom: "16px" }}> 
              <Box
                className="membership-flex"
                sx={{
                  flex: { xs: "100%", sm: "1" },
                  marginRight: { xs: "0", sm: "0px" },
                  marginBottom: { xs: "16px", md: "0" },
                }}
              >
                <input
                  type="text"
                  name="atsApiKey"
                  placeholder="API Key"
                  onChange={handleChange("atsApiKey")}
                  value={formData.atsApiKey}
                  className="membership-input"
                  autoComplete="off"
                  style={{ marginRight: '-34px'}}
                />
                <FieldTooltip 
                  title={<div style={{ whiteSpace: 'pre-line' }}>
                  {`Only necessary for users of Teamtailor.\n
                  Your API key can be found `} <a href="https://app.teamtailor.com/app/settings/integrations/api_keys">here</a></div>} 
                  buttonStyle={{marginTop: '8px'}}/>
                  
              </Box>
            </Box>
            {errors["atsApiKey"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["atsApiKey"]}
                    </Text>
                  )}
            </Collapse>
            {errors["atsCheckError"] && (
            <Text
              styles={{ flex: "0 0 100%" }}
              fontSize="14px"
              color="#ffcccc"
            >
              {errors["atsCheckError"]}
            </Text>
          )}
            </Box>
          </Box>
          
        )}
      </Section>
      <CompleteCommandModal open={openSuccessModal} setOpen={setOpenSuccessModal} error={modalErrorProp} 
          clearError={() => { 
            if(modalErrorProp !== {}) 
              setModalErrorProp({}) 
            }}  
      />
    </>
  );
};

export default AuthedAtsIntegration;

