import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { NavLink } from "react-router-dom";

import { Cross as Hamburger } from "hamburger-react";
import _ from 'lodash';
import Text from "./Text";
import { useAuth } from "../context/AuthContext";
import agents from "../agents";
import { useUser } from "../context/UserContext";
import { useNavigate } from "react-router-dom";

const Header = (data) => {
  const { onLoginClicked } = data;

  const [isOpen, setIsOpen] = useState(false);
  const { currentUser, logout } = useAuth();
  const { userDetails, resetUser } = useUser();
  const navigate = useNavigate();

  // TODO NEW VERSION: remove authenicated as not in use 
  // const authenicated = currentUser && userDetails.companyId !== null;

  // TODO NEW VERSION: remove authenticatedAndPremium as not in use
  // const authenticatedAndPremium =
  //   currentUser && userDetails.companyId !== null && userDetails.isPremium;

  const isAuthenticated = !_.isEmpty(currentUser);
  const onLogout = async () => {
    await logout();
    await agents.removeTokens();
    await resetUser();
    navigate('/');

    setIsOpen((v) => !v);
  };

  useEffect(() => {
    // if (currentUser && userDetails.companyId === null) {
    //   onLogout();
    // }
  }, []);


  return (
    <>
      <nav className="nav">
        <div className="nav-container">
          <div className="nav-container-logo">
            <NavLink
              onClick={() => {
                if (isOpen) setIsOpen(false);
              }}
              to="/"
              className="nav-link nav-link-logo"
            >
              <img
                src="/DTL-Logo-White.png"
                className="nav-logo"
                alt="Deep Tech Jobs Logo"
              />
            </NavLink>
          </div>
        </div>
        <Box
          display={{
            xs: "none",
            md: "block",
          }}
          className="nav-desktop"
        >
          <ul className="nav-list">
            <li className="nav-item">
              <NavLink to="/" className="nav-link">
                Home
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/companies" className="nav-link">
                Explore Companies
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/jobs" className="nav-link">
                Explore Jobs
              </NavLink>
            </li>
            {!isAuthenticated && (
              <>
                <li className="nav-item">
                  <NavLink to="/join" className="nav-link">
                    Add Your Company
                  </NavLink>
                </li>
                <li className="nav-item">
                  <Box
                    onClick={() => onLoginClicked()}
                    className="nav-link"
                    sx={{ cursor: "pointer" }}
                  >
                    Sign In
                  </Box>
                </li>
              </>
            )}
            {isAuthenticated && currentUser.emailVerified && (
              <li className="nav-item">
                <NavLink to="/settings" className="nav-link">
                  Settings
                </NavLink>
              </li>
            )}
            {isAuthenticated && (
              <li className="nav-item">
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => onLogout()}
                  className="nav-link"
                >
                  Logout
                </Box>
              </li>
            )}
          </ul>
        </Box>
        <Box
          display={{
            xs: "block",
            md: "none",
          }}
          className="nav-mobile"
        >
          <Box
            className="nav-menu-button-container"
            onClick={() => setIsOpen((v) => !v)}
          >
            <button className="nav-menu-button">
              <Hamburger color="#fff" toggled={isOpen} />
            </button>
          </Box>
          {isOpen && (
            <div className="nav-content">
              <ul className="nav-list">
                <li className="nav-item">
                  <NavLink
                    onClick={() => setIsOpen((v) => !v)}
                    to="/"
                    className="nav-link"
                  >
                    <Text width="fit-content" fontSize="28px" margin="auto">
                      Home
                    </Text>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    onClick={() => setIsOpen((v) => !v)}
                    to="/companies"
                    className="nav-link"
                  >
                    <Text width="fit-content" fontSize="28px" margin="auto">
                      Explore Companies
                    </Text>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    onClick={() => setIsOpen((v) => !v)}
                    to="/jobs"
                    className="nav-link"
                  >
                    <Text width="fit-content" fontSize="28px" margin="auto">
                      Explore Jobs
                    </Text>
                  </NavLink>
                </li>
                {!isAuthenticated && (
                  <>
                    <li className="nav-item">
                      <NavLink
                        onClick={() => setIsOpen((v) => !v)}
                        to="/join"
                        className="nav-link"
                      >
                        <Text width="fit-content" fontSize="28px" margin="auto">
                            Add Your Company
                        </Text>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <Box
                        onClick={() => {
                          setIsOpen((v) => !v);
                          onLoginClicked();
                        }}
                        className="nav-link"
                        sx={{ cursor: "pointer" }}
                      >
                        <Text width="fit-content" fontSize="28px" margin="auto">
                          Sign In
                        </Text>
                      </Box>
                    </li>
                  </>
                )}
                {isAuthenticated && (
                  <li className="nav-item">
                    <NavLink
                      onClick={() => setIsOpen((v) => !v)}
                      to="/settings"
                      className="nav-link"
                    >
                      <Text width="fit-content" fontSize="28px" margin="auto">
                        Settings
                      </Text>
                    </NavLink>
                  </li>
                )}
                {isAuthenticated && (
                  <li className="nav-item">
                    <Box
                      onClick={() => {
                        setIsOpen((v) => !v);
                        onLogout();
                        
                      }}
                      className="nav-link"
                      sx={{ cursor: "pointer" }}
                    >
                      <Text width="fit-content" fontSize="28px" margin="auto">
                        Logout
                      </Text>
                    </Box>
                  </li>
                )}
              </ul>
            </div>
          )}
        </Box>
        {isOpen && (
          <Box
            display={{
              xs: "block",
              md: "none",
            }}
            className="nav-overlay"
          ></Box>
        )}
      </nav>
    </>
  );
};

export default Header;
