import { Box, Container } from "@mui/material";
import React  from "react";
import { NavLink } from "react-router-dom";
import Text from "../Text";
import { displayJobPostedDate } from '../../utils/date'; 
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import { viewJobButtonStyle } from './jobCardStyles';
import urlCreator from "../../utils/urlCreator";


const tagFormatter = (value) => {
  const { text } = value;
  let formattedClass = "";
  if(text !== undefined && text !== null && text.trim())
    formattedClass = text
      .toLowerCase()
      .replace("&", "")
      .replace(".", "-")
      .replace("/", "-")
      .replace("  ", " ")
      .trim()
      .split(" ")
      .join("-");

  return `<p class="card-sector ${formattedClass}"><span>${text}</span></p>`;
};

export const LoadingJobCard = (data) => {
  let { componentClasses } = data;

  if (!componentClasses) componentClasses = [];
  return (
    <Box
      sx={{
        height: "300px",
        maxWidth: "1000px",
        width: "100%",
      }}
      className="skeleton-card"
    >
      <Box sx={{ height: "100%" }} className="skeleton-wrapper">
        <Box sx={{ height: "100%" }} className="skeleton-wrapper-inner">
          <Container className="skeleton-wrapper-body">
            {!componentClasses?.includes("company-profile-job-card") && (
              <>
                <div className="skeleton-avatar"></div>
                <Box
                  sx={{
                    maxWidth: "160px",
                    width: "100% !important",
                  }}
                  className="skeleton-author"
                ></Box>
                <Box
                  sx={{
                    maxWidth: "170px",
                    width: "100% !important",
                  }}
                  className="skeleton-label"
                ></Box>
              </>
            )}
            {componentClasses?.includes("company-profile-job-card") && (
              <>
                <Box
                  sx={{
                    maxWidth: "160px",
                    width: "100% !important",
                    left: "0 !important",
                  }}
                  className="skeleton-author"
                ></Box>
                <Box
                  sx={{
                    maxWidth: "170px",
                    width: "100% !important",
                    left: "0 !important",
                  }}
                  className="skeleton-label"
                ></Box>
              </>
            )}

            <Box
              sx={{
                width: "100% !important",
                left: "0 !important",
                right: "0 !important",
              }}
              className="skeleton-content-position"
            ></Box>
            <Box
              sx={{
                left: "0 !important",
                right: "0 !important",
                width: "100% !important",
              }}
              className="skeleton-content-location"
            ></Box>
            <div className="skeleton-content-sector"></div>
            <div className="skeleton-content-technology"></div>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

const JobCard = (data) => {
  let { isLoading, componentClasses } = data;
  const job = data.item;
  const viewJobClick = data.eventHandlers.viewJobClick.bind(this, job);
  const jobDatePosted = displayJobPostedDate(job.datePosted);
  const remoteChip = <Chip sx={{marginLeft: '17px', marginTop: '3px'}} label="Remote" color="secondary" size="small" />;
  if (!componentClasses) componentClasses = [];
  const renderLocation = (job) => {
    const locationChain = [];
     if(job.city)
        locationChain.push(job.city);
     if(job.state)
        locationChain.push(job.state);
     if(job.country && job.country !== job.city)
        locationChain.push(job.country);
    return locationChain.join(', ');
  };

  return isLoading ? (
    <LoadingJobCard componentClasses={componentClasses}></LoadingJobCard>
  ) : (
    <Box
      sx={{
        padding: "32px 32px 56px",
        boxShadow:
          "2px 2px 8px rgb(96 96 96 / 32%), -2px -2px 8px rgb(0 0 0 / 40%)",
        borderRadius: "8px",
        margin: "8px 0",
        backgroundColor: "#1f2227",
        maxWidth: "1000px",
        width: "100%",
        background:
          "transparent linear-gradient(112deg,#161a21 0%,#201d39 100%) 0% 0% no-repeat padding-box",
        marginRight:
          componentClasses &&
          componentClasses.includes("company-profile-job-card") &&
          "30px",
        height: "100%",
      }}
    >
      <Container style={{ display: "flex", flexDirection: "column" }}>
        {componentClasses &&
          !componentClasses.includes("company-profile-job-card") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flex: "1 1 auto",
                margin: "0 0 22px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: "1 1 auto",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "60px", marginRight: "20px" }}>
                  <img
                    alt={`Profile of job - ${job.companyName} on Deep Tech Jobs`}
                    style={{ width: "100%", borderRadius: "50%" }}
                    src={job.companyLogoUrl}
                  />
                </Box>
                <Box>
                  <Text fontWeight="600">{job.companyName}</Text>
                  <a
                    target="_blank"
                    href={ urlCreator.companyProfile(job.companyId) }
                  >
                    <Text color="#edbb2a" fontSize="13px" fontWeight="500">
                      Company Profile
                    </Text>
                  </a>
                </Box>
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                }}
              >
                <Button 
                  sx = { viewJobButtonStyle }
                  onClick = { viewJobClick }
                >
                      View Job
                </Button>
                <Text fontSize="13px">
                  Posted { jobDatePosted }
                </Text>
              </Box>
            </Box>
          )}

        <Box
          style={{
            margin: "0 0 12px",
          }}
        >
          <Text
            fontWeight="600"
            marginBottom="4px"
            fontSize="calc((1.2 - 1) * 1.2vw + 1rem)"
          >
            {job.jobTitle}
          </Text>
          <Text>
            { renderLocation(job) } { ( job.isRemote === true ) ? remoteChip : null } 
          </Text>
        </Box>
        <Box>
          <div
            className="job-card-category-container"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <span
              style={{ whiteSpace: "nowrap", marginBottom: "4px" }}
              dangerouslySetInnerHTML={{
                __html: tagFormatter({
                  text: job.category,
                }),
              }}
            ></span>
          </div>
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
          }}
        >
          <Button 
                  sx = { viewJobButtonStyle }
                  onClick = { viewJobClick }
                >
                View Job
          </Button>
          <Text fontSize="13px">
              Posted { jobDatePosted }
          </Text>
        </Box>
        {componentClasses &&
          componentClasses.includes("company-profile-job-card") && (
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                flexDirection: "column",
                alignItems: "flex-start",
                marginTop: "20px",
              }}
            >
              <Button 
                  sx = { viewJobButtonStyle }
                  onClick = { viewJobClick }
                >
                    View Job
                </Button>
              <Text fontSize="13px">
                  Posted { jobDatePosted }
              </Text>
            </Box>
          )}
      </Container>
    </Box>
  );
};

export default JobCard;