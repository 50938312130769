import { Box } from "@mui/material";
import React, { useState } from "react";
import { PopupModal } from "react-calendly";
import Heading from "./Heading";

const Calendly = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box>
      <Box
        onClick={() => {
          setIsOpen(true);
        }}
        sx={{
          cursor: "pointer",
        }}
      >
        <Heading
          fontSize="24px"
          color="#E9B82A"
          styles={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          Schedule time to speak with us.
        </Heading>
      </Box>

      <PopupModal
        url="https://calendly.com/d/dtm-dvm-f2j/deep-tech-jobs-intro"
        // pageSettings={this.props.pageSettings}
        // utm={this.props.utm}
        // prefill={this.props.prefill}
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
      />
    </Box>
  );
};

export default Calendly;
