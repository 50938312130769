import { Box, Typography } from "@mui/material";
import React from "react";

const HeadingLoading = () => (
  <Box
    sx={{
      height: "28px",
      width: "100%",
      maxWidth: "420px",
      background: "#373d46",
      marginBottom: "25px",
    }}
  ></Box>
);

const Heading = (data) => {
  const { fontSize, sx, type, color, styles, variant, isLoading, children } =
    data;

  const HEADING_STYLES = {
    display: "inline-block",
    color: color || "#fdfdfd",
    fontSize: fontSize || "calc((2.1 - 1) * 1.2vw + 1rem)",
    lineHeight: "1.2em",
    fontFamily: "Work Sans",
    fontWeight: "600",
    fontStyle: "normal",
    letterSpacing: "0em",
    textTransform: "none",
    marginBottom: sx?.marginBottom || styles?.marginBottom || "40px",
    ...styles,
  };

  const _styles = type === "HERO" ? "" : HEADING_STYLES;
  const className = type === "HERO" ? "header-hero" : "";

  return isLoading ? (
    <HeadingLoading></HeadingLoading>
  ) : (
    <Typography
      color={color}
      className={className}
      {...styles}
      {..._styles}
      sx={{
        fontSize:
          type === "HERO"
            ? {
                xs: "32px",
                sm: "40px",
                md: "48px",
                lg: "52px",
              }
            : fontSize || "calc((2.2 - 1) * 1.2vw + 1rem)",
      }}
      variant={variant}
    >
      {children}
    </Typography>
  );
};

export default Heading;
