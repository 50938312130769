import { Box } from "@mui/material";


/*
    This HOC is used to hide the elements we don'e want to diplay now, 
    but perhaps would like to do it later 
*/
const DisplayNoneWrapper = ({ children }) => 
    <Box style={{ display: "none" }}> { children } </Box>

export default DisplayNoneWrapper;    