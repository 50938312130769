import agents from "../../agents";

const editCompanyProfile = async (companyData, companyStatus) => {
   try{
        await agents.CompanyProfile.updateCompanyProfileOnPreview(
          { ...companyData, ...{status: companyStatus} }
        );
        return { success: true };
   }
   catch(ex) {
        return { success: false };
   }
};

export default editCompanyProfile;