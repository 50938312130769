import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from "./InfoIcon";

const toolTipStyle = {
    sx: {
      "& .MuiTooltip-tooltip": {
      border: "solid grey 0.5px",
      background: 'white',
      color: "#282828",
      borderRadius: "0px"
    }
  }
};

const onClickStopPropagation = (e) => e.stopPropagation();

const FieldTooltip = ({title, buttonStyle}) => 
        <Tooltip title={title} PopperProps={toolTipStyle}>
                <IconButton sx={buttonStyle} onClick={onClickStopPropagation}>
                    <InfoIcon height="18px" />
                </IconButton>
        </Tooltip>

export default FieldTooltip;
