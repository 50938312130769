import Link from '@mui/material/Link';
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import Text from "../Text";
import { displayJobPostedDate } from '../../utils/date'; 
import Chip from '@mui/material/Chip';
import urlCreator from '../../utils/urlCreator';

function decodeHtml(html) {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const remoteChip = <Chip sx={{marginLeft: '17px', marginTop: '3px'}} label="Remote" color="secondary" size="small" />;
const renderLocation = (job) => {
      const locationChain = [];
       if(job.city)
          locationChain.push(job.city);
       if(job.state)
          locationChain.push(job.state);
       if(job.country)
          locationChain.push(job.country);
      return locationChain.join(', ');
};

const JobDetailsModal = (props) => {
    const style = {
      position: "absolute",
      top: "55%",
      left: "50%",
      maxWidth: "860px",
      maxHeight: "620px",
      width: "calc(100% - 40px)",
      transform: "translate(-50%, -50%)",
      background: "transparent linear-gradient(112deg,#161a21 0%,#201d39 100%) 0% 0% no-repeat padding-box",
      borderRadius: "10px",
      opacity: 1,
      padding: { xs: "32px", lg: "50px" }
    };
    
    const scrollStyles = {
      overflow: 'auto',
      /* width */
      '&::-webkit-scrollbar': {
        width: '10px',
        borderRadius: '16px',
        height: '10px',
        background: "transparent linear-gradient(112deg,#161a21 0%,#201d39 100%) 0% 0% no-repeat padding-box"
      },
      
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: "transparent linear-gradient(112deg,#161a21 0%,#201d39 100%) 0% 0% no-repeat padding-box"
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '16px'
      },
      
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555' 
      },
      '&::-webkit-scrollbar-corner': {
        background: '#201d39'
      }
    };
    const { closeHandler, job } = props;
    const jobDatePosted = displayJobPostedDate(job.datePosted);
    return (
      <Modal sx={{ outline: "none" }} open={props.open} onClose={closeHandler}>
        <Box sx={{...style, ...scrollStyles}}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: "flex", flex: "1 1 auto", alignItems: "center" }}>
            <Box sx={{ width: "60px", marginRight: "20px" }}>
                    <img
                      alt={`Profile of job - ${job.companyName} on Deep Tech Jobs`}
                      style={{ width: "100%", borderRadius: "50%" }}
                      src={job.companyLogoUrl}
                    />
            </Box>
            <Box>
                  <Text fontWeight="600">{job.companyName}</Text>
                  <Link underline="hover" target="_blank" href={ urlCreator.companyProfile(job.companyId) }
                    sx={{ 
                        fontWeight: 500, 
                        fontSize: "13px",
                        color: "#edbb2a"
                        }}>
                        Company Profile         
                  </Link>                
            </Box>
          </Box>
          <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flex: "1 1 auto",
                margin: "0 0 22px",
              }}
          >
              <Box sx={{
                    margin: "10px 0 12px",
                  }}
              >
                <Text
                  fontWeight="600"
                  marginBottom="4px"
                  fontSize="calc((1.2 - 1) * 1.2vw + 1rem)"
                >
                  {job.jobTitle}
                </Text>
                <Text>
                  { renderLocation(job) } { ( job.isRemote === true ) ? remoteChip : null } 
                </Text>
            </Box>
            <Box sx={{
                  display: 'flex',
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                  marginBottom: '15px'
                }}
            >
              <Link underline="hover" target="_blank" href={job.jobUrl}
                sx={{ 
                    fontWeight: 500, 
                    fontSize: "17px",
                    color: "#edbb2a"
                    }}>
                   Apply to Job         
              </Link>
              <Text fontSize="13px">
                  Posted { jobDatePosted }
              </Text>
            </Box>
          </Box>
          </Box>
          <Box>
            <Text styles={ { marginBottom: '20px'} } fontWeight="700">
                Description
            </Text>
            <Box color='#fff' dangerouslySetInnerHTML={{ __html: decodeHtml(job.jobDescription) }}></Box>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "center", marginTop: "55px" }}
          >
            <Link underline="hover" target="_blank" href={job.jobUrl}
                sx={{ 
                    fontWeight: 600, 
                    fontSize: "18px",
                    color: "#E9B82A"
                    }}>
                   Apply to Job         
            </Link>
          </Box>
        </Box>
      </Modal>
    );
  };

  export  default JobDetailsModal;