const isProduction = {
  REACT_APP_FIREBASE_API_KEY: "AIzaSyDQrHip6jm9yjIiLd1sdABdfEQpLsviwB8",
  REACT_APP_FIREBASE_AUTH_DOMAIN: "deeptechjobs-production.firebaseapp.com",
  REACT_APP_FIREBASE_PROJECT_ID: "deeptechjobs-production",
  REACT_APP_FIREBASE_STORAGE_BUCKET: "deeptechjobs-production.appspot.com",
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: "323138630601",
  REACT_APP_FIREBASE_APP_ID: "1:323138630601:web:19d5b6340133c29c5cee18",
  REACT_APP_FIREBASE_MEASUREMENT_ID: "G-GL4MT7HEM9"
};

const isDevelopment = {
  REACT_APP_FIREBASE_API_KEY: "AIzaSyDg98cH8QxCaDZ_ar4UnejTxNMtuWsAdd0",
  REACT_APP_FIREBASE_AUTH_DOMAIN: "deeptechjobs-7024a.firebaseapp.com",
  REACT_APP_FIREBASE_PROJECT_ID: "deeptechjobs-7024a",
  REACT_APP_FIREBASE_STORAGE_BUCKET: "deeptechjobs-7024a.appspot.com",
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: "829415900299",
  REACT_APP_FIREBASE_APP_ID: "1:829415900299:web:d9754bba1a61eeda63408e",
  REACT_APP_FIREBASE_MEASUREMENT_ID: "G-4MPBQGS5ZJ"
};

export default process.env.NODE_ENV === "development" ||
process.env.REACT_APP_NODE_ENV === "staging"
  ? isDevelopment
  : isProduction;