import { Box, Modal } from "@mui/material";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import validator from "validator";

import agents from "../agents";
import { useAuth } from "../context/AuthContext";
import { useUser } from "../context/UserContext";
import Text from "./Text";
import urlCreator from "../utils/urlCreator";

const LoginModal = (data) => {
  const { login, resetPassword, currentUser, logout } = useAuth();
  const { setUser } = useUser();
  const { open, setOpen } = data;

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [process, setProcess] = useState("login");
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxWidth: "800px",
    width: { xs: "calc(100% - 10px)", md: "calc(100% - 40px)" },
    transform: "translate(-50%, -50%)",
    background: "#161A21 0% 0% no-repeat padding-box",
    borderRadius: "16px",
    opacity: 1,
    padding: { xs: "20px", md: "72px" },
  };

  const handleValidation = async () => {
    let _errors = {};
    let formIsValid = true;

    if (!validator.isEmail(formData.email)) {
      formIsValid = false;
      _errors["email"] = "Please add fill in a valid email address";
    }

    if (validator.isEmpty(formData.email)) {
      formIsValid = false;
      _errors["email"] = "Please fill in this field";
    }

    if (validator.isEmpty(formData.password) && process === "login") {
      formIsValid = false;
      _errors["password"] = "Please fill in this field";
    }

    setErrors(_errors);

    return formIsValid;
  };

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  };

  const onForgotPassword = async () => {
    const formIsValid = await handleValidation();

    if (!formIsValid) {
      return;
    }

    try {
      const response = resetPassword(formData.email);

      setProcess("forgot_password_success");
    } catch (e) {
      const code = e.code;

      setErrors({
        form: "Failed to send request, please try again",
      });
    }
  };

  const onSubmit = async () => {
    const formIsValid = await handleValidation();

    if (!formIsValid) {
      return;
    }

    try {
      const response = await login(formData);

      const token = response.user._delegate.accessToken;

      await agents.setToken(token);

      delete formData.password;

      try {
        const _response = await agents.Auth.login(formData, token);
        agents.setBackendToken(_response.data.backendToken);
        navigate( urlCreator.companyProfile(_response.data.companyId));
          //window.location.reload();
        setOpen(false);

          // updateCompanyProfile((v) => ({ ...v, data: response.data }));
        setIsLoading(false);

      } catch (err) {
        if (currentUser) {
          logout();
          agents.removeTokens()
        }

        setErrors({
          form: "Failed to sign in, server error. Please try again",
        });
      } 
    } catch (e) {
      setErrors({
        form: "Failed to sign in, please review",
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setProcess("login");
        setErrors({});
        setOpen(!open);
      }}
    >
      <Box sx={style}>
        <Box>
          {process === "login" && (
            <form id="signInForm" noValidate onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
              <Text
                fontSize="24px"
                styles={{ textAlign: "center", justifyContent: "center" }}
              >
                Sign in to your company account
              </Text>
              <Box sx={{ margin: "20px 0" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                >
                  <Box
                    className="membership-flex"
                    sx={{
                      marginBottom: "16px",
                      width: "100%",
                    }}
                  >
                    <input
                      type="email"
                      name="email"
                      required
                      onChange={handleChange("email")}
                      className="membership-input"
                      placeholder="Email address"
                    />
                    {errors["email"] && (
                      <Text
                        styles={{ flex: "0 0 100%" }}
                        fontSize="14px"
                        color="#ffcccc"
                      >
                        {errors["email"]}
                      </Text>
                    )}
                  </Box>
                  <Box
                    className="membership-flex"
                    sx={{
                      marginBottom: "16px",
                      width: "100%",
                    }}
                  >
                    <input
                      type="password"
                      name="password"
                      required
                      className="membership-input"
                      onChange={handleChange("password")}
                      placeholder="Password"
                    />
                    {errors["password"] && (
                      <Text
                        styles={{ flex: "0 0 100%" }}
                        fontSize="14px"
                        color="#ffcccc"
                      >
                        {errors["password"]}
                      </Text>
                    )}
                    <Box sx={{ marginTop: "10px", flex: "0 0 100%" }}>
                      <Box
                        onClick={() => {
                          setFormData({
                            email: "",
                            password: "",
                          });
                          setErrors({});

                          setProcess("forgot_password");
                        }}
                      >
                        <Text
                          fontSize="14px"
                          color="#edbb2a"
                          sx={{ cursor: "pointer" }}
                        >
                          I forgot my password.
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ marginTop: "10px", flex: "0 0 100%" }}>
                    {errors["form"] && (
                      <Text
                        styles={{ flex: "0 0 100%" }}
                        fontSize="14px"
                        color="#ffcccc"
                      >
                        {errors["form"]}
                      </Text>
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "auto",
                    }}
                  >
                    <button
                      type="submit" 
                      form="signInForm" 
                      value="Submit"
                      style={{
                        background: "transparent",
                        border: "none",
                        margin: "auto",
                      }}
                    >
                      <Text
                        style={{ justifyContent: "center", cursor: "pointer" }}
                        fontSize="22px"
                        color="#E9B82A"
                      >
                        Sign In
                      </Text>
                      <Box component="p" color="#fff" fontSize="14px">
                        Don’t have an account?{" "}
                        <NavLink to="/join" onClick={() => setOpen(false)}>
                          <Box component="span" color="#E9B82A">
                            Register here.
                          </Box>
                        </NavLink>
                      </Box>
                    </button>
                  </Box>
                </Box>
              </Box>
              </form>
            
          )}
          {process === "forgot_password" && (
            <>
              <Text
                fontSize="24px"
                styles={{ textAlign: "center", justifyContent: "center" }}
              >
                Please enter your email address
              </Text>
              <Box sx={{ margin: "20px 0" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                >
                  <Box
                    className="membership-flex"
                    sx={{
                      marginBottom: "16px",
                      width: "100%",
                    }}
                  >
                    <input
                      type="email"
                      name="email"
                      required
                      onChange={handleChange("email")}
                      className="membership-input"
                      placeholder="Email address"
                    />
                    {errors["email"] && (
                      <Text
                        styles={{ flex: "0 0 100%" }}
                        fontSize="14px"
                        color="#ffcccc"
                      >
                        {errors["email"]}
                      </Text>
                    )}
                    <Box sx={{ marginTop: "10px", flex: "0 0 100%" }}>
                      {errors["form"] && (
                        <Text
                          styles={{ flex: "0 0 100%" }}
                          fontSize="14px"
                          color="#ffcccc"
                        >
                          {errors["form"]}
                        </Text>
                      )}
                    </Box>
                    <Box sx={{ marginTop: "10px", flex: "0 0 100%" }}>
                      <Box
                        onClick={() => {
                          setFormData({ email: "", password: "" });
                          setErrors({});
                          setProcess("login");
                        }}
                      >
                        <Text
                          fontSize="14px"
                          color="#edbb2a"
                          sx={{ cursor: "pointer" }}
                        >
                          Login
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "auto",
                    }}
                  >
                    <button
                      type="button"
                      onClick={() => onForgotPassword()}
                      style={{
                        background: "transparent",
                        border: "none",
                        margin: "auto",
                      }}
                    >
                      <Text
                        style={{ justifyContent: "center", cursor: "pointer" }}
                        fontSize="22px"
                        color="#E9B82A"
                      >
                        Forgot Password
                      </Text>
                    </button>
                  </Box>
                </Box>
              </Box>
            </>
          )}
          {process === "forgot_password_success" && (
            <>
              <Text
                fontSize="24px"
                styles={{ textAlign: "center", justifyContent: "center" }}
              >
                Thank you!
              </Text>
              <Box sx={{ margin: "20px 0" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                >
                  <Box
                    className="membership-flex"
                    sx={{
                      marginBottom: "16px",
                      width: "100%",
                    }}
                  >
                    <Text fontSize="14px">
                      We have sent a link to the requested email address, please
                      find the instructions to reset your password. Once
                      complete, you will have full access to your account.
                    </Text>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "auto",
                    }}
                  >
                    <Box sx={{ marginTop: "10px", flex: "0 0 100%" }}>
                      <Box
                        onClick={() => {
                          setFormData({ email: "", password: "" });
                          setErrors({});
                          setProcess("login");
                        }}
                      >
                        <Text fontSize="14px" color="#edbb2a" style={{cursor: 'pointer'}}>
                          Login
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default LoginModal;
