import { Box } from "@mui/system";
import React from "react";
import Text from "./Text";

const HorizontalScroll = (data) => {
  const { list, scrollForMore, type, component, componentClasses, isLoading, eventHandlers } = data;
  const Component = component;

  return (
    <>
      <div className="horizontal-scroll-container">
        {list.map((item, key) => (
          <Box
            key={key}
            sx={{
              marginRight: "30px",
              flex:
                type === "COMPANY_CARD"
                  ? { xs: "0 0 100%", md: "0 0 360px" }
                  : { xs: "0 0 100%", sm: "0 0 420px" },
              width: "unset !important",
              scrollSnapAlign: "start",
            }}
          >
            <Component eventHandlers={eventHandlers} isLoading={isLoading} componentClasses={componentClasses} item={item} />
          </Box>
        ))}
      </div>
      {scrollForMore && (
        <div
          style={{ margin: "auto", textAlign: "center" }}
          className="horizontal-scroll-block"
        >
          <div className="horizontal-scroll-content">
            <Text
              fontSize={"14px"}
              styles={{
                wordwrap: "break-word",
                color: "#a3a3a3",
                textAlign: "center",
                margin: "28px auto 0",
                width: "fit-content",
                fontWeight: 400,
                fontStyle: "normal",
                letterSpacing: "0em",
                textTransform: "none",
              }}
            >
              Scroll for more →
            </Text>
          </div>
        </div>
      )}
    </>
  );
};

export default HorizontalScroll;
