import React from "react";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Text from "./Text";
import Heading from "./Heading";

import MembershipBackground from "../assets/membership-bg.png";
import BGVideo from "../assets/dtj-hero-video-720.mp4";

export const Hero = () => {
  return (
    <Box
      sx={{
        fontFamily: "Work Sans",
        fontWeight: "400",
        fontStyle: "normal",
        letterSpacing: "0em",
        textTransform: "none",
        minHeight: "32vh",
        height: "80vh",
        paddingTop: "142.453px",
        paddingBottom: "82.453px",
        alignItems: "center",
        color: "#fdfdfd",
        // backgroundColor: "#161a21",
        display: "flex",
        paddingRight: { xs: "10px", lg: "72px" },
        paddingLeft: { xs: "10px", lg: "72px" },
        position: "relative",
        overflow: "hidden",
      }}
    >
      <video
        autoPlay="autoplay"
        loop="loop"
        muted
        defaultmuted="true"
        playsInline
        preload="auto"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: "0",
          height: "100%",
          width: "177.77777778vh" /* 100 * 16 / 9 */,
          minWidth: "100%",
          minHeight: "56.25vw",
        }}
      >
        <source src={BGVideo} type="video/mp4" />
      </video>
      <Container sx={{ zIndex: 0 }}>
        <Box
          sx={{
            marginBottom: "54px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Heading
            variant="h1"
            type="HERO"
            sx={{
              // lineHeight: 1.2,
              // textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              font: "normal normal bold 72px/80px Work Sans",
              letterSpacing: "0px",
              color: "#FFFFFF",
              opacity: 1,
              backdropFilter: "blur(50px)",
              marginBottom: 0,
            }}
          >
            Ready to change the world?
          </Heading>
          <Heading
            variant="h1"
            type="HERO"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              font: "normal normal bold 72px/80px Work Sans",
              letterSpacing: "0px",
              color: "#FFFFFF",
              opacity: 1,
              backdropFilter: "blur(50px)",
              marginBottom: 0,
            }}
          >
            Here's your chance.
          </Heading>
        </Box>
        <Box
          sx={{
            margin: "40px auto 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NavLink
            to="/companies"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0px 12px",
              borderRight: "2px solid #fff",
            }}
          >
            <Text fontWeight="600">
              <SearchIcon style={{ marginRight: "4px" }} /> Search Companies
            </Text>
          </NavLink>
          <NavLink
            to="/jobs"
            style={{
              display: "flex",
              padding: "0px 12px",
              justifyContent: "center",
            }}
          >
            <Text fontWeight="600">
              <SearchIcon style={{ marginRight: "4px" }} /> Search Jobs
            </Text>
          </NavLink>
        </Box>
      </Container>
    </Box>
  );
};

export const Banner = (data) => {
  const { title, backgroundImg } = data;

  return (
    <Box
      sx={{
        fontFamily: "Work Sans",
        fontWeight: "400",
        fontStyle: "normal",
        letterSpacing: "0em",
        textTransform: "none",
        minHeight: "42vh",
        paddingTop: "142.453px",
        paddingBottom: "82.453px",
        alignItems: "center",
        color: "#fdfdfd",
        background: `url('${backgroundImg}') no-repeat`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        paddingRight: { xs: "6px", lg: "72px" },
        paddingLeft: { xs: "6px", lg: "72px" },
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <Heading
            style={{
              textAlign: "center",
            }}
            fontSize="calc((6 - 1) * 1.2vw + 1rem)"
            variant="h1"
          >
            {title}
          </Heading>
        </Box>

        <Box
          sx={{
            margin: "40px auto 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NavLink
            to="/companies"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0px 12px",
              borderRight: "2px solid #fff",
            }}
          >
            <Text fontWeight="600">
              <SearchIcon style={{ marginRight: "4px" }} /> Search Companies
            </Text>
          </NavLink>
          <NavLink
            to="/jobs"
            style={{
              display: "flex",
              padding: "0px 12px",
              justifyContent: "center",
            }}
          >
            <Text fontWeight="600">
              <SearchIcon style={{ marginRight: "4px" }} /> Search Jobs
            </Text>
          </NavLink>
        </Box>
      </Container>
    </Box>
  );
};

export const InsightsBanner = () => {
  return (
    <Box
      sx={{
        padding: { xs: "60px 20px", md: "72px" },
        background: {
          xs: " linear-gradient(132deg, rgba(26,18,65,1) 0%, rgba(0,0,0,1) 48%, rgba(0,0,0,1) 100%)",
          lg: "transparent linear-gradient(132deg, rgba(46,37,96,1) 0%, rgba(0,0,0,1) 48%, rgba(0,0,0,1) 100%) 0% 0% no-repeat padding-box",
        },
        opacity: 1,
        display: "flex",
        alignItems: { xs: "flex-start", lg: "center" },
        justifyContent: "space-between",
        flexDirection: { xs: "column", lg: "row" },
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Heading fontSize="36px" sx={{ marginBottom: 0 }}>
          Want to know what to pay your executives?
        </Heading>
        <Box sx={{ maxWidth: "612px" }}>
          <Text
            fontSize="24px"
            sx={{
              textAlign: "left",
              font: "normal normal normal 24px/32px Work Sans",
              letterSpacing: "0px",
              color: "#FFFFFF",
              opacity: 1,
              marginBottom: "16px",
            }}
          >
           Take our Compensation Survey and receive a free Compensation report.
          </Text>
        </Box>
        <a
          href="https://www.surveymonkey.co.uk/r/DTLCompensationStudy2023"
          target="_blank"
          rel="noreferrer"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Heading
              fontSize="20px"
              sx={{ marginBottom: "0", marginRight: "16px" }}
            >
              Go to Compensation Survey
            </Heading>
            <ArrowForwardIcon
              sx={{
                marginTop: "2px",
                marginLeft: "6px",
                color: "#fff",
                fontSize: "22px",
                animation: "nudge 1s linear infinite",
              }}
            />
          </Box>
        </a>
      </Box>
      <Box sx={{ margin: "auto", marginTop: "50px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: { sx: "center", lg: "flex-end" },
          }}
        >
          <lottie-player
            src="https://assets8.lottiefiles.com/packages/lf20_eKikFJ.json"
            background="transparent"
            speed="1"
            style={{ width: "300px", height: "300px" }}
            loop
            // controls
            autoplay
          ></lottie-player>
        </Box>
      </Box>
    </Box>
  );
};

export const MembershipBanner = (data) => {
  const { title, elevatedTitle, subtitle, buttonText } = data;
  return (
    <Box
      sx={{
        padding: { xs: "60px 20px", md: "104px 72px" },
        backgroundImage: "url(" + MembershipBackground + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        opacity: 1,
        display: "flex",
        alignItems: { xs: "flex-start", lg: "center" },
        justifyContent: "space-between",
        flexDirection: { xs: "column", lg: "row" },
      }}
    >
      <Box sx={{ flex: 1 }}>
        {elevatedTitle && (
          <Heading fontSize="18px" sx={{ marginBottom: "4px" }}>
            {elevatedTitle}
          </Heading>
        )}
        <br/>
        {title && (
          <Heading fontSize="32px" sx={{ marginBottom: "22px" }}>
            {title}
          </Heading>
        )}
        {subtitle && (
          <Box sx={{ maxWidth: "612px" }}>
            <Heading
              fontSize="18px"
              sx={{
                textAlign: "left",
                letterSpacing: "0px",
                color: "#FFFFFF",
                opacity: 1,
                marginBottom: "16px",
              }}
            >
              {subtitle}
            </Heading>
          </Box>
        )}
      </Box>
      <Box
        sx={{ margin: "auto", marginTop: "50px", marginLeft: { lg: "40px" } }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { sx: "center", md: "flex-end" },
          }}
        >
          <NavLink to="/join">
            <Box
              component="button"
              sx={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "0px 10px 30px #00000079",
                border: "none",
                borderRadius: "32px",
                opacity: 1,
                backdropFilter: "blur(8px)",
                padding: { xs: "14px 16px", lg: "21px 28px" },
                cursor: "pointer"
              }}
            >
              <Text fontSize="16px" color="#000">
                {buttonText}
              </Text>
            </Box>
          </NavLink>
        </Box>
      </Box>
    </Box>
  );
};
