const Company_Status = {
    Predefined: 1,
    New: 2,
    Profile: 3
};

const Company_Profile_Upload = {
    Video: 'video',
    Image: 'image'
};

export { Company_Status, Company_Profile_Upload }