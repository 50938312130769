import React from "react";
import { Box } from "@mui/material";
import Heading from "./Heading";
import { Banner, Hero } from "./Banners";

const ContentSection = (data) => {
  const {
    isLoading,
    backgroundColor,
    title,
    children,
    childStyles,
    type,
    padding,
    ...rest
  } = data;
  return (
    <Box
      sx={{
        padding: { xs: padding || "40px 10px", md: padding || "72px 0 72px 72px" },
        backgroundColor: backgroundColor === "DARK" ? "#161a21" : "#1F2227",
      }}
      {...rest}
    >
      <Box sx={{ ...childStyles }}>
        {title && <Heading isLoading={isLoading}>{title}</Heading>}
        {children}
      </Box>
    </Box>
  );
};

const Section = (data) => {
  const { type } = data;

  let component;

  switch (type) {
    case "HERO":
      component = <Hero {...data} />;
      break;
    case "BANNER":
      component = <Banner {...data} />;
      break;
    default:
      component = <ContentSection {...data} />;
      break;
  }

  return <section>{component}</section>;
};

export default Section;
