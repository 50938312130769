import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

import agents from "../../agents";
import { useAuth } from "../../context/AuthContext";
import { useUser } from "../../context/UserContext";

import CompanySection from "./CompanyProfileSection";

const CompanyProfileLoading = () => {
  return <>Loading</>;
};

const CompanyProfile = (data) => {
  const { currentUser } = useAuth();
  const { userDetails } = useUser();
  const location = useLocation();
  const updateTime = location.state?.updateTime ? location.state.updateTime : null;

  const [companyProfile, setCompanyProfile] = useState({
    data: {},
    companyId: null,
  });

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const companyId = searchParams.get("companyId");
  let _openModal = searchParams.get("openModal");

  const [openModal, setOpenModal] = useState(_openModal);
  const [isLoading, setIsLoading] = useState(true);
  const [currentModalProcess, setCurrentModalProcess] = useState("");

  const fetchData = async () => {
    
    const query = {
      companyId: companyId
    };

    try {
      const response = await agents.Companies.getCompany(query);
      setCompanyProfile((v) => ({ ...v, data: response.data }));
      setIsLoading(false);
    } 
    catch (e) {
      navigate("/404");
      return;
    }
  };

  useEffect(() => {
    if(!isLoading && updateTime) 
      setIsLoading(true);
    setCompanyProfile((v) => ({ ...v, companyId: companyId }));

    if (currentUser?.auth?.currentUser && !companyProfile.data.verified) {
      setCurrentModalProcess("reviewing_company");
    }

    fetchData();
  }, [updateTime]);


  return (
    <>
      <Helmet>
        <title>
          Deep Tech Jobs
          {`${
            !isLoading ? " - " + companyProfile?.data?.company?.companyName : ""
          }`}
        </title>
      </Helmet>
      {isLoading ? (
        <CompanyProfileLoading />
      ) : (
        <CompanySection
          {...companyProfile}
          companyId={companyId}
          isLoading={isLoading}
          openModal={openModal}
          currentModalProcess={currentModalProcess}
          setCurrentModalProcess={setCurrentModalProcess}
          setOpenModal={setOpenModal}
        />
      )}
    </>
  );
};

export default CompanyProfile;
