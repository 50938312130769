// import moment from "moment";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const displayJobPostedDate = (dateAsString) => {
  if (dateAsString && dateAsString.trim()) {
    const currentDate = dayjs();
    const modifiedDate = dateAsString.toUpperCase().replace("UTC", "").trim();
    const jobDate = dayjs.utc(modifiedDate);
    let diff = currentDate.diff(jobDate, "month");
    // display months
    if (diff >= 6) 
            return "6+ months ago";
    else if (diff >= 2 && diff <= 5) 
            return `${diff} months ago`;
    else {
      // display weeks
      diff = currentDate.diff(jobDate, "week");
      if (diff >= 2) 
            return `${diff} weeks ago`;
      else {
        // display days
        diff = currentDate.diff(jobDate, "day");
        if (diff === 0) 
            return "Today";
        else if (diff === 1) 
            return "Yesterday";
        else 
            return `${diff} days ago`;
      }
    }
  }
};

export { displayJobPostedDate };
