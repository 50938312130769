import { element } from "prop-types";
import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useModal } from "../context/ModalContext";

export default function PrivateRoute(props) {
  const { currentUser, isLoading } = useAuth();

  return !isLoading ? currentUser ? <Outlet /> : <Navigate to="/" /> : null;
}
