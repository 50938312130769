import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { LoadingJobCard } from "./JobCard/JobCard";
import { Box } from "@mui/material";
import Text from "./Text";
import urlCreator from "../utils/urlCreator";

const VimeoVideo = ({ videoLink }) => {
  const iframe = `
  <iframe
  style="

width: 100%;
height: 100%;
"
  id="profile-media"
  src="${videoLink}"
  title="Vimeo video player"
  frameborder="0"
  scrolling="no"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowfullscreen
></iframe>
`;

  return (
    <Box
      className="youtube"
      sx={{ minHeight: "200px", width: "100%", position: "relative" }}
      dangerouslySetInnerHTML={{ __html: iframe }}
    ></Box>
  );
};

export const YoutubeVideo = ({ youtubeLink, alt }) => {
  const [clicked, setClicked] = useState(true);

  const youtubeId = youtubeLink?.split("embed/")[1]?.replace("#", "") || null;

  const youtubeContainer = (
    <Box
      sx={{ height: "200px" }}
      className="youtube"
      onClick={() => setClicked((v) => !v)}
    >
      <img src={`https://i.ytimg.com/vi/${youtubeId}/0.jpg`} alt={alt} />
      <div className="play-button"></div>
    </Box>
  );

  const iframe = `<iframe
    style="
    position: absolute;
    top: 0;
    left: 0;
  width: 100%;
  height: 100%;

  "
    src="${youtubeLink}"
    title="YouTube video player"
    frameborder="0"
    scrolling="no"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    enablejsapi=”true” 
  ></iframe>`;

  const YoutubeEmbed = (
    <Box
      sx={{ height: "200px", position: "relative", paddingTop: "56.25%" }}
      dangerouslySetInnerHTML={{ __html: iframe }}
    ></Box>
  );

  return clicked ? youtubeContainer : YoutubeEmbed;
};

export const LoadingCompanyCard = () => (
  <Box>
    <Box className="skeleton-card" sx={{ borderRadius: "16px" }}>
      <div className="skeleton-wrapper">
        <div className="skeleton-wrapper-inner">
          <div className="skeleton-wrapper-body"></div>
        </div>
      </div>
    </Box>
    <Box>
      <Box
        sx={{
          background: "#373d46",
          width: "69px",
          height: "16px",
          marginBottom: "10px",
        }}
      ></Box>
      <Box
        sx={{
          background: "#373d46",
          width: "112px",
          height: "16px",
          marginBottom: "10px",
        }}
      ></Box>
      <Box
        sx={{
          background: "#373d46",
          width: "54px",
          height: "16px",
          marginBottom: "10px",
        }}
      ></Box>
    </Box>
  </Box>
);

const Card = (data) => {
  const company = data.item;

  let innerText1 = "",
    innerTextClass1 = "",
    innerText2 = "",
    innerTextClass2 = "",
    innerText3 = "",
    innerTextClass3 = "";

  if (company.basicInformation.technologyClassification1) {
    innerText1 = company.basicInformation.technologyClassification1;
    innerTextClass1 = innerText1
      .toLowerCase()
      .replace("&", "")
      .replace(".", "-")
      .replace("/", "-")
      .replace("  ", " ")
      .trim()
      .split(" ")
      .join("-");
  }
  const youtubeId =
    company.profileMedia.MediaUrl?.toLowerCase().includes("youtube");

  const html = (
    <Box className="company-object-result">
      {company.profileMedia.MediaUrl && (
        <div
        className="profile-media-container"
      >
          {youtubeId ? (
            <YoutubeVideo
              alt={`${company.companyName} Profile Media on Deep Tech Jobs`}
              youtubeLink={company.profileMedia.MediaUrl}
            />
          ) : (
            <VimeoVideo videoLink={company.profileMedia.MediaUrl} />
          )}
      </div>
      )}

      {company.profileMedia.CompanyImageUrl && (
        <div
        className="profile-media-container"
      >
          <Box 
            className="companyImage"
            sx={{ height: "200px" }}
          >
            <a href={urlCreator.companyProfile(company.id)}>
              <img src={company.profileMedia.CompanyImageUrl} alt={`${company.companyName} image.`} />
            </a>
          </Box>
      </div>
      )}
      
      <Box sx={{ paddingLeft: "16px" }} className="company-object-title">
        <p className="company-object-sector-focus">
          {innerText1 && innerTextClass1 && (
            <span id={innerTextClass1}>{innerText1}</span>
          )}
          {innerText2 && innerTextClass2 && (
            <span id={innerTextClass2}>{innerText2}</span>
          )}
          {innerText3 && innerTextClass3 && (
            <span id={innerTextClass3}>{innerText3}</span>
          )}
        </p>
        <a
          target="_blank"
          className="company-object-link"
          href={urlCreator.companyProfile(company.id)}
        >
          <p className="company-object-title">{company.companyName}</p>
        </a>
        <Text
          className="company-object-location"
          styles={{
            paddingBottom: company.basicInformation.companyLocation
              ? ""
              : "30px",
          }}
        >
          {company.basicInformation.companyLocation}
        </Text>
      </Box>
    </Box>
  );

  return html;
};

const CompanyCard = (data) => {
  const { isLoading } = data;

  return isLoading ? <LoadingCompanyCard /> : <Card {...data} />;
};

export default CompanyCard;
