import { Box } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import LoginModal from "../components/LoginModal";

const ModalContext = React.createContext();

export function useModal() {
  return useContext(ModalContext);
}

export function ModalProvider({ children }) {
  const [openModal, setOpenModal] = useState(false);

  function updateModal(bool) {
    setOpenModal(bool);
  }

  const value = {
    openModal,
    updateModal,
  };

  useEffect(() => {
    setOpenModal(false);
    return;
  }, []);

  return (
    <ModalContext.Provider value={value}>
      <Box>
        {children}
        <LoginModal open={openModal} setOpen={updateModal} />
      </Box>
    </ModalContext.Provider>
  );
}
