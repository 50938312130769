import { Box, Container } from "@mui/material";

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Heading from "../../components/Heading";
import Section from "../../components/Section";
import Text from "../../components/Text";

const ForgotPasswordForm = (data) => {
  const { submit } = data;

  const [forgotPasswordValues, updateForgotPasswordValues] = useState({
    email: "",
  });

  const handleChange = (prop) => (event) => {
    updateForgotPasswordValues({ ...forgotPasswordValues, [prop]: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    submit(forgotPasswordValues);
  };

  return (
    <Container sx={{ maxWidth: "600px", width: "100%", marginX: "auto" }}>
      <Box component="form" onSubmit={onSubmit} className="form-container">
        <Box>
          <Heading style={{ lineHeight: 1, marginBottom: "40px" }}>
            Forgot Email
          </Heading>
          <Text marginBottom="40px" fontSize="14px">
            Welcome back. Need help?
            <NavLink to="/contact">
              <Text marginLeft="8px" fontSize="14px" color="#edbb2a">
                Contact support.
              </Text>
            </NavLink>
          </Text>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            marginBottom: "10px",
          }}
        >
          <input
            type="email"
            // id="position-search-dark"
            name="email"
            required
            onChange={handleChange("email")}
            placeholder="Email address"
          />
        </Box>
        <Box
          sx={{
            marginBottom: "20px",
          }}
          className="container-content"
        >
          <button type="submit" className="content-loadmore-button">
            Submit
          </button>
        </Box>
      </Box>
    </Container>
  );
};
const ForgotPassword = (data) => {
  return (
    <Section
      padding="90px 72px 72px"
      position="relative"
      minHeight="100vh"
      childStyles={{
        position: "absolute",
        width: "100%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <ForgotPasswordForm />
    </Section>
  );
};

export default ForgotPassword;
