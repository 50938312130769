import { auth } from "../../firebase";
import agents from "../../agents";



const getFirebaseUserToken = (user) => user._delegate.stsTokenManager.accessToken;

const tryLogin = async (email, fireBaseToken) => {
  try {
    const response = await agents.Auth.login({ email: email }, fireBaseToken);
    agents.setToken(fireBaseToken);
    agents.setBackendToken(response.data.backendToken);
    return { success: true }
  } 
  catch (err) {
     // console.error(`Unable to sign in.${err}`);
     return { success: false }
  }
}

const userEditPredefinedCompany = async (companyData) => {
    try {
      await agents.Companies.userUpdatePredefinedCompany(companyData);
      return { success: true };
    }
    catch (ex) {
      return { success: false };
    }
}

const createFirebaseAccount = async (email, password) => {
  try {
       const result = await auth.createUserWithEmailAndPassword(email, password);
       return { success: true, firebaseUser: result.user };
  } catch (e) {
    if (e.code === "auth/email-already-in-use") {
      return {
        success: false,
        errorMsg:
          "Email address already in use, if you have account please login or use a different email address",
      };
    }
    return {
      success: false,
      errorMsg:
        e.message || "Failed to submit request, please review and try again",
    };
  }
};


const postNewCompany = async (companyData, token) => {
  //companyData.userEmail = userEmail;
  try {
    const response = await agents.CompanyProfile.postNewCompany(companyData, token);
    return { success: true, companyId: response.data.companyId };
  }
  catch(error) {
    return { success: false };
  }
}


const postCompanyUser = async (companyId, userData, token) => {

  try {
    let userDataToSend = Object.assign({}, userData);
    delete userDataToSend.password;
    const response = await agents.CompanyUser.postCompanyUser(companyId, userDataToSend, token);
    return { success: true, userId: response.data.userId };
  }
  catch (err) {
    return { success: false }
  }
}

const signUpActions = {
  createFirebaseAccount: createFirebaseAccount,
  postCompanyUser: postCompanyUser,
  tryLogin: tryLogin,
  getFirebaseUserToken: getFirebaseUserToken,
  postNewCompany: postNewCompany,
  userEditPredefinedCompany: userEditPredefinedCompany
};



export default signUpActions;

