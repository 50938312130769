import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import Text from "../../../components/Text";

const CompleteCommandModal = (props) => {
    const { open, setOpen, error, clearError } = props;
    
    return (
      <Modal sx={{ outline: "none" }} open={open} onClose={() => { clearError(); setOpen(false); }}>
        <Box
          sx={{
            background: error?.message?.length
              ? "#8F2613 0% 0% no-repeat padding-box"
              : "#6553E8 0% 0% no-repeat padding-box",
            boxShadow: "0px 8px 16px #00000029",
            borderRadius: "16px",
            maxWidth: "500px",
            padding: "20px",
            opacity: 1,
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "calc(100% - 40px)",
            transform: "translate(-50%, -50%)",
          }}
        >
          
            {error?.message?.length
              ? error.message.split('.').map(
                  (sentence)=> <Text  styles={{ marginBottom: "12px" }}>{sentence}</Text>
                )
              : <>
                  <Text styles={{ marginBottom: "12px" }}>Your changes have been saved.</Text>
                  <Text styles={{ marginBottom: "12px" }}>These changes may take a few hours to be implemented on the site.</Text>
                </> } 
          
          <Text
            style={{ justifyContent: "flex-end", cursor: "pointer" }}
            onClick={() =>  { clearError(); setOpen(false); } }
          >
            Dismiss
          </Text>
        </Box>
      </Modal>
    );
  };

  export default CompleteCommandModal;