import React from "react";
import { Box, Container } from "@mui/material";

import Heading from "../../components/Heading";
import Section from "../../components/Section";
import Text from "../../components/Text";
import Modal from "./Modal";
import Background from "../../assets/membership-bg.png";

import GreenhouseLogo from "../../assets/membership-icons/greenhouse@2x.png";
import LeverLogo from "../../assets/membership-icons/lever@2x.png";
import PersonioLogo from "../../assets/membership-icons/personio@2x.png";
import RecruiteeLogo from "../../assets/membership-icons/recruitee-logo@2x.png";
import SmartRecruitersLogo from "../../assets/membership-icons/SmartRecruiters-logo@2x.png";
import TeamtailorLogo from "../../assets/membership-icons/Teamtailor@2x.png";
import WorkableLogo from "../../assets/membership-icons/workable@2x.png";
import { Helmet } from "react-helmet";
import { modalActions } from "./constants";

const MembershipSection = (data) => {
  const {
    open,
    setOpen,
    isLoading,
    companies,
    company,
    formData,
    setFormData,
    updateForm
  } = data;

  return (
    <>
      <Helmet>
        <title>Deep Tech Jobs - Join</title>
      </Helmet>
      <Box
        // backgroundColor="DARK_LIGHT"
        sx={{
          background: `url('${Background}') no-repeat`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingTop: "272.453px",
          paddingBottom: "200.453px",
          paddingRight: { xs: "10px", lg: "72px" },
          paddingLeft: { xs: "10px", lg: "72px" },
        }}
      >
        <Container>
          <Heading
            variant="h1"
            type="HERO"
            color="#fff"
            fontSize="48px"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              font: "normal normal bold 72px/80px Work Sans",
              letterSpacing: "0px",
              color: "#FFFFFF !important",
              opacity: 1,
              backdropFilter: "blur(50px)",
              marginBottom: 0,
            }}
          >
            Join the largest Deep Tech job community and start attracting the talent you need.
          </Heading>
        </Container>
      </Box>
      <Section padding="70px 0 0">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ marginBottom: "10px" }}>         
                    <Heading
                      fontSize="48px"
                      styles={{
                        marginBottom: 0,
                        lineHeight: 1,
                        textAlign: "center",
                      }}
                    >
                      Membership is Free!
                    </Heading>
                    <Text
                    styles={{
                      margin: "10px",
                      letterSpacing: "0px",
                      color: "#DBDBDB",
                      justifyContent: "center"
                    }}
                  >
                    It only takes 5 minutes to set up.
                  </Text>
                
            
          </Box>
          <Box sx={{ margin: "auto", width: "fit-content" }}>
            <Box
              disabled={isLoading}
              sx={{
                border: "none",
                padding: "20px 27px",
                borderRadius: "47px",
                color: "#fff",
                fontSize: "14px",
                cursor: "pointer",
                background:
                  "transparent linear-gradient(180deg, #7B69FF 0%, #5442D6 100%) 0% 0% no-repeat padding-box",
                margin: "21px 0",
              }}
              component="button"
              onClick={() => setOpen(true)}
            >
              Become a Member
            </Box>
          </Box>
        </Box>
      </Section>

      <Section padding="0">
        <Box
          padding={{
            xs: "20px 10px 0px",
            md: "72px 72px 0",
            marginBottom: "0px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                background: "#161A21 0% 0% no-repeat padding-box",
                boxShadow: "0px 8px 12px #000000CC",
                borderRadius: "8px 8px 0 0",
                padding: "32px 64px",
                opacity: 1,
                width: "100%",
                maxWidth: "720px",
              }}
            >
              <Text
                styles={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  marginBottom: "40px",
                }}
                fontSize="22px"
              >
                Benefits
              </Text>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", lg: "1fr 1fr" },
                  gridColumnGap: "80px",
                  gridRowGap: "56px",
                }}
              >
                <Box sx={{ margin: "auto" }}>
                  <Text
                    styles={{
                      margin: "0 auto 34px",
                      letterSpacing: "0px",
                      color: "#DBDBDB",
                      textAlign: "center",
                    }}
                  >
                    Attract talent from the largest Deep Tech job community.
                  </Text>
                </Box>

                <Box sx={{ margin: "auto" }}>
                  <Text
                    styles={{
                      margin: "0 auto 34px",
                      letterSpacing: "0px",
                      color: "#DBDBDB",
                      textAlign: "center",
                    }}
                  >
                    Post unlimited jobs from any country in the world.
                  </Text>
                </Box>

                <Box sx={{ margin: "auto" }}>
                  <Text
                    styles={{
                      margin: "0 auto 34px",
                      letterSpacing: "0px",
                      color: "#DBDBDB",
                      textAlign: "center",
                    }}
                  >
                    Receive applications directly within your ATS system.
                  </Text>
                </Box>

                <Box sx={{ margin: "auto" }}>
                  <Text
                    styles={{
                      margin: "0 auto 34px",
                      letterSpacing: "0px",
                      color: "#DBDBDB",
                      textAlign: "center",
                    }}
                  >
                    Feature in regular promotions on our Social Media.
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section padding="0" backgroundColor="DARK" marginTop="-40px">
        <Box padding={{ xs: "20px 10px 0px", lg: "40px 72px 72px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                background: "#1F2227 0% 0% no-repeat padding-box",
                boxShadow: "0px 8px 12px #000000CC",
                borderRadius: "0 0 8px 8px",
                padding: "32px 64px",
                opacity: 1,
                width: "100%",
                maxWidth: "720px",
              }}
            >
              <Text
                styles={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  marginBottom: "40px",
                }}
                fontSize="22px"
              >
                Requirements
              </Text>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", lg: "1fr 1fr" },
                  gridColumnGap: "80px",
                  gridRowGap: "56px",
                }}
              >
                <Box sx={{ margin: "auto" }}>
                  <Text
                    styles={{
                      margin: "0 auto 34px",
                      letterSpacing: "0px",
                      color: "#DBDBDB",
                      textAlign: "center",
                    }}
                  >
                    You’re a Deep Tech / Lab-to-Market company.
                  </Text>
                </Box>

                <Box sx={{ margin: "auto" }}>
                  <Text
                    styles={{
                      margin: "0 auto 34px",
                      letterSpacing: "0px",
                      color: "#DBDBDB",
                      textAlign: "center",
                    }}
                  >
                    You are using an ATS to post your jobs.
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "112px",
                paddingBottom: "48px",
              }}
            >
              <Heading
                fontSize="24px"
                styles={{ textAlign: "center", margin: "0" }}
              >
                We integrate with the leading ATS platforms, including…
              </Heading>
            </Box>
            <Box
              sx={{
                maxWidth: "1280px",
                margin: "auto",
                display: "grid",
                gridRowGap: { xs: "40px", lg: "64px" },
                gridColumnGap: { xs: "40px", lg: "64px" },
                justifyContent: "center",
                gridTemplateColumns: { xs: "1fr 1fr", lg: "1fr 1fr 1fr 1fr" },
              }}
            >
              <Box
                component="img"
                sx={{ width: "100%", maxWidth: "286px", margin: "auto" }}
                src={WorkableLogo}
              ></Box>
              <Box
                component="img"
                sx={{ width: "100%", maxWidth: "286px", margin: "auto" }}
                src={GreenhouseLogo}
              ></Box>
              <Box
                component="img"
                sx={{ width: "100%", maxWidth: "286px", margin: "auto" }}
                src={LeverLogo}
              ></Box>
              <Box
                component="img"
                sx={{ width: "100%", maxWidth: "286px", margin: "auto" }}
                src={PersonioLogo}
              ></Box>
              </Box>
              <Box
              sx={{
                maxWidth: "1280px",
                margin: "auto",
                display: "grid",
                gridRowGap: { xs: "40px", lg: "64px" },
                gridColumnGap: { xs: "40px", lg: "64px" },
                justifyContent: "center",
                placeItems: 'center',
                gridTemplateColumns: { xs: "1fr 1fr", lg: "1fr 1fr 1fr" },
              }}
            > 
                <Box
                  component="img"
                  sx={{ width: "100%", maxWidth: "286px", margin: "auto" }}
                  src={RecruiteeLogo}
                ></Box>
                <Box
                  component="img"
                  sx={{ width: "100%", maxWidth: "286px", margin: "auto" }}
                  src={SmartRecruitersLogo}
                ></Box>
                <Box
                  component="img"
                  sx={{ width: "100%", maxWidth: "286px", margin: "auto" }}
                  src={TeamtailorLogo}
                ></Box>
             
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              
              <Heading
                variant="h4"
                fontSize="18px"
                styles={{ textAlign: "center", margin: "0" }}
              >
                Not using one of these? <a style={{
                      color: "#edbb2a",
                      textDecoration: "underline !important",
                    }}
                    href="mailto: support@deeptechjobs.com"
                  >
                    Contact Us
                  </a>
                  <p>We are always interested in adding more ATS connections!</p>    
              </Heading>
              
            </Box>
          </Box>
        </Box>
      </Section>
      {!isLoading && (
        <Modal
          open={open}
          onClose={() => { setOpen(false); }}
          isLoading={isLoading}
          companies={companies}
          company={company}
          formData={formData}
          setFormData={setFormData}
          updateForm={updateForm}
          action={modalActions.create}
        />
      )}
    </>
  );
};

export default MembershipSection;
