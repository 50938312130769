import { Company_Profile_Upload } from "../../../constants/company";

const selectCompanyFormData = (data) => {
   
   return {
    id: data?.id || "",
    companyName: data?.companyName || "",
    logo: data?.logo || "",
    logoPreview: data?.logoPreview || null,
    logoImageFile: data?.logoImageFile || null,
    technologyClassification: data?.technologyClassification || "",
    companyCity: data?.companyCity || "",
    companyLocation: data?.companyLocation || "",
    mediaUrl:  (data?.companyProfileUpload === Company_Profile_Upload.Video) ? (data?.mediaUrl || "") : "",
    companyImageFile: data?.companyImageFile || "",
    companyImage: (data?.companyProfileUpload === Company_Profile_Upload.Image) ? (data?.companyImage || "") : "" , 
    problemStatement: data?.problemStatement || "",
    atsName: data?.atsName || "",
    atsUserName: data?.atsUserName || "", 
    atsApiKey: data?.atsApiKey || "",
    websiteUrl: data?.websiteUrl || "",
    linkedInUrl: data?.linkedInUrl || "",
    twitterUrl: data?.twitterUrl || ""
   }
};

const selectUserAccountFormData = (data) => ({
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    position: data?.position || "",
    userEmail: data?.userEmail || "",
    password: data?.password || ""
});

export { selectCompanyFormData, selectUserAccountFormData }