import * as React from "react";
const InfoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="-12 -12 333.277 333.277"
    {...props}
  >
    <g strokeWidth={0} />
    <g strokeLinecap="round" strokeLinejoin="round" />
    <path
      fill="transparent"
      stroke="#fff"
      strokeWidth={15}
      d="M155.139 0C69.592 0 0 69.598 0 155.139c0 85.547 69.598 155.139 155.139 155.139s155.139-69.592 155.139-155.139S240.686 0 155.139 0z"
    />
    <path
      fill="#fff"
      d="M167.721 248.502h-24.363V114.48h24.363v134.022zm-13-156.732c-8.58 0-14.678-6.647-14.678-14.953 0-8.58 6.373-15.227 15.227-15.227 9.141 0 14.965 6.647 14.965 15.227.262 8.306-5.824 14.953-15.514 14.953z"
    />
  </svg>
);
export default InfoIcon;