import "./App.scss";
import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Signup from "./pages/Auth/Signup";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Companies from "./pages/Companies";
import Jobs from "./pages/Jobs";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import PageTemplate from "./templates/page-templates";
import CompanyProfile from "./pages/CompanyProfile/CompanyProfile";
import TermsOfBusiness from "./pages/TermsOfBusiness";
import ScrollToTop from "./utils/route/ScrollToTop";
import MembershipConversion from "./pages/Membership/MembershipConversion";
import AuthedCompanyProfile from "./pages/CompanyProfile/Authenticated/AuthedCompanyProfile/AuthedCompanyProfile";
import AuthedAccountInformation from "./pages/CompanyProfile/Authenticated/AuthedAccountInformation/AuthedAccountInformation";
import AuthedAtsIntegration from "./pages/CompanyProfile/Authenticated/AuthedAtsIntegration/AuthedAtsIntegration";
import PageNotFound from "./pages/PageNotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";

import { AuthProvider } from "./context/AuthContext";
import { UserProvider } from "./context/UserContext";

import PageProtect from "./components/PageProtect";

import PrivateRoute from "./components/PrivateRoute";
import AuthenticatedDeclineRoute from "./components/AuthenticatedDeclineRoute";
import TermsOfUse from "./pages/TermsOfUse";

const _privateRoutes = [
  { exact: true, path: "/settings", component: AuthedCompanyProfile },
  //{ exact: true, path: "/settings/jobs", component: AuthedJobs },
  {
    exact: true,
    path: "/settings/account-information",
    component: AuthedAccountInformation,
  },
  {
    exact: true,
    path: "/settings/ats-integration",
    component: AuthedAtsIntegration
  }
];

const _nopreviewAuthenticatedRoutes = [
  { path: "/join", component: MembershipConversion },
];

const _publicRoutes = [
  { exact: true, path: "/", component: Home },
  { path: "/join", component: MembershipConversion },
  { path: "/signup", component: Signup },
  { path: "/forgot-password", component: ForgotPassword },
  { exact: true, path: "/companies", component: Companies },
  { path: "/jobs", component: Jobs },
  // { path: "/insights", component: Insights },
  { path: "/contact", component: Contact },
  { exact: true, path: "/companies/profile", component: CompanyProfile },
  { path: "/terms-of-business", component: TermsOfBusiness },
  { path: "/terms-of-use", component: TermsOfUse },

  { path: "/privacy-policy", component: PrivacyPolicy },
  
  { path: "*", component: PageNotFound },
];

// TODO: create a theme
function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { 
            backgroundColor: "#161A21",
            overflow: "auto"
          }
        }}
      />
      <Router>
        <AuthProvider>
          <UserProvider>
            <ScrollToTop />
            <PageTemplate>
              <Routes>
                {_privateRoutes.map((route, idx) => (
                  <Route
                    exact={route.exact ?? false}
                    key={idx}
                    path={route.path}
                    element={<PrivateRoute />}
                  >
                    <Route
                      exact
                      path={route.path}
                      element={<route.component />}
                    />
                  </Route>
                ))}
                {_publicRoutes.map((route, idx) => (
                  <Route
                    exact={route.exact ?? false}
                    key={idx}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}
              </Routes>
            </PageTemplate>
          </UserProvider>
        </AuthProvider>
      </Router>
    </React.Fragment>
  );
}

export default App;
