import validator from "validator";
import { validateImage } from "image-validator";
import { convertMedia } from "../../../../utils/utils";
import { createObjectToValidate } from "../../../../utils/validations/helpers";


const validateAtsIntegration = (formData) => {
    let errors = {};
    let formIsValid = true;
    
    const atsNameValidationObj = createObjectToValidate(formData.atsName);
    if(atsNameValidationObj.isEmpty){
      formIsValid = false;
      errors["atsName"] = "Please choose the ats name";
    }
    if(atsNameValidationObj.value !== 'teamtailor') {
      if(createObjectToValidate(formData.atsUserName).isEmpty){
        formIsValid = false;
        errors["atsUserName"] = "Please specify the username for the ats";
      }
    } 
    else {
      if(createObjectToValidate(formData.atsApiKey).isEmpty) {
        formIsValid = false;
        errors["atsApiKey"] = "Please specify the token";
      }
    } 
    return { formIsValid, errors };
  };

  export default validateAtsIntegration;