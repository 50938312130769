import { createTheme } from "@mui/system";

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
});


export default defaultTheme;
