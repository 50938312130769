import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/material";

const ArrowBackComponent = ({ onClick }) => (
    <Box sx={{ position: "absolute", zIndex: 2, left: "0" }}>
                <ArrowBackIcon
                  onClick={ onClick }
                  sx={{ color: "#fff", cursor: "pointer" }}
                />
    </Box>
);

export default ArrowBackComponent; 