import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, MenuItem, Select } from "@mui/material";
import Text from "../../../../components/Text";
import HeaderComponent from "../HeaderComponent";
import ArrowBackComponent from "../ArrowBackComponent";
import { SIGNUP_STAGES } from "../constants";
import { popupBodyBoxStyle } from "../styles";
import handleValidation from "./formValidation";
import signUpActions from "../../signUpActions";
import { selectCompanyFormData, selectUserAccountFormData } from "../helpers";
import { useModal } from  '../../../../context/ModalContext';
import urlCreator from "../../../../utils/urlCreator";


const CreateUserAccountStep = (props) => {
     const { updateModal } = useModal();
    // useState
    const [formData, setFormData] = useState(props.formData);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(null);
    // useNavigate
    const navigate = useNavigate();
    // event handlers
    const onArrowBackClick = () => props.assignNextStep(SIGNUP_STAGES.CREATE_PROFILE);
    
    const openLogin = () => {
      props.onClose();
      updateModal(true);
    };
    
    const handleChange = (prop) => (event) => {
        if (prop === "termsAndConditions") {
          setFormData({ ...formData, [prop]: event.target.checked });
          return;
        }
    
        setFormData({ ...formData, [prop]: event.target.value });
    };
    
    const handleCreateAccount = async () => {
      setIsLoading(true);
      // validate form
      const  { formIsValid, errors } = handleValidation(formData, {allowEmptyPassword: false});
      if ( !formIsValid ) {
        setIsLoading(false);
        setErrors(errors);
        return;
      }
      // try create account
      const createAccountResult = await signUpActions.createFirebaseAccount(formData.userEmail, formData.password);
      if(!createAccountResult.success) {
        setIsLoading(false);
        setErrors({
            serverError: createAccountResult.errorMsg
        });
        return;
      }
      const { firebaseUser } = createAccountResult;
       
      const token = signUpActions.getFirebaseUserToken(firebaseUser);
      let companyId;
      if(formData.isNewCompany) {
          const postNewCompanyResult = await signUpActions.postNewCompany(selectCompanyFormData(formData), token);
          if(!postNewCompanyResult.success ){
            setIsLoading(false);
            setErrors({
              serverError: 'Something went wrong while creating the new company.'
            });
            return;
          }
          companyId = postNewCompanyResult.companyId;
      } else {
        companyId = formData.id;
        const userEditCompanyResult = await signUpActions.userEditPredefinedCompany(selectCompanyFormData(formData));
        if(!userEditCompanyResult.success) {
          setIsLoading(false);
          setErrors({
            serverError: 'Something went wrong while creating the user account.'
          });
          return;  
        } 
      }
      const postCompanyUserResult = await signUpActions.postCompanyUser(companyId,  selectUserAccountFormData(formData), token);
      if(!postCompanyUserResult.success){
        setIsLoading(false);
        setErrors({
          serverError: 'Something went wrong while creating the user account.'
        });
        return;
      }
      
      

      const signInResult = await signUpActions.tryLogin(formData.userEmail, token);
      if(signInResult.success === false) {
        setIsLoading(false);
        setErrors({
          serverError: 'Unable to Sign In the user.'
        });
        return;
      }
      setIsLoading(false);
      props.onSuccessFlow();
      navigate(
        urlCreator.companyProfile(companyId)
      );
      // window.location.reload();
      
    };    
    
    
    return (
      <>
        <HeaderComponent
          arrowBackComponent={<ArrowBackComponent onClick={onArrowBackClick} />}
          title="Enter your personal details below"
        />
        <Box sx={popupBodyBoxStyle}>
          <Box component="form" sx={{ marginTop: "16px" }}>
            <Box>
              <Box
                className="membership-flex"
                sx={{
                  marginBottom: "16px",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  className="membership-flex"
                  sx={{
                    flex: { xs: "100%", sm: "1" },
                    marginRight: { xs: "0", sm: "16px" },
                    marginBottom: { xs: "16px", md: "0" },
                  }}
                >
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First name"
                    onChange={handleChange("firstName")}
                    value={formData.firstName}
                    className="membership-input"
                  />
                  {errors["firstName"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["firstName"]}
                    </Text>
                  )}
                </Box>
                <Box className="membership-flex">
                  <input
                    type="text"
                    name="lastNane"
                    placeholder="Last name"
                    onChange={handleChange("lastName")}
                    value={formData.lastName}
                    className="membership-input"
                  />
                  {errors["lastName"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["lastName"]}
                    </Text>
                  )}
                </Box>
              </Box>
              <Box className="membership-flex" sx={{ marginBottom: "16px" }}>
                <Box
                  className="membership-flex"
                  sx={{
                    flex: { xs: "100%", sm: "1" },
                    marginRight: { xs: "0", sm: "16px" },
                    marginBottom: { xs: "16px", md: "0" },
                  }}
                >
                  <input
                    type="text"
                    name="position"
                    placeholder="Position"
                    onChange={handleChange("position")}
                    value={formData.position}
                    className="membership-input"
                    autoComplete="off"
                  />
                  {errors["position"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["position"]}
                    </Text>
                  )}
                </Box>
                <Box className="membership-flex">
                  <input
                    type="email"
                    name="email"
                    placeholder="Your work email"
                    onChange={handleChange("userEmail")}
                    value={formData.userEmail}
                    className="membership-input"
                  />
                  {errors["userEmail"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["userEmail"]}
                    </Text>
                  )}
                </Box>
              </Box>
              <Box className="membership-flex" sx={{ marginBottom: "16px" }}>
                <Box
                  className="membership-flex"
                  sx={{
                    flex: { xs: "100%", sm: "1" },
                    marginRight: { xs: "0", sm: "16px" },
                    marginBottom: { xs: "16px", md: "0" },
                  }}
                >
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    onChange={handleChange("password")}
                    value={formData.password}
                    className="membership-input"
                    autoComplete="new-password"
                  />
                  {!errors["password"] && (
                    <Text
                      fontSize="14px"
                      color="#98999D"
                      styles={{
                        marginTop: "5px",
                        justifyContent: "flex-start",
                        flex: "0 0 100%",
                      }}
                    >
                      Passwords must include at least one uppercase, lowercase
                      letter, number and special character.
                    </Text>
                  )}
                  {errors["password"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["password"]}
                    </Text>
                  )}
                </Box>
                <Box className="membership-flex">
                  <input
                    type="password"
                    name="passwordRepeat"
                    placeholder="Confirm Password"
                    onChange={handleChange("passwordRepeat")}
                    value={formData.passwordRepeat}
                    className="membership-input"
                    autoComplete="new-password"
                  />

                  {errors["passwordRepeat"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["passwordRepeat"]}
                    </Text>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                margin: "12px 0",
              }}
            >
              
                <input
                  id='signUpTermsAndConditions'
                  type="checkbox"
                  value={formData.termsAndConditions}
                  onChange={handleChange("termsAndConditions")}
                />
                  <Box component="span" color="#fff" fontSize="12px" marginTop="0">
                    <label for = 'signUpTermsAndConditions'>
                      By creating an account, you are agreeing to our
                      <a href="/terms-of-business" target="_blank">
                        <Box component="span" color="#E9B82A" fontSize="12px">
                          {" "}
                          terms of business.
                        </Box>
                      </a>
                    </label>
                  </Box>
              
              
            </Box>
            {errors["form"] && (
              <Text
                styles={{ flex: "0 0 100%", marginBottom: "10px" }}
                fontSize="14px"
                color="#ffcccc"
              >
                {errors["form"]}
              </Text>
            )}
            <Box component="p" color="#fff" fontSize="14px">
              Already have an account?
              <Box
                onClick={openLogin}
                component="span"
                color="#E9B82A"
                sx={{ cursor: "pointer" }}
              >
                {" "}
                Login
              </Box>
              {errors["serverError"] && (
                <Text
                  styles={{ flex: "0 0 100%", marginBottom: "10px" }}
                  fontSize="14px"
                  color="#ffcccc"
                  marginTop="10px"
                >
                  {errors["serverError"]}
                </Text>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <button
                type="button"
                onClick={ handleCreateAccount }
                style={{
                  background: "transparent",
                  border: "none",
                  margin: "auto",
                }}
              >
                <Text
                  style={{ justifyContent: "center", cursor: "pointer" }}
                  fontSize="18px"
                  color="#E9B82A"
                  isLoading={isLoading}
                >
                  Create Account and Preview Profile
                </Text>
              </button>
            </Box>
          </Box>
        </Box>
      </>
    );
}

export default CreateUserAccountStep;