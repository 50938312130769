import { Box, Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import agents from "../../agents";
import Heading from "../../components/Heading";
import Section from "../../components/Section";
import Text from "../../components/Text";
import { useAuth } from "../../context/AuthContext";

const SignUpForm = (data) => {
  const { signup } = useAuth();

  const [isLoading, updateIsLoading] = useState(false);
  const [signupValues, updateSignupValues] = useState({
    name: "Tate",
    email: "nyasha@deeptechjobs.com",
    password: "Password!2345",
    retypePassword: "Password!2345",
  });

  const [errors, updateErrors] = useState({ errors: [] });

  const handleChange = (prop) => (event) => {
    updateSignupValues({ ...signupValues, [prop]: event.target.value });
  };

  const validate = (data) => {
    if (data.password !== data.retypePassword) {
      return false;
    }

    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      updateIsLoading(true);

      if (!validate(signupValues)) {
        throw Error("Failed");
      }
      const backendResponse = await agents.Auth.signUp(signupValues);

    } catch (e) {
      updateErrors({ errors: ["Failed to create an account"] });
    }

    updateIsLoading(false);
  };

  return (
    <Container sx={{ maxWidth: "600px", width: "100%", marginX: "auto" }}>
      <Box
        sx={{ maxWidth: "600px", width: "100%", margin: "auto" }}
        component="form"
        onSubmit={onSubmit}
        className="form-container"
      >
        <Box>
          <Heading style={{ lineHeight: 1, marginBottom: "40px" }}>
            Create an account
          </Heading>
          <Text marginBottom="40px" fontSize="14px">
            Please submit the information below, and our team will verify that
            you have the authority to manage your company’s profile.
          </Text>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            marginBottom: "10px",
          }}
        >
          <input
            type="text"
            name="search"
            required
            onChange={handleChange("name")}
            placeholder="Your name"
            value={signupValues.name}
          />

          <input
            type="email"
            // id="position-search-dark"
            name="email"
            required
            onChange={handleChange("email")}
            placeholder="Email address"
            value={signupValues.email}
          />

          <input
            type="password"
            // id="position-search-dark"
            name="password"
            required
            onChange={handleChange("password")}
            placeholder="Password"
            value={signupValues.password}
          />

          <Box>
            <Text marginBottom="40px" fontSize="14px">
              Must contain at least one number and one uppercase and lowercase
              letter, and at least 8 or more characters.
            </Text>
          </Box>

          <input
            type="password"
            // id="position-search-dark"
            name="retypePassword"
            required
            onChange={handleChange("retypePassword")}
            placeholder="Retype Password"
            value={signupValues.retypePassword}
          />
        </Box>
        <Box
          sx={{
            marginBottom: "20px",
          }}
          className="container-content"
        >
          <button type="submit" className="content-loadmore-button">
            Create Account
          </button>
        </Box>
      </Box>
    </Container>
  );
};

const Signup = (data) => {
  return (
    <Section
      padding="90px 72px 72px"
      position="relative"
      minHeight="100vh"
      childStyles={{
        position: "absolute",
        width: "100%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <SignUpForm />
    </Section>
  );
};

export default Signup;
