import React from "react";
import { Box, Container } from "@mui/material";

import Heading from "../components/Heading";
import Section from "../components/Section";
import Text from "../components/Text";
import { Helmet } from "react-helmet";

const TermsOfBusiness = () => {
  return (
    <>
      <Helmet>
        <title>Deep Tech Jobs - Terms of Business</title>
      </Helmet>
      <Section padding="180px 72px 72px">
        <Container
          sx={{
            color: "#fff",
          }}
        >
          <Text fontSize="12px">Effective date: 09/05/2023 </Text>
          <Heading
            style={{ lineHeight: "1.1em" }}
            fontSize="calc((6 - 1) * 1.2vh + 1rem)"
          >
            Terms of Business
          </Heading>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>1. INTRODUCTION </Text>
            <p>
              1.1 You are an innovator, Deep Tech or otherwise, looking to grow by recruiting great talent.
            </p>
            <p>
              1.2 We are a platform looking to help you attract and recruit great talent.
            </p>
            <p>
              1.3 These terms aim to form a legally binding contract between you and us that cover the provision of all services by us, 
              including directing talent to your website. What happens on your website thereafter is your responsibility.
            </p>
            <p>
              1.4 The entity that provides the Platform and the Services is Deep Tech Jobs Limited, 
            which is a limited company incorporated in England with registered number 13163773. 
            We refer to ourselves as
              “<b>Deep Tech Jobs</b>”, “<b>we</b>”, “<b>our</b>” and “<b>us</b>”
              and to you as the “<b>Member</b>”, “<b>you</b>” and “<b>your</b>”.
            </p>
            <p>
              1.5 If we refer to any gender, it includes reference to all genders. 
              If we refer to a person, we are referring to all natural persons and 
              any corporate or unincorporated body.
            </p>
            <p>
              1.6 If you use our platform, you are confirming your agreement with these Terms. 
              If you are not in agreement with our Terms, you should not use our Platform.
            </p>
            <p>
              1.7 We may have to alter these Terms from time to time. 
              When we do, we will inform you of any changes and publish our new terms online. 
              Our relationship will then be governed by new Terms, which will be legally binding.
            </p>
          </Box>

          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>2. OUR SERVICES </Text>
            <p>
              2.1 We offer a free membership to the platform (the “Membership”) which provides Members with 
              the ability to attract and recruit Talent on an ongoing basis.{" "}
            </p>

            <p>
              2.2 The Membership includes provision of a profile (a “Profile”) on the Platform and the ability 
              to benefit from the following functionality:{" "}
            </p>

            <p>
              (a) the posting of videos or other media on the Member’s profile;{" "}
            </p>

            <p>
              (b) the showcasing of unlimited current Job Opportunities on the Deep Tech Jobs job board and Member profile,
               via integration with the Member’s Applicant Tracking System (‘ATS’);{" "}
            </p>

            <p>
              (c) the promotion of the Member’s profile 
              and Job Opportunities via social media and other third-party websites.{" "}
            </p>

            <p>
              2.3 Members enjoy access to the Platform so long as their Membership is in place.{" "}
            </p>

            <p>
              2.4 To use the available functionality, you are responsible for ensuring that your 
              social media feeds and ATS are properly connected to your Profile and that information 
              on your ATS is up to date, including notifying us in the event you change your ATS provider 
              or if you modify your Careers page. 
              We will assist you with meeting the Technical Requirements where we can but are not responsible 
              for your compliance with the Technical Requirements. 
              We shall have no liability for any failure by you to comply with the Technical Requirements.{" "}
            </p>

            <p>
              2.5 On termination of a Membership, any data associated with the Member’s Profile will be removed from the Platform and the Profile will cease to be viewable within 45-days. 
              The Member may request a download of any data associated with its Profile and we will aim to meet such request where we can. 
              We will charge an hourly rate of £250 + VAT per hour for the time spent in meeting such a request. 
              The Employer acknowledges and agrees that we may be unable to provide all personal data of third parties associated with the Employer’s Profile.{" "}
            </p>
          </Box>

          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>3. MEMBER PROFILES </Text>

            <p>
              3.1 Any user creating a Member Profile on the Platform is representing that 
              they are acting on behalf of the Company in question and that they have the authority 
              to do so.{" "}
            </p>

            <p>
              3.2 Promotion of your Member Profile goes live on the Platform after email verification.{" "}
            </p>

            <p>
              3.3 For Members joining the Platform for the first time, 
              there may be a 6-hour delay in fully publishing the Member Profile 
              and any associated jobs.{" "}
            </p>

            <p>
              3.4 You will be notified via email once your Member Profile goes live on the Platform.{" "}
            </p>

            <p>
              3.5 You agree to deal fairly and professionally and in accordance with the requirements 
              set forth in the Employment Rights Act 1996, 
              the Equality Act 2010 and any other applicable laws with Candidates 
              who may respond to a Job Opportunity displaying on the Platform through your website.{" "}
            </p>

            <p>
              3.6 You will not do anything which may bring us or the Platform into disrepute.{" "}
            </p>
          </Box>

          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>4. RESTRICTIONS</Text>
            <p>
              4.1 We have rules regarding the content and format of the 
              Member Profiles and Job Opportunities posted on the Platform. 
              The purpose of this is to ensure that Talent who use the 
              Platform get access to information presented as clearly and informatively as possible.{" "}
            </p>

            <p>
              4.2 Except as expressly set out in these Terms or as permitted by applicable law, 
              you undertake 
              not to advertise or post any opportunity or provide any content on your Profile that:{" "}
            </p>

            <p>
              (a) does not comply with the applicable law of the country where the job is to be performed, 
              including laws relating to employment, equal opportunity and eligibility requirements, 
              data privacy, data access and use and intellectual property;{" "}
            </p>

            <p>
              (b) contains false information or solicits employees by intentional misrepresentation,
               such as misrepresentation of the terms of employment, or the hiring entity;{" "}
            </p>

            <p>
              (c) involves any screening requirement where such screening 
              requirement is not an actual and legal requirement of the opportunity in question;{" "}
            </p>

            <p>
              (d) contains any logo or brands or link to website, other than your own or 
              those of any entity for which you are authorised to submit job advertisements;{" "}
            </p>

            <p>
              (e) discriminates against Talent on the basis of race, religion, gender, sexual orientation,
               age, disability or any other ground(s)
               prohibited by applicable law, in each case as determined by our reasonable discretion;{" "}
            </p>

            <p>
              (f) does not clearly disclose whether the Job Opportunity is for an independent contractor 
              or is part-time, piecework, commission-based, or has otherwise non-traditional working conditions 
              or compensation may constitute intentional misrepresentation.{" "}
            </p>

            <p>4.3 You undertake not to: </p>

            <p>
              (a) use our Platform (or the software underlying our Platform, the “Software”) or its content other 
              than for promoting your business and job opportunities for the benefit of your business;{" "}
            </p>

            <p>
              (b) rent, lease, sub-license, loan, translate, merge, adapt, vary, alter or modify, 
              the whole or any part of the Software or Platform nor permit the Software or any part 
              of it to be combined with, or become incorporated in, any other programs;{" "}
            </p>

            <p>
              (c) copy, disassemble, de-compile, reverse engineer or 
              create derivative works based on the Software and/or Platform;{" "}
            </p>

            <p>
              (d) create an Advertisement without reasonable and legitimate intent to hire for 
              a bona fide opportunity or for the specific position as advertised;{" "}
            </p>

            <p>
              (e) create an Advertisement that intentionally misrepresents the Job Opportunity, 
              the hiring company or poster;{" "}
            </p>

            <p>
              (f) creates an Advertisement for “business opportunities” that require payments or recruitment 
              or recruitment of others or that resemble franchises, multi-level marketing, 
              club memberships, distributorships, or are entirely or almost entirely commission-based;{" "}
            </p>

            <p>(g) provides any Personal Information to any other parties; </p>

            <p>
              (h) spam or otherwise contact Talent for purposes other than 
              related to the specific Job Opportunity described in the Advertisement 
              displayed on the Platform; and{" "}
            </p>

            <p>
              (i) harass, stalk or contact any Talent after they have asked not to be contacted.{" "}
            </p>

            <p>
              4.4 You agree that we may, in our sole discretion, remove or prevent the posting 
              of any Member Profile and/or Job Opportunity for any or no reason whatsoever.
              Whilst we have obligation to do so, we reserve the right to review and 
              delete any content (or portion thereof) that we believe, in our sole discretion, 
              breaches these Terms or other applicable polices, or that we deem, 
              in our sole discretion, inappropriate.{" "}
            </p>

            <p>
              4.5 You acknowledge and agree that you are solely responsible for any hiring 
              decisions you make and we are not liable for any incorrect, 
              inaccurate or misleading information provided by any person via the 
              Platform and/or for any loss, harm, damage to reputation and/or cost suffered 
              by you as a result of hiring any Talent from the Platform and/or posting 
              any content to the Platform and/or any matter relating to your use of the Platform, 
              our Services and/or the Membership.{" "}
            </p>
          </Box>

          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>
              5. CANDIDATE DATA PROTECTION
            </Text>

            <p>
              5.1 When a Candidate submits a response to a Job Opportunity directly through your website, 
              details of their application should be uploaded on to your ATS once they have submitted the same 
              (“<b>Candidate Information</b>”) which you acknowledge is outside of our control.{" "}
            </p>

            <p>
              5.2 A Candidate at all times owns their own Candidate Information and you are responsible 
              for processing Candidate Information in accordance with all applicable data protection 
              legislation from time to time in force in the UK including the General Data Protection 
              Regulation ((EU) 2016/679); the Data Protection Act 2018; the Privacy 
              and Electronic Communications Directive 2002/58/EC (as updated by Directive 2009/136/EC) 
              and the Privacy and Electronic Communications Regulations 2003 (SI 2003/2426) 
              as amended (the “<b>Data Protection Legislation</b>”).{" "}
            </p>

            <p>
              5.3 You are a controller of Candidate Information submitted by you to the Platform and received by you from any Candidate. 
              You agree that you will be responsible for ensuring that any 
              Candidate Information entered by a Candidate or held by you about a Candidate is accurate, 
              deleted when no longer required and that you respond in a timely fashion to any request 
              from a Candidate seeking to exercise his rights under Data Protection Legislation,
              including requests which we may receive and pass to you because we are not able to assess them, 
              for example where a Candidate asks for inaccurate data to be corrected.{" "}
            </p>

            <p>
              5.4 We are registered with the ICO in the UK as required by 
              the Data Protection Act 2018 
              (which amongst other things implemented into English law the General Data Protection Regulation (EU) 2016/679 (GDPR)).{" "}
            </p>

            <p>
              5.5 We will never disclose any Candidate Information 
              to any Employer without a Candidate’s prior express written consent. However,
              by applying for a role, a Candidate may disclose personal data you and it is your 
              responsibility to ensure that you comply with Data Protection Legislation thereafter.{" "}
            </p>
          </Box>

          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>
              6. INTELLECTUAL PROPERTY AND PRIVACY RIGHTS
            </Text>
            <p>
              6.1  You grant us a non-exclusive, worldwide, royalty-free, 
              sublicensable and transferable licence to use, copy, modify, distribute and publish your Member Profile 
              and Job Opportunities in connection with the performance of the Services on the Platform.{" "}
            </p>

            <p>
              6.2 Unless otherwise notified to the contrary by you in writing to us, 
              you hereby provide us with your consent to use and reproduce your name, 
              logo and trademarks within our advertising 
              via the Platform provided we shall not materially change or otherwise amend your name, 
              logo or trademark without your prior written consent. 
              Nothing in this provision shall create any rights 
              of ownership (whether registrable or not) in favour of us over your intellectual property.{" "}
            </p>

            <p>
              6.3 You acknowledge that all intellectual property rights in the Platform throughout 
              the world belong to us, and that you have no intellectual property rights in, or to, 
              the Platform other than the right to use the Platform in accordance with these Terms.{" "}
            </p>

            <p>
              6.4 You acknowledge that your trademarks and service marks may be published on or viewable 
              via the Platform from time to time. You represent and warrant that the trademarks 
              and service marks belong to the Member and that you own or have all necessary rights to the Job Opportunities. 
              You as Member are in no 
              way connected to Deep Tech Jobs and the inclusion on our Platform of a link to any post or to 
              your website does not mean and should not be construed as meaning that you are related to Deep 
              Tech Jobs or have been authorised by us to act for Talent in respect of a Job Opportunity.{" "}
            </p>

            <p>
              6.5 If you choose to provide suggestions or feedback to us about the Services, 
              you agree that we can (but do not have to) use and share such feedback 
              for any purpose without compensation to you.{" "}
            </p>
          </Box>

          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>
              7. HOW WE LIMIT OUR LIABILITY
            </Text>
            <p>
              7.1 We do not exclude or limit in any way our liability to 
              you where it would be unlawful to do so. 
              This includes liability 
              for death or personal injury caused by our negligence or the negligence of our employees, 
              agents or subcontractors or for fraud or fraudulent misrepresentation.{" "}
            </p>

            <p>
              7.2 You acknowledge that the Platform has not been developed to meet 
              any individual requirements, and that it is therefore your responsibility 
              to ensure that the device(s) 
              via which you access the Platform and our Service is appropriate.{" "}
            </p>

            <p>
              7.3 You are responsible for ensuring that you are within your rights to upload any content, 
              including videos, to the Platform and that you have the authority to use any content, 
              including videos for the purpose of attracting and recruiting Talent to your Company.{" "}
            </p>

            <p>
              7.4 You are responsible for maintaining suitable anti-virus software on the device(s) via which you access the Platform. 
              We take reasonable steps to prevent viruses being disseminated via 
              the Platform but exclude all liability for any damage caused to your device(s) arising from 
              any virus or other malicious code made available directly or indirectly via the Platform.{" "}
            </p>

            <p>
              7.5 We have no obligation to present any content that you ask us to upload on to the Platform. 
              We are not liable for any failure to recruit a Candidate after a Member Profile 
              or Job Opportunity has been displaying on our Platform for any period of time.{" "}
            </p>

            <p>
              7.6 We assume no responsibility, and disclaim all liability for the content, 
              accuracy, completeness, legality, reliability, or availability of any content, 
              screening question, 
              assessment and/or other information provided by or relating to any Member.{" "}
            </p>

            <p>
              7.7 We make no claim of ownership over any content, including videos, 
              featured on Member’s Profiles or in other areas of the Platform where 
              it is attributed to a Member. 
              All copyright and credit is attributed to the rightful owner.{" "}
            </p>

            <p>
              7.8 We are not liable if any role secured by Talent after being 
              found through the Platform is not suitable for the Candidate, 
              or if you suffer any loss (howsoever arising) 
              by reason of offering any role made available to Talent 
              as advertised through the Platform.{" "}
            </p>

            <p>
              7.9 We may reformat the way in which you see any content in order to optimise 
              it for the device(s) you are viewing such content on. We are not liable 
              if the information you see as a result of us reformatting it is incomplete or incorrect.{" "}
            </p>

            <p>
              7.10 We are not liable for the content of any Member’s website. 
              If Talent decides to click a link for a Member’s website, 
              they do so entirely at their own risk, 
              and may become subject to any terms and/or privacy policy imposed 
              by that Employer website.{" "}
            </p>

            <p>
              7.11 Neither Deep Tech Jobs nor any of our directors, officers,
              employees, consultants or agents will be liable to you for any loss, 
              liability, damage, cost, claim, expense or inconvenience whatsoever 
              suffered or incurred by you or any other third party,
              arising out of or in connection with your use of the Platform and the publication 
              of any content that directs Talent to your website from our Platform,
              including whether arising under contract, tort (including negligence),
              or otherwise and whether arising from any misrepresentation of, or by you, or incorrect
              or misleading information provided by you, or any act or omission by you, or otherwise. 
              We will not be liable for any losses suffered by you, 
              whether arising under contract, tort (including negligence) including without limitation, 
              loss of or corruption of data, loss of profits or of contracts, 
              loss of earnings, goodwill or anticipated savings and damage to reputation.{" "}
            </p>

            <p>
              7.12 Our total liability to you (whether in contract, tort, including negligence, 
              or otherwise) 
              under or in connection with these Terms will not exceed in aggregate the total amount 
              paid by you to us in respect of Charges and/or Membership Fees during the 12 months 
              prior to the date on which your claim against us arose, or £1,000 (whichever is greater).{" "}
            </p>

            <p>
              7.13 You agree that this Section 7 sets out our entire liability to you.{" "}
            </p>
          </Box>

          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>8. TERMINATION</Text>
            <p>
              8.1 We may terminate these Terms (including your Membership of the Platform 
              and the Services) immediately by written notice to you if you commit 
              a material or persistent breach of these Terms, 
              or otherwise by giving 7 days prior written notice (which may be by email).{" "}
            </p>

            <p>
              8.2 Upon termination for any reason, 
              you will no longer be able to access the Platform and/or the Services.{" "}
            </p>
            <p>
              8.3 Members may terminate their Membership by sending an email to {" "}
              <a
                style={{
                  color: "#edbb2a",
                  textDecoration: "underline !important",
                }}
                href="mailto: support@deeptechjobs.com"
              >
                support@deeptechjobs.com
              </a>
              {" "}
              from the email address associated with the 
              Member Profile. This process may take up to 14 days to be completed 
              and confirmation will be communicated through email.
            </p>
          </Box>

          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>9. GENERAL </Text>
            <p>
              9.1 You may contact Deep Tech Jobs by sending an email to{" "}
              <a
                style={{
                  color: "#edbb2a",
                  textDecoration: "underline !important",
                }}
                href="mailto: support@deeptechjobs.com"
              >
                support@deeptechjobs.com
              </a>
              . We may communicate with you by email or other electronic communication 
              unless you tell us not to. Communication by email is not entirely secure. 
              We will not be liable to you for any losses you 
              suffer by reason of corruption of any information contained in an electronic communication.{" "}
            </p>
            <p>
              9.2 Any failure or delay by a party to exercise any right
               or remedy provided under the Agreement or by law shall not 
               constitute a waiver of that or any other right or remedy, 
               nor shall it preclude or restrict the further exercise of that 
               or any other right or remedy. 
               No single or partial exercise of such right or 
              remedy shall preclude or restrict the further exercise of that or 
              any other right or remedy.{" "}
            </p>
            <p>
              9.3 If any provision of these Terms (or part of any provision) is found 
              by any court or other authority of competent jurisdiction to be invalid, 
              illegal or unenforceable, that provision or part-provision shall, 
              to the extent required, be deemed not to form part of these Terms, and 
              the validity and enforceability of the other provisions 
              of these Terms will not be affected.{" "}
            </p>
            <p>
              9.4 We will not be liable for any delay in performing or failure 
              to perform our obligations under these Terms due to causes outside our reasonable control.{" "}
            </p>
            <p>
              9.5 These Terms are a complete and exhaustive statement 
              of the agreement between us relating to the Services. 
              In the absence of fraud, all previous communications, representations 
              and other arrangements, 
              oral or written are terminated and cannot be relied on by either party.{" "}
            </p>
            <p>
              9.6 You shall not, without our prior written consent, assign, transfer, 
              charge, mortgage, subcontract or deal in any other manner with 
              all or any of your rights or obligations under these Terms. 
              We may at any time assign, transfer, charge, mortgage, subcontract
              or deal in any other manner with all or any of our rights 
              or obligations under these Terms.{" "}
            </p>
            <p>
              9.7 These Terms do not create a partnership between us, 
              or constitute either of us as the agent for the other. Neither of us has authority 
              to act in the name or on behalf of or otherwise to bind the other in any way.{" "}
            </p>
            <p>
              9.8 A person who is not a party to these Terms shall not have any rights 
              under or in connection with them.
            </p>
            <p>
              9.9 These Terms and any dispute or claim arising out of or in connection 
              with them or their subject matter, shall be governed by, 
              and construed in accordance with, the law of England and Wales and 
              we both irrevocably submit to the exclusive jurisdiction 
              of the courts of England and Wales.{" "}
            </p>
          </Box>
        </Container>
      </Section>
    </>
  );
};

export default TermsOfBusiness;
