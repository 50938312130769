import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import environment from './environment';

const app = firebase.initializeApp({
  apiKey: environment.REACT_APP_FIREBASE_API_KEY,
  authDomain: environment.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: environment.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: environment.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: environment.REACT_APP_FIREBASE_MEASUREMENT_ID,
  appId: environment.REACT_APP_FIREBASE_APP_ID,
  measurementId: environment.REACT_APP_FIREBASE_MEASUREMENT_ID
});

export const auth = app.auth();

export default app;
