import { Box } from "@mui/material";
import Text from "../../../components/Text";

const HeaderComponent = ( { arrowBackComponent, title } ) => (
    <Box sx={{ position: "relative", paddingTop: "20px" }}>
            { arrowBackComponent }
            <Text
              fontSize="24px"
              styles={{ textAlign: "center", justifyContent: "center" }}
            >
              { title }
            </Text>
    </Box>
);

export default HeaderComponent;