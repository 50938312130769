import React, { useEffect, useRef, useState } from "react";
import { Box, MenuItem, Select } from "@mui/material";
import Modal from "@mui/material/Modal";
import validator from "validator";
import { validateImage } from "image-validator";
import { modalActions } from './constants';
import { convertMedia } from "../../utils/utils";
import FindCompanyStep from "./UserSteps/FindCompanyStep/FindCompanyStep";
import { SIGNUP_STAGES } from "./UserSteps/constants";
import CreateCompanyProfileStep from "./UserSteps/CreateCompanyProfileStep/CreateCompanyProfileStep";
import CreateUserAccountStep from "./UserSteps/CreateUserAccountStep/CreateUserAccountStep";
import { Company_Profile_Upload } from "../../constants/company";

const _Modal = (props) => {
  const {
    open,
    onClose,
    isLoading,
    companies,
    formData,
    setFormData,
    updateForm,
    action
  } = props;

  const [preview, setPreview] = useState();

  const [errors, setErrors] = useState({});
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const getStepInitialValue = () => {
    switch(action) {
      case modalActions.create:
        return SIGNUP_STAGES.FIND_COMPANY;
      case modalActions.edit:
        return SIGNUP_STAGES.CREATE_PROFILE;
      default:
        return null;    
    }
  }; 
  const [step, setStep] = useState(getStepInitialValue());
  const assignNextStep = (stepName) => setStep(stepName);
  const fileInput = useRef(null);

  const updateCompany = (data) => {
    setFormData({
      id: data.id || "",
      companyName: data.companyName || "",
      logo: data.logo || "",
      logoPreview: data.logoPreview || null,
      logoImageFile: data.logoImageFile || null,
      technologyClassification: data.technologyClassification || "",
      companyCity: data.companyCity || "",
      companyLocation: data.companyLocation || "",
      mediaUrl: data.mediaUrl || "",
      companyImage: data.companyImage || "",
      companyImageFile: data.companyImageFile || "",
      companyProfileUpload: data.companyProfileUpload || Company_Profile_Upload.Video,
      websiteUrl: data.website || data.websiteUrl || "",
      twitterUrl: data.twitter || data.twitterUrl || "",
      linkedInUrl: data.linkedIn || data.linkedInUrl || "", 
      problemStatement: data.problemStatement || "",
      firstName: data.firstName || "",
      lastName: data.lastName || "",
      position: data.position || "",
      userEmail: data.userEmail || "",
      password: data.password || "",
      passwordRepeat: data.passwordRepeat  || "",
      isNewCompany: data.isNewCompany,
      atsName: data.atsName || "",
      atsUserName: data.atsUserName || "", 
      atsApiKey: data.atsApiKey || ""  
    });
  };

  

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxWidth: "800px",
    width: { xs: "calc(100% - 10px)", md: "calc(100% - 40px)" },
    transform: "translate(-50%, -50%)",
    background: "#161A21 0% 0% no-repeat padding-box",
    borderRadius: "16px",
    opacity: 1,
    padding: { xs: "20px", md: "0px 72px 0px 72px" },
  };



  const getModalStyle = (step) => {
    const padding = { xs: "20px", md: {} }; 
    if(step == SIGNUP_STAGES.FIND_COMPANY || step == SIGNUP_STAGES.CREATE_ACCOUNT)
        padding.md = "72px";
    else if(step == SIGNUP_STAGES.CREATE_PROFILE)
        padding.md = "20px 65px 20px 65px";
    return {
        position: "absolute",
        top: "50%",
        left: "50%",
        maxWidth: "800px",
        width: { xs: "calc(100% - 10px)", md: "calc(100% - 40px)" },
        transform: "translate(-50%, -50%)",
        background: "#161A21 0% 0% no-repeat padding-box",
        borderRadius: "16px",
        opacity: 1,
        padding: padding,
        maxHeight: '100%',
        overflow: 'auto'
    }     
  }

  // TODO: remove
  const dropDownMenuStyles = {
    '& .MuiMenu-list': { 
      backgroundColor: "#1F2227",
      color: "#d2d3d8",
      '& .MuiMenuItem-root': {
        '&:hover': { backgroundColor: "#161A21" }
      }
    }
  }

  const onSelectFile = (e) => {
    setErrors({});
    if (!e.target.files || e.target.files.length === 0) {
      // setSelectedFile(undefined);
      return;
    }

    const file = e.target.files[0];

    if (file.size > 307200) {
      setErrors({ logo: "File size cannot exceed 300kb" });
      return;
    }

    setPreview(URL.createObjectURL(file));
    setFormData({ ...formData, logoImageFile: file });
  };

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  }; 
  const _onClose = () => {
    setErrors({});
    setPreview(undefined);
    
    if(action == modalActions.create){
      assignNextStep(SIGNUP_STAGES.FIND_COMPANY);
      setFormData({});
    } 
      
    else if(action == modalActions.edit)
      assignNextStep(SIGNUP_STAGES.CREATE_PROFILE);
      onClose();
  };

  

  return (
    <Modal open={open} onClose={_onClose} action={action}>
      <Box sx={getModalStyle(step)}>
            { step == SIGNUP_STAGES.FIND_COMPANY && (
               
                <FindCompanyStep 
                    isLoading={isLoading}
                    updateCompany={updateCompany}
                    companies={companies}
                    updateForm={updateForm}
                    assignNextStep={assignNextStep}
                />
            )}
            { step == SIGNUP_STAGES.CREATE_PROFILE && (
              <CreateCompanyProfileStep 
                formData={formData} 
                assignNextStep={assignNextStep} 
                updateCompany={updateCompany}
                action={action}
                companyStatus={props.companyStatus}
                onSuccessFlow={() => _onClose()}
                />    
            )}
            {step == SIGNUP_STAGES.CREATE_ACCOUNT && (
               <CreateUserAccountStep 
                  formData={formData}
                  assignNextStep={assignNextStep}
                  updateCompany={updateCompany}
                  onSuccessFlow={() => _onClose()}
                  onClose={props.onClose}
                  action={action}
               />
            )}
            
      </Box>
    </Modal>
  );
};

export default _Modal;
