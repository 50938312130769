import { Box, Container } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Heading from "../../components/Heading";
import JobListComponent from "./JobListComponent";
import JobsSearchBarComponent from "./JobsSearchBarComponent";
import { InsightsBanner } from "../../components/Banners";
import Section from "../../components/Section";
import Text from "../../components/Text";
import SuccessfulSignUpModal from './SuccessfulSignUpModal';
import { useUser } from "../../context/UserContext";
import { useAuth } from "../../context/AuthContext";
import agents from "../../agents";
import Modal from '../Membership/Modal';
import { modalActions } from "../Membership/constants";
import { mapCompanyToFormData, mapUserToFormData } from '../../data/mappers';
import { Company_Profile_Upload } from "../../constants/company";

const CompanyProfileVideo = ({ videoLink }) => {
  const iframe = `      <iframe
      style="
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }
    "
      src="${videoLink}"
      title="YouTube video player"
      frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
    ></iframe>`;

  return (
    <Box
      sx={{
        height: "100%",
        flex: 1,
      }}
    >
      <Box>
        <Box
          sx={{
            position: "relative",
            paddingTop: "56.25%",
          }}
          dangerouslySetInnerHTML={{ __html: iframe }}
        ></Box>
      </Box>
    </Box>
  );
};

const ClaimCompanyProfile = (props) => {
  const { onOpen } = props;
  const navigate = useNavigate();
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  
  const initCompanyFormData = (company) => {
    const data = mapCompanyToFormData(company);
    if(data.mediaUrl)
      data.companyProfileUpload = Company_Profile_Upload.Video;
    else if(data.companyImage)
      data.companyProfileUpload = Company_Profile_Upload.Image;
    return data;  
  };
  const [formData, setFormData] = useState({
    ...initCompanyFormData(props.company), 
    ...mapUserToFormData(props.userData)
  });

  
  return (
    <Box sx={{ padding: { xs: "20px", lg: "32px 50px" } }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", lg: "space-between" },
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ marginBottom: "20px", flex: 4, marginRight: "20px" }}>
          <Heading
            fontSize="27px"
            styles={{ padding: "0px", margin: "0px 0px 8px !important" }}
          >
            Looks good! Let’s finish things up.
          </Heading>
          <Text
            fontSize="17px"
            styles={{ padding: "0px", margin: "0px 0px 8px !important" }}
          >
            If you are happy with how everything looks, your profile and jobs are ready to go live.
          </Text>
        </Box>
        <Box
          sx={{
            flex: { xs: "0 0 100%", md: 1 },
            display: "flex",
            justifyContent: { xs: "center", lg: "flex-end" },
          }}
        >
          <Modal
              open={showSignUpModal}
              onClose={() => setShowSignUpModal(false)}
              isLoading={false}
              companies={[]}
              company={{}}
              formData={formData}
              companyStatus={props.company.status}
              setFormData={setFormData}
              updateForm={()=>{}}
              action={modalActions.edit}
            />
          <button
            onClick={() => setShowSignUpModal(true) }
            style={{
              letterSpacing: "0px",
              opacity: "1",
              background: "0% 0% no-repeat padding-box",
              border: "1px solid #FFFFFF",
              borderRadius: "24px",
              opacity: "1",
              width: "176px",
              height: "48px",
              cursor: "pointer",
              marginRight: "10px"
            }}
          >
            <Text color="#FFFFFF" style={{ justifyContent: "center" }}>
              Make Changes
            </Text>
          </button>
          <button
            onClick={ props.onFinishProfileClick }
            style={{
              letterSpacing: "0px",
              opacity: "1",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              border: "none",
              borderRadius: "24px",
              opacity: "1",
              width: "176px",
              height: "48px",
              cursor: "pointer",
            }}
          >
            <Text color="#6553E8" style={{ justifyContent: "center" }}>
              Finish Profile
            </Text>
          </button>
        </Box>
      </Box>
    </Box>
  );
};

const CompanySection = (data) => {
  const [open, setOpen] = useState(false);
  const { currentUser, signup } = useAuth();
  const { userDetails, setUser } = useUser();
  const [openSignUpSuccessModal, setOpenSignUpSuccessModal] = useState(false);
  
  const {
    isLoading,
    companyId,
    openModal,
    setOpenModal,
    currentModalProcess,
    setCurrentModalProcess,
  } = data;
  const { company, userData } = data.data;
  
  // state
  const [jobs, setJobs] = useState(data.data.jobs);

  const sendVerificationEmail = async () => {
     try{
        await agents.CompanyProfile.postClaimCompanyProfile();
        return true;
     } 
     catch(e) {
      return false;
     }
  }

  // event handlers
  const onFinishProfileClick = async () => {
    const isEmailSent = await sendVerificationEmail();
    if(isEmailSent)
      setOpenSignUpSuccessModal(true); 
  };

  const handleSearchJobsChange = async ( { keywords, city, location, onlyRemoteJobs } ) => {
    try {
      const jobsResponse = await agents.Companies.getCompanyJobs(companyId, { keywords, city, location, onlyRemoteJobs } );
      setJobs(jobsResponse.data);
    } catch(ex) {
      console.error(`Can't get company jobs`);
      setJobs(jobs);
    }

    
  };

  
  
  const getClickableLink = (link) => {
    return link.startsWith("http://") || link.startsWith("https://")
      ? link
      : `https://${link}`;
  };

  const technology = company.basicInformation.technologyClassification1
    .toLowerCase()
    .replace("&", "")
    .replace(".", "-")
    .replace("/", "-")
    .replace("  ", " ")
    .trim()
    .split(" ")
    .join("-");

  const onCloseModal = () => {
    setOpenModal(false);
    setOpen(false);
  };

  return (
    <>
      <Section backgroundColor="DARK_LIGHT" padding="180px 0 72px">
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Container
            sx={{
              flex: 1,
              margin: { xs: "40px 0 20px", md: "0 0 20px" },
              display: "flex",
            }}
          >
            {company.profileMedia.CompanyImageUrl && (
                <Box sx={{ display: "flex" }}>
                <img
                  src={company.profileMedia.CompanyImageUrl}
                  alt={company.companyName + " on Deep Tech Jobs"}
                  style={{
                    objectFit: "cover",
                    width: "724px",
                    height: "380px",
                    borderRadius: "10px",
                    maxWidth: '100%',
                    height: 'auto',
                    padding: 0,
                    margin: 0
                  }}
                />
              </Box>
            )}
            {company.profileMedia.MediaUrl && (<CompanyProfileVideo videoLink={company.profileMedia.MediaUrl} />)}
            
          </Container>
          <Container sx={{ flex: 1 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                marginTop: company.profileMedia.CompanyImageUrl ? '15px' : '0px'
              }}
            >
              <Box sx={{ height: "96px", width: "96px", display: "flex" }}>
                <img
                  src={company.logoImageUrl}
                  alt={company.companyName + " on Deep Tech Jobs"}
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    padding: "5px",
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                />
              </Box>
              <Box sx={{ padding: "30px 20px" }}>
                <Heading
                  variant="h1"
                  fontSize="1.8em"
                  styles={{ marginBottom: "0", padding: "0", lineHeight: 1.1 }}
                >
                  {company.companyName}
                </Heading>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {company.profileMedia.Website && (
                    <a
                      href={getClickableLink(company.profileMedia.Website)}
                      target="_blank"
                    >
                      <Text color="#edbb2a" fontWeight="600" marginRight="10px">
                        Website
                      </Text>
                    </a>
                  )}
                  {company.profileMedia.LinkedIn && (
                    <a
                      href={getClickableLink(company.profileMedia.LinkedIn)}
                      target="_blank"
                    >
                      <Text color="#0077b5" fontWeight="600" marginRight="10px">
                        LinkedIn
                      </Text>
                    </a>
                  )}

                  {company.profileMedia.Twitter && (
                    <a
                      href={getClickableLink(company.profileMedia.Twitter)}
                      target="_blank"
                    >
                      <Text color="#1da1f2" fontWeight="600">
                        Twitter
                      </Text>
                    </a>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <span className={"card-sector " + technology}>
                {company.basicInformation.technologyClassification1}
              </span>
            </Box>
            <Box>
              <Text fontSize="1.6rem">
                {company.basicInformation.problemStatement}
              </Text>
            </Box>
          </Container>
        </Box>
      </Section>
      <Section
        backgroundColor="DARK"
        title="Jobs"
      >
        
        {
          (jobs) ?
          (
              <>
                <JobsSearchBarComponent onSearchParamsChange = { handleSearchJobsChange } /> 
                { 
                  (jobs.length) ?  
                  <JobListComponent jobs={ jobs } isLoading={isLoading} /> 
                  : null
                }
              </>
          )
          :
          (<> 
            <Box>
                <Text fontSize="1.6rem">
                    No Current Vacancies
                </Text>
            </Box>
            <Box style={{ marginTop: '20px' }}>
              <Text fontSize="1rem">
                  Please check again soon.
              </Text>
            </Box>
          </>)
        }
      </Section>
      {/* <InsightsContainer backgroundColor="DARK_LIGHT" /> */}
      <InsightsBanner />
      {!isLoading &&
        company.needsEmailVerification &&
        (
          <Box
            sx={{
              position: "fixed",
              background: "#6553E8 0% 0% no-repeat padding-box",
              bottom: "0",
              left: "0",
              width: "100%",
            }}
          >
            <ClaimCompanyProfile
              onOpen={() => setOpen(true)}
              onFinishProfileClick = { onFinishProfileClick }
              company = {{...company, ...{id: companyId}}}
              userData = { userData }

            />
            <SuccessfulSignUpModal open={openSignUpSuccessModal} 
                sendEmailHandler={sendVerificationEmail} closeHandler={()=>setOpenSignUpSuccessModal(false)} />
          </Box>
        )} 
    </>
  );
};

export default CompanySection;