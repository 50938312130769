import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

const TextLoading = () => (
  <Box
    sx={{
      height: "17px",
      width: "100%",
      maxWidth: "600px",
      background: "#fdfdfd",
    }}
  ></Box>
);

const Text = (data) => {
  const { fontSize, styles, color, isLoading, children, ...rest } = data;

  const _style = {
    color: color || "#fff",
    display: "flex",
    alignItems: "center",
    fontSize: fontSize || "16px",
    fontFamily: "Work Sans",
    ...styles,
  };

  return isLoading ? (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <CircularProgress sx={{ color: color || "#fff" }} />
    </Box>
  ) : (
    <Typography {..._style} {...rest} variant="p">
      {children}
    </Typography>
  );
};

export default Text;
