import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Box, MenuItem, Select } from "@mui/material";
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';

import Heading from "../../../../components/Heading";
import AuthedSubNavigation from "../AuthedSubNavigation";

import agents from "../../../../agents";
import Text from "../../../../components/Text";
import Section from "../../../../components/Section";
import validateCompanyProfile from './formValidation';
import { useModal } from "../../../../context/ModalContext";
import { Helmet } from "react-helmet";
import { mapCompanyToFormData } from '../../../../data/mappers';
import { RESPONSE_STATUS_CODES } from "../../../../constants/response";
import { notAuthorizedHandler } from "../../../../utils/errors/responseErrorHandlers";
import CompleteCommandModal from "../CompleteCommandModal";
import { COUNTRY_CODES } from "../../../../constants/countries";
import { Company_Profile_Upload } from '../../../../constants/company';

const getCountryMenuItems = () => { 
  const items = [];
  for(const key in COUNTRY_CODES) {
    items.push(
      <MenuItem key={key} value={COUNTRY_CODES[key]}>{COUNTRY_CODES[key]}</MenuItem>
    )
  }
  return items; 
};



const AuthedCompanyProfile = () => {  
  const { updateModal } = useModal();

  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [errors, setErrors] = useState({});
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [modalErrorProp, setModalErrorProp] = useState({});
  const navigate = useNavigate();

  const fileInputLogo = useRef(null);
  const fileInputCompanyImage = useRef(null); 

  const dropDownMenuStyles = {
    "& .MuiMenu-list": {
      backgroundColor: "#1F2227",
      color: "#d2d3d8",
      "& .MuiMenuItem-root": {
        "&:hover": { backgroundColor: "#161A21" },
      },
    },
  };

  const style = {
    maxWidth: "800px",
    width: { xs: "100%", sm: "calc(100% - 40px)" },
    margin: "0 auto 0px",
    borderRadius: "16px",
    overflow: "hidden",
    opacity: 1,
    marginTop: "32px",
    marginBottom: "32px"
  };

  const topBottomStyle = {
    background: "#1F2227 0% 0% no-repeat padding-box",
    padding: { xs: "20px", sm: "20px 32px" },
  };

  const boxStyle = {
    padding: { xs: "20px", sm: "20px 32px" },
    marginTop: "2px",
    background: "#1F2227 0% 0% no-repeat padding-box",
  };

  const fetchData = async () => {
    try {
      const response = await agents.CompanyProfile.getCompanyProfile();
      updateCompany(response.data);
      setIsLoading(false);
    }  
    catch(e) {
      if(e.response?.status === RESPONSE_STATUS_CODES.NOT_AUTHORIZED) {
        notAuthorizedHandler();
        // this opens login popup.
        updateModal(true);
      }
      else if(e.response?.status === RESPONSE_STATUS_CODES.ACCESS_FORBIDDEN) {
        navigate('/404');
      }
      else {
        setModalErrorProp({ message: `Failed to Load Company Data.
          Please sign-out and sign-in, then try again. 
          If this issue persists, please contact support.` });
        setOpenSuccessModal(true);
      }   
    }
    };
  

  const updateCompany = (data) => {
    const formData = mapCompanyToFormData(data);
    if(formData.mediaUrl)
      formData.companyProfileUpload = Company_Profile_Upload.Video;
    else if(formData.companyImage)
      formData.companyProfileUpload = Company_Profile_Upload.Image;
    setFormData(formData);
  };

  const onSelectLogoFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file.size > 307200) {
      setErrors({ logo: "File size cannot exceed 300kb" });
      return;
    }
    
    if(formData.logoPreview)
      URL.revokeObjectURL(formData.logoPreview);
    const logoPreview = URL.createObjectURL(file);
    setFormData({ ...formData, logoImageFile: file, logoPreview: logoPreview });
  };

  const onSelectCompanyImageFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if(formData.companyImagePreview)
      URL.revokeObjectURL(formData.companyImagePreview);
    const companyImagePreview = URL.createObjectURL(file);
    setFormData({ ...formData, companyImageFile: file, companyImagePreview: companyImagePreview });  
  };

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  };


  const handleRadioButtonChange = (prop) => (event) => {
    const newData = {
      [prop]: event.target.value
    };
    if(event.target.value === Company_Profile_Upload.Video) {
       newData.companyImageFile = '';
       URL.revokeObjectURL(formData.companyImagePreview);
       newData.companyImagePreview = null;
    }
      
    else if (event.target.value === Company_Profile_Upload.Image) {
      newData.mediaUrl = '';
    }
      
    setFormData({ ...formData, ...newData });  
  }

  const handleSubmit = async () => {
    setErrors({});
      
    const { formIsValid, errors, convertedMediaUrl } = await validateCompanyProfile(formData);

    if (!formIsValid) {
      setErrors(errors);
      return;
    }

    try {
      let dataToSend = formData;
      if(formData.companyProfileUpload === Company_Profile_Upload.Video) {
        delete dataToSend.companyImageFile;
        delete dataToSend.companyImage;
        dataToSend = { ...dataToSend,  ...{mediaUrl: convertedMediaUrl}};
      }
      else if(formData.companyProfileUpload === Company_Profile_Upload.Image) {
        delete dataToSend.mediaUrl;
      }
      
      const formDataToSend = new FormData();
      for (const key in dataToSend) {
         formDataToSend.set(key, dataToSend[key]) 
      };
      const response = await agents.CompanyProfile.updateCompanyProfile(formDataToSend);
      if(formData.logoImageFile)
        setFormData({ ...formData, logoImageFile: '' });
      setOpenSuccessModal(true);
    }
    catch(e){
      setModalErrorProp({ message: `Failed to Update Company Data.
          Please sign-out and sign-in, then try again. 
          If this issue persists, please contact support.` });
      setOpenSuccessModal(true);      
    }
  };

  useEffect( () => {
    (async () => await fetchData())();  
    
    return () =>  { 
      if(formData.logoPreview)
        URL.revokeObjectURL(formData.logoPreview);
      if(formData.companyImagePreview)
        URL.revokeObjectURL(formData.companyImagePreview);   
    };
  }, [] );

  return (
    <>
      <Helmet>
        <title>Deep Tech Jobs - Company Profile</title>
      </Helmet>
      <AuthedSubNavigation />
      <Section
        backgroundColor="DARK"
        padding={{ xs: "32px 10px 10px", md: "32px 72px 0" }}
      >
        {!isLoading && (
          <Box sx={{ ...style }}>
            <Box sx={{ ...topBottomStyle }}>
              <Heading fontSize="18px" styles={{ marginBottom: "8px" }}>
                Company Profile
              </Heading>
            </Box>
            <Box sx={{ ...boxStyle }}>
              <Text>Brand and location</Text>
              <Box
                className="membership-flex"
                sx={{
                  marginBottom: "16px",
                  marginTop: "16px",
                }}
              >
                <input
                  type="text"
                  name="search"
                  placeholder="Company Name"
                  onChange={handleChange("companyName")}
                  value={formData.companyName}
                  className="membership-input"
                />
                {errors["companyName"] && (
                  <Text
                    styles={{ flex: "0 0 100%" }}
                    fontSize="14px"
                    color="#ffcccc"
                  >
                    {errors["companyName"]}
                  </Text>
                )}
              </Box>

              <Box className="membership-flex" sx={{ marginBottom: "16px" }}>
                <Box
                  className="membership-flex"
                  sx={{
                    flex: { xs: "100%", sm: "1" },
                    marginRight: { xs: "0", sm: "16px" },
                    marginBottom: { xs: "16px", md: "0" },
                  }}
                >
                  <input
                    type="text"
                    name="search"
                    placeholder="Location"
                    onChange={handleChange("companyCity")}
                    value={formData.companyCity}
                    className="membership-input"
                  />
                  {errors["companyCity"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["companyCity"]}
                    </Text>
                  )}
                </Box>
                <Box className="membership-flex">
                  {/* <FormControl fullWidth> */}
                  <Select
                    className="membership-input"
                    MenuProps={{ sx: dropDownMenuStyles }}
                    sx={{ paddingLeft: "8px !important" }}
                    value={formData.companyLocation || ""}
                    onChange={handleChange("companyLocation")}
                  >
                    { getCountryMenuItems() }
                    
                  </Select>
                  {errors["companyLocation"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["companyLocation"]}
                    </Text>
                  )}
                </Box>
              </Box>
              <Box
                className="membership-flex"
                sx={{
                  flex: { xs: "100%", sm: "1" },
                  marginBottom: { xs: "16px", md: "0" },
                }}
              >
                
                <Box className="membership-flex">
                  {formData.logoPreview || formData.logo ? (
                    <img
                      src={formData.logoPreview || formData.logo}
                      style={{
                        width: "48px",
                        height: "48px",
                        /* UI Properties */
                        background:
                          "transparent url('img/Image 150.png') 0% 0% no-repeat padding-box",
                        borderRadius: "48px",
                        opacity: "1",
                        marginRight: "16px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "48px",
                        height: "48px",
                        background: "#888888",
                        borderRadius: "48px",
                        opacity: "1",
                        marginRight: "16px",
                      }}
                    ></Box>
                  )}
                  <Box
                    component="button"
                    type="button"
                    onClick={() => fileInputLogo.current.click()}
                    className="membership-button"
                    sx={{ flex: { xs: "1", md: "0 0 calc(50% - 74px)" } }}
                  >
                    <Text>Upload company logo</Text>
                  </Box>
                  <input
                    hidden
                    ref={fileInputLogo}
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={onSelectLogoFile}
                  />
                  {errors["logo"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["logo"]}
                    </Text>
                  )}
                </Box>
              </Box>      
            </Box>

            <Box sx={{ ...boxStyle }}>
              <Text>Brand and location</Text>

              <Box
                className="membership-flex"
                sx={{
                  alignItems: "flex-start !important",
                  marginBottom: "16px",
                  marginTop: "16px",
                }}
              >
                <Box
                  className="membership-flex"
                  sx={{
                    flex: { xs: "100%", sm: "1" },
                    marginRight: { xs: "0", sm: "16px" },
                    marginBottom: { xs: "16px", md: "0" },
                  }}
                >
                  <input
                    type="text"
                    name="search"
                    placeholder="Website"
                    onChange={handleChange("websiteUrl")}
                    value={formData.websiteUrl}
                    className="membership-input"
                  />

                  {errors["websiteUrl"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["websiteUrl"]}
                    </Text>
                  )}
                </Box>
                <Box className="membership-flex">
                  <input
                    type="text"
                    name="search"
                    placeholder="Twitter"
                    onChange={handleChange("twitterUrl")}
                    value={formData.twitterUrl}
                    className="membership-input"
                  />
                  {errors["twitterUrl"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["twitterUrl"]}
                    </Text>
                  )}
                </Box>
              </Box>

              <Box className="membership-flex" sx={{ marginBottom: "16px" }}>
                <Box
                  className="membership-flex"
                  sx={{
                    flex: { xs: "100%", sm: "0 0 calc(50% - 10px)" },
                    marginBottom: { xs: "16px", md: "0" },
                  }}
                >
                  <input
                    type="text"
                    name="search"
                    placeholder="LinkedIn"
                    onChange={handleChange("linkedInUrl")}
                    value={formData.linkedInUrl}
                    className="membership-input"
                  />
                  {errors["linkedInUrl"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["linkedInUrl"]}
                    </Text>
                  )}
                </Box>
              </Box>
            </Box>

            <Box sx={{ ...boxStyle }}>
              <Text>Promotion</Text>
              
              {/* <label style={{ color: '#fff', fontSize: '0.87rem', marginLeft: '20px'}}><Switch /> Upload Photo instead of video</label>       */}
              
              {/* <FormControlLabel control={<Switch />} label="Upload Photo Instead of Video" /> */}

              <RadioGroup defaultValue={ formData.companyProfileUpload } row onChange={handleRadioButtonChange('companyProfileUpload')}>
                <FormControlLabel label={<Text fontSize='16px'>Add Video</Text>} value={Company_Profile_Upload.Video} control={<Radio size="small" sx={{color: '#fff'}} />} />
                <FormControlLabel label={<Text fontSize='16px'>Upload Image</Text>}  value={Company_Profile_Upload.Image} control={<Radio size="small" sx={{color: '#fff'}} />} />          
              </RadioGroup>
              <Box
                className="membership-flex"
                sx={{ marginTop: "16px", marginBottom: "16px" }}
              >
                <Box
                  className="membership-flex"
                  sx={{
                    flex: { xs: "100%", sm: "1" },
                    marginRight: { xs: "0", sm: "16px" },
                    marginBottom: { xs: "16px", md: "0" },
                    width: '100%'
                  }}
                >
                  <Collapse sx={{width: '100%'}} in={formData.companyProfileUpload === Company_Profile_Upload.Image}>
                      <Box sx={{width: '100%'}} className="membership-flex" >
                      {formData.companyImagePreview || formData.companyImage ? (
                        <img
                          src={formData.companyImagePreview || formData.companyImage}
                          style={{
                            width: "48px",
                            height: "48px",
                            /* UI Properties */
                            background:
                              "transparent url('img/Image 150.png') 0% 0% no-repeat padding-box",
                            borderRadius: "48px",
                            opacity: "1",
                            marginRight: "16px",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            width: "48px",
                            height: "48px",
                            background: "#888888",
                            borderRadius: "48px",
                            opacity: "1",
                            marginRight: "16px",
                          }}
                        ></Box>
                      )}
                      <Box
                        component="button"
                        type="button"
                        onClick={() => fileInputCompanyImage.current.click()}
                        className="membership-button"
                      >
                        <Text>Upload company image</Text>
                      </Box>
                      <input
                        hidden
                        ref={fileInputCompanyImage}
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={onSelectCompanyImageFile}
                      />
                      {errors["companyImage"] && (
                        <Text
                          styles={{ flex: "0 0 100%" }}
                          fontSize="14px"
                          color="#ffcccc"
                        >
                          {errors["companyImage"]}
                        </Text>
                      )}
                    </Box>
                </Collapse>
                <Collapse sx={{width: '100%'}} in={formData.companyProfileUpload === Company_Profile_Upload.Video}>
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <input
                      type="text"
                      name="search"
                      placeholder="Media Url"
                      onChange={handleChange("mediaUrl")}
                      value={formData.mediaUrl}
                      className="membership-input"
                    />
                  </Box>
                  {errors["mediaUrl"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["mediaUrl"]}
                    </Text>
                  )}
                  {!errors["mediaUrl"] && (
                    <Text
                      fontSize="12px"
                      color="#98999D"
                      styles={{
                        marginTop: "2px",
                        justifyContent: "flex-start",
                        flex: "0 0 100%",
                      }}
                    >
                      Hosted on YouTube or Vimeo
                    </Text>
                  )}
                  </Collapse>
                </Box>

                <Box className="membership-flex">
                  {/* <FormControl fullWidth> */}
                  <Select
                    className="membership-input"
                    MenuProps={{ sx: dropDownMenuStyles }}
                    sx={{ paddingLeft: "8px !important" }}
                    value={formData.technologyClassification || ""}
                    onChange={handleChange("technologyClassification")}
                  >
                    <MenuItem defaultValue value="">
                      Technology
                    </MenuItem>
                    <MenuItem value="Advanced Engineering">
                      Advanced Engineering
                    </MenuItem>
                    <MenuItem value="Advanced Materials">
                      Advanced Materials
                    </MenuItem>
                    <MenuItem value="Aerospace &amp; Drones">
                      Aerospace &amp; Drones
                    </MenuItem>
                    <MenuItem value="AI/ML">AI/ML</MenuItem>
                    <MenuItem value="Biotech">Biotech</MenuItem>
                    <MenuItem value="Cybersecurity">Cybersecurity</MenuItem>
                    <MenuItem value="Energy &amp; Climate Tech">
                      Energy &amp; Climate Tech
                    </MenuItem>
                    <MenuItem value="Food &amp; AgTech">
                      Food &amp; AgTech
                    </MenuItem>
                    <MenuItem value="Hardware &amp; Electronics">
                      Hardware &amp; Electronics
                    </MenuItem>
                    <MenuItem value="Immersive Tech">
                      Immersive Tech
                    </MenuItem>
                    <MenuItem value="Industry 4.0 &amp; Robotics">
                      Industry 4.0 &amp; Robotics
                    </MenuItem>
                    <MenuItem value="MedTech &amp; Healthtech">
                      MedTech &amp; Healthtech
                    </MenuItem>
                    <MenuItem value="Mobility Tech">Mobility Tech</MenuItem>
                    <MenuItem value="Quantum Computing">
                      Quantum Computing
                    </MenuItem>
                    <MenuItem value="Web 3 &amp; Blockchain">
                      Web 3 &amp; Blockchain
                    </MenuItem>
                  </Select>
                  {errors["technologyClassification"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["technologyClassification"]}
                    </Text>
                  )}
                </Box>
              </Box>

              <Box sx={{ marginBottom: "16px" }}>
                <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                  <textarea name="search"  
                      value={formData.problemStatement}
                      placeholder="Company Description" rows="3" 
                      autoComplete="off" className="membership-textarea" 
                      onChange={handleChange("problemStatement")}>
                  </textarea>
                  {errors["problemStatement"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {errors["problemStatement"]}
                    </Text>
                  )}

                  {!errors["problemStatement"] && (
                    <Text
                      fontSize="11px"
                      color="#98999D"
                      styles={{
                        marginTop: "2px",
                        justifyContent: "flex-start",
                        flex: "0 0 100%",
                      }}
                    >
                      Tell us what big problem your company is working to solve.
                    </Text>
                  )}
                </Box>
              </Box>
            </Box>

            <Box sx={{ ...topBottomStyle }}>
              <button
                onClick={handleSubmit}
                style={{
                  background: "transparent",
                  display: "flex",
                  justifyContent: "center",
                  border: "none",
                  margin: "auto",
                }}
              >
                <Text
                  style={{ justifyContent: "center", cursor: "pointer" }}
                  fontSize="18px"
                  color="#E9B82A"
                >
                  Save Changes
                </Text>
              </button>
            </Box>
            
          </Box>
        )}
      </Section>
      
      <CompleteCommandModal open={openSuccessModal} setOpen={setOpenSuccessModal} error={modalErrorProp} 
          clearError={() => { 
            if(modalErrorProp !== {}) 
              setModalErrorProp({}) 
            }}  
      />
    </>
  );
};

export default AuthedCompanyProfile;
