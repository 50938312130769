import React, { useContext, useState, useEffect } from "react";
import { getLocalStorage, saveLocalStorage } from "../utils/localStorage";
import { useAuth } from "./AuthContext";

const UserContext = React.createContext();

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({children}) {
  const {currentUser} = useAuth();

  const [userDetails, setUserDetails] = useState({
    companyId: null,
    newCompany: null,
  });

  function resetUser() {
    setUserDetails({
      companyId: null,
      newCompany: null,
    });
  }

  
  function setUser(data) {
    const { companyId, newCompany } = data;

    saveLocalStorage("companyId", companyId);
    saveLocalStorage("newCompany", newCompany);

    setUserDetails({
      companyId,
      newCompany,
    });
  }

  const value = {
    userDetails,
    setUser,
    resetUser
  };

  useEffect(() => {
    const companyId = currentUser !== null ? getLocalStorage("companyId") : null;
    const newCompany = currentUser !== null ? getLocalStorage("newCompany") === "true" : null;

    setUserDetails({
      companyId,
      newCompany,
    });

    return;
  }, []);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
