import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import CompanyCard from "../components/CompanyCard";
import DisplayNoneWrapper from "../components/DisplayNoneWrapper";
import agents from "../agents";
import Section from "../components/Section";
import CardList from "../components/CardList";
import Text from "../components/Text";
import { searchQuery } from "../utils/searchQuery";
import Heading from "../components/Heading";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getCountryOptions } from "../utils/ui/htmlGenerators";



const CompaniesSearch = (data) => {
  const { companiesLength, clearSearch, isLoading, query, querySubmitted } =
    data;

  const [formData, updateFormData] = useState({
    search: "",
    location: "",
    technology: "",
  });

  const handleClearSearch = () => {
    updateFormData({ search: "", location: "", technology: "" });
    clearSearch();
  };

  const handleChange = (prop) => (event) => {
    updateFormData({ ...formData, [prop]: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const response = searchQuery(query, formData);
    querySubmitted(response);
  };


  const technology = [
    "Advanced Engineering",
    "Advanced Materials",
    "Aerospace & Drones",
    "AI/ML",
    "Biotech",
    "Cybersecurity",
    "Energy & Climate Tech",
    "Food & AgTech",
    "Hardware & Electronics",
    "Immersive Tech",
    "Industry 4.0 & Robotics",
    "MedTech & Healthtech",
    "Mobility Tech",
    "Quantum Computing",
    "Web 3 & Blockchain",
  ];

  return (
    <>
      <form name="query" id="search-filter" onSubmit={onSubmit}>
        <div className="search-filter-flex-container">
          <Box
            sx={{ display: "flex", width: { xs: "100%", lg: "50%" } }}
            className="search-filter-flex-container-component"
          >
            <Text>
              <SearchIcon style={{ marginRight: "10px" }} />
            </Text>
            <input
              type="text"
              id="position-search-dark"
              name="search"
              placeholder="Search companies"
              onChange={handleChange("search")}
              value={formData.search}
            />
          </Box>
          <div className="search-filter-flex-container-component-companies">
            <select
              name="location"
              id="location-select-dark"
              onChange={handleChange("location")}
              value={formData.location}
            >
              <option value="">Location</option>
              { getCountryOptions() }
            </select>
          </div>
          <div className="search-filter-flex-container-component-companies">
            <select
              name="technology"
              id="technology-select-dark"
              onChange={handleChange("technology")}
              value={formData.technology}
            >
              <option value="">Technology</option>
              {technology.map((value, key) => (
                <option value={value} key={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <Box width={{ xs: "100%", lg: "15%" }}>
            <button
              type="submit"
              id="submit-search"
              value="Search"
              style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
              disabled={isLoading}
            >
              Search
            </button>
          </Box>
        </div>
      </form>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 0",
          flexWrap: "wrap",
        }}
      >
        <Box onClick={() => handleClearSearch()}>
          <p
            style={{
              width: "fit-content",
              cursor: "pointer",
              color: "#edbb2a",
              fontSize: "16px",
              padding: "3px 4px",
              fontFamily: "Work Sans",
              marginBottom: "10px",
            }}
          >
            Clear search
          </p>
        </Box>
        <DisplayNoneWrapper>
            <Text>Number of results: {isLoading ? 0 : companiesLength}</Text>
        </DisplayNoneWrapper>    
      </Box>
    </>
  );
};

const CompaniesSection = (data) => {
  const { companies, isLoading, loadmoreFunction, companiesLength } = data;
  return (
    <CardList
      type="ROW"
      list={companies}
      listLength={companiesLength}
      loadmoreFunction={loadmoreFunction}
      isLoading={isLoading}
      component={CompanyCard}
    ></CardList>
  );
};

const Companies = (data) => {
  const [companies, setCompanies] = useState(new Array(10).fill(""));
  const [companiesLength, setCompaniesLength] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, updateIsLoading] = useState(true);
  const [query, updateQuery] = useState({ query: "?", page: 1 });
  const navigate = useNavigate();
  const location = useLocation();

  const _location = searchParams.get("location");
  const _search = searchParams.get("search");
  const _technology = searchParams.get("technology");

  const fetchData = async (parameters, getResponse) => {
    updateIsLoading(true);
    const response = await agents.Companies.getCompanies(parameters ?? query.query);
    if(getResponse)
      return response.data;
    
    setCompanies(response.data.data);  
    setCompaniesLength(response.data.companiesLength);
    updateIsLoading(false);
  };

  //  appendMore
  const loadmoreFunction = async () => {
    updateIsLoading(true);

    const page = query.page + 1;
    const path = query.query + "&page=" + page;

    try {
      await fetchData(path, true)
        .then((response) => {
          setCompanies([...companies, ...response.data]);
          setCompaniesLength(response.companiesLength);
        })
        .then((response) => {
          updateQuery((v) => ({ ...v, page: page }));
          updateIsLoading(false);
        });
    } catch (e) {
      console.log("error");
    }
  };

  const querySubmitted = async (response) => {
    updateIsLoading(true);

    updateQuery((v) => ({ ...v, query: response }));

    fetchData(response);
  };

  const clearSearch = async () => {
    updateQuery({ query: "?", page: 1 });

    const path = "?&page=1";

    const response = await fetchData(path, true);

    const _companies = [...response.data];

    setCompanies(_companies);
    setCompaniesLength(response.companiesLength);
    updateIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Deep Tech Jobs - Companies</title>
      </Helmet>
      <Box
        backgroundColor="DARK"
        padding={{ xs: "140px 10px 110px", md: "160px 72px 72px" }}
      >
        <Container>
          <CompaniesSearch
            companiesLength={companiesLength}
            query={query}
            isLoading={isLoading}
            querySubmitted={querySubmitted}
            clearSearch={clearSearch}
          />
        </Container>
      </Box>
      <Section padding="0">
        {companies.length ? (
          <CompaniesSection
            companies={companies}
            companiesLength={companiesLength}
            isLoading={isLoading}
            loadmoreFunction={loadmoreFunction}
          />
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", paddingTop: '20px' }}>
            <Heading
              variant="h2"
              style={{
                display: "block",
                textAlign: "center",
                margin: "auto",
                padding: "40px 8px",
              }}
            >
              No Search Results Found
            </Heading>
          </Box>
        )}
      </Section>
    </>
  );
};

export default Companies;
