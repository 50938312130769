import MenuItem from '@mui/material/MenuItem';
import { COUNTRY_CODES } from '../../constants/countries';

const getCountryOptions = () => {
    const items = [];
    for (const key in COUNTRY_CODES) {
      items.push(
        <option value={COUNTRY_CODES[key]} key={key}>
          {COUNTRY_CODES[key]}
        </option>
      );
    }
    return items;
};

const getCountryMenuItems = () => {
  const items = [];
    for (const key in COUNTRY_CODES) {
      items.push(
        <MenuItem value={COUNTRY_CODES[key]} key={key}>{COUNTRY_CODES[key]}</MenuItem>
      );
    }
    return items;
};

export { getCountryOptions, getCountryMenuItems }