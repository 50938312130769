import React, { useState } from "react";
import { Input, TextField, Box, FormControlLabel, Checkbox, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { isFirefox } from 'react-device-detect';
import { getCountryMenuItems } from '../../utils/ui/htmlGenerators'; 



const JobsSearchBarComponent = (props) => {
    const theme = useTheme();
    const matchesSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMiddle = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    
    const elementGapBetween = { marginLeft: '20px' };
    const cityBoxMaxWidth = { maxWidth: '150px' };   
    const screenSizeStyles = (() => {
        if(!matchesSmall) {
            // tablet
            if(matchesMiddle)
                return {
                    spanWrapper: { marginLeft: '50px' },
                    secondInputBox: { ...elementGapBetween, ...cityBoxMaxWidth },
                    formControl: elementGapBetween,
                    dropDownBox: { width: '100%' },
                    paperProps: {},
                    checkBox: { marginLeft: '100px', marginTop: '-21px', marginBottom: '50px' },
                    clearAllButton: { ...elementGapBetween, marginTop: '-21px', marginBottom: '50px' } 
                };
            // PC        
            return {
                spanWrapper: { marginLeft: '100px' },
                secondInputBox: { ...elementGapBetween, ...cityBoxMaxWidth },
                formControl: elementGapBetween,
                dropDownBox: { width: '100%' },
                paperProps: {},
                checkBox: elementGapBetween,
                clearAllButton: { marginLeft: '185px', marginTop: '-21px', display: 'block', marginBottom: '50px' }
            };
        }
        // smartphone    
        return {
            spanWrapper: { display: 'block' },
            secondInputBox: { ...cityBoxMaxWidth, display: 'block' },
            formControl: { display: 'block' },
            dropDownBox: { width: '50%' },
            paperProps: { maxHeight: '450px'},
            checkBox: { display: 'block' }, 
            clearAllButton: { display: 'block', marginBottom: '20px' }
        };    
            
    })();
    

    const dropDownBoxStyle = {
            
            backgroundColor: '#161a21',
            color: '#ffffff61',
            '& .MuiSelect-select': {
                margin: isFirefox ? '4px' : '2px',
                color: '#fff'
            },
            '&:before': {
                borderBottom: '2px solid #fff'
            },
            '&:hover:not(.Mui-disabled):before': {
                    borderBottom: '2px solid #1976d2'
            },                
            '& .MuiSelect-icon': {
                color: '#ffffff61'
            }
        };

        const checkBoxStyle = {
            color: '#fff',
            '&:hover': {
                color: '#1976d2'
            }
        };

        const emptyFormData = {
            keywords: '',
            city: '',
            location: 'Country',
            onlyRemoteJobs: false
          };
        
          const [formData, setFormData] = useState(emptyFormData);    
    
    const prepareFormDataToReturn  = (changedFormData) => {
        const preparedFormData = {...changedFormData};
        preparedFormData['keywords'] = preparedFormData['keywords'].trim();
        preparedFormData['city'] = preparedFormData['city'].trim();
        if(preparedFormData['onlyRemoteJobs'] === false)
            preparedFormData['onlyRemoteJobs'] = '';
        if(preparedFormData['location'] === emptyFormData.location)
            preparedFormData['location'] = '';
        return preparedFormData;    
    };      

    const handleChange = (prop) => (event) => {
        const changedFormData = { ...formData, [prop]: event.target.value };
        setFormData(changedFormData);
        props.onSearchParamsChange(prepareFormDataToReturn(changedFormData));
    };

    const handleOnlyRemoteCheckboxClick = (prop) => (event) => {
        const changedFormData = { ...formData, [prop]: event.target.checked };
        setFormData(changedFormData);
        props.onSearchParamsChange(prepareFormDataToReturn(changedFormData));
    };
    const handleClearAllClick = () => {
        const changedFormData = {...emptyFormData};
        setFormData(changedFormData);
        props.onSearchParamsChange(prepareFormDataToReturn(changedFormData));
    };

    
    return (
        <>
            <Box className="search-company-jobs-bar"  component="span" sx={screenSizeStyles.spanWrapper}>
            <Box component="span">
                <Input onChange={handleChange('keywords')} value={formData.keywords} placeholder="Keyword Search" />
                <Input onChange={handleChange('city')} value={formData.city}  placeholder="City" sx={ {...screenSizeStyles.secondInputBox} } />
                <FormControl variant="standard" sx={
                        {...{  minWidth: 120, verticalAlign: 'baseline' }, ...screenSizeStyles.formControl}}>
                    <Select
                    sx={ {...dropDownBoxStyle, ...screenSizeStyles.dropDownBox} }
                    value={formData.location}
                    onChange={handleChange('location')}
                    MenuProps={{
                        PaperProps: {
                          sx: {...screenSizeStyles.paperProps, ...{
                            bgcolor: '#161a21',
                            color: '#fff',
                            '& .MuiMenuItem-root:hover': {
                              backgroundColor: '#1976d2'
                            },
                          }},
                        },
                      }}
                    >
                        <MenuItem value='Country' key='Country'>
                            <Box component="span" sx={{color: '#ffffff61'}}>Country</Box>
                        </MenuItem>
                        { getCountryMenuItems() }
                    </Select>
                </FormControl>
                <FormControlLabel sx={{...screenSizeStyles.checkBox, ...{color: '#fff'}}} 
                control={<Checkbox sx={checkBoxStyle} checked={ formData.onlyRemoteJobs } onChange={handleOnlyRemoteCheckboxClick("onlyRemoteJobs")}  />} label="Only Remote" />
            </Box>        
        </Box>    
        <Button sx={screenSizeStyles.clearAllButton} onClick={handleClearAllClick} size="small" variant="outlined">Clear All</Button>
    </>  
    );
}                         

export default JobsSearchBarComponent;