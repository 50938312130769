import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Calendly from "../components/ Calendly";

import Heading from "../components/Heading";
import Section from "../components/Section";
import Text from "../components/Text";

const Contact = (data) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Deep Tech Jobs - Contact</title>
      </Helmet>
      <Section backgroundColor="DARK" padding="150px 72px 72px">
        <Container>
          <Heading fontSize="60px">Contact</Heading>
        </Container>
        <Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box>
              <Calendly />
            </Box>
            <Box>
              <Box
                sx={{
                  margin: "10px 0 60px",
                }}
              >
                <Text
                  textTransform="uppercase"
                  marginBottom="14px"
                  fontSize="14px"
                >
                  General Enquires
                </Text>
                <a href="mailto:mail@deeptechjobs.com">
                  <Text color="#edbb2a">mail@deeptechjobs.com</Text>
                </a>
              </Box>
              <Box
                sx={{
                  margin: "10px 0 60px",
                }}
              >
                <Text
                  textTransform="uppercase"
                  marginBottom="14px"
                  fontSize="14px"
                >
                  Support
                </Text>
                <a href="mailto:support@deeptechjobs.com">
                  <Text color="#edbb2a">support@deeptechjobs.com</Text>
                </a>
              </Box>
              <Box
                sx={{
                  margin: "10px 0 60px",
                }}
              >
                <Text
                  textTransform="uppercase"
                  marginBottom="14px"
                  fontSize="14px"
                >
                  Social
                </Text>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="https://www.linkedin.com/company/deep-tech-jobs/"
                    target="_blank"
                    style={{
                      paddingRight: "20px",
                      borderRight: "1px solid #fff",
                    }}
                  >
                    <Text color="#edbb2a">LinkedIn</Text>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/deeptechjobs/"
                    style={{
                      paddingLeft: "20px",
                    }}
                  >
                    <Text color="#edbb2a">Instagram</Text>
                  </a>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Section>
    </>
  );
};

export default Contact;
