import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Heading from "../../../../components/Heading";
import AuthedSubNavigation from "../AuthedSubNavigation";
import { useNavigate } from "react-router-dom";

import agents from "../../../../agents";
import Text from "../../../../components/Text";
import Section from "../../../../components/Section";
import validator from "validator";
import { useAuth } from "../../../../context/AuthContext";
import { Helmet } from "react-helmet";
import { validateUserInfo, validatePassword } from "./formValidation";
import CompleteCommandModal from "../CompleteCommandModal";
import { useModal } from "../../../../context/ModalContext";
import { notAuthorizedHandler } from "../../../../utils/errors/responseErrorHandlers";
import { RESPONSE_STATUS_CODES } from "../../../../constants/response";

const AuthedAccountInformation = () => {
  const [formData, setFormData] = useState({});
  const [passwordData, setPasswordData] = useState({});
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [modalErrorProp, setModalErrorProp] = useState({});
  const { updateModal } = useModal();

  const [isLoading, setIsLoading] = useState(true);
  const [textIsLoading, setTextIsLoading] = useState(false);

  const [userInfoErrors, setUserInfoErrors] = useState({});
  const [passwordErrors, setPasswordErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState({});

  const { updatePassword, updateEmail } = useAuth();
  const navigate = useNavigate();

  const style = {
    maxWidth: "800px",
    width: { xs: "100%", sm: "calc(100% - 40px)" },
    margin: "0 auto 0",
    borderRadius: "16px",
    overflow: "hidden",
    opacity: 1,
    marginTop: "32px",
    marginBottom: "32px"
  };

  const topBottomStyle = {
    background: "#1F2227 0% 0% no-repeat padding-box",
    padding: { xs: "20px", sm: "20px 32px" },
  };

  const boxStyle = {
    padding: { xs: "20px", sm: "20px 32px" },
    marginTop: "2px",
    background: "#1F2227 0% 0% no-repeat padding-box",
  };

  const fetchData = async () => {
    try {
        const companyUserResponse = await agents.CompanyUser.getCompanyUser();
        setFormData(companyUserResponse.data);
        setIsLoading(false);
    }
    catch (e) {
        if(e.response?.status === RESPONSE_STATUS_CODES.NOT_AUTHORIZED) {
            notAuthorizedHandler();
            // this opens login popup.
            updateModal(true);
          }
          else if(e.response?.status === RESPONSE_STATUS_CODES.ACCESS_FORBIDDEN) {
            navigate('/404');
          }
          else {
            setModalErrorProp({ message: `Failed to Load Company Data.
              Please sign-out and sign-in, then try again. 
              If this issue persists, please contact support.` });
            setOpenSuccessModal(true);
          }
        setIsLoading(false);
    }
  };

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  };

  const handlePasswordChange = (prop) => (event) => {
    setPasswordData({ ...passwordData, [prop]: event.target.value });
  };

  const handleUpdateUserInfo = async () => {
    setUserInfoErrors({});
      
    const { formIsValid, errors } = validateUserInfo(formData);

    if (!formIsValid) {
      setUserInfoErrors(errors);
      return;
    }
    try {
        const result = await agents.CompanyUser.putCompanyUser(formData);
        setOpenSuccessModal(true);
    }
    catch(ex){
        setModalErrorProp({ message: `Failed to Update Company Data.
          Please sign-out and sign-in, then try again. 
          If this issue persists, please contact support.` });
        setOpenSuccessModal(true);
    }
  };

  const handleUpdatePassword = async () => {
    setPasswordErrors({});
      
    const { formIsValid, errors } = validatePassword(passwordData);

    if (!formIsValid) {
      setPasswordErrors(errors);
      return;
    }
    try {
        const result = await agents.CompanyUser.putCompanyUser({ password: passwordData.password });
        setOpenSuccessModal(true);
    }
    catch(ex){
        setModalErrorProp({ message: `Failed to Update Company Data.
          Please sign-out and sign-in, then try again. 
          If this issue persists, please contact support.` });
        setOpenSuccessModal(true);
    }
  }

  useEffect(async () => {
    await fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Deep Tech Jobs - Account Information</title>
      </Helmet>
      <AuthedSubNavigation />
      <Section
        backgroundColor="DARK"
        padding={{ xs: "32px 10px 10px", md: "32px 72px 0" }}
      >
        {!isLoading && (
          <Box sx={{ ...style }}>
            <Box sx={{ ...topBottomStyle }}>
              <Heading fontSize="18px" styles={{ marginBottom: "8px" }}>
                Account Information
              </Heading>
            </Box>
            <Box sx={{ ...boxStyle }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "12px",
                }}
              >
                <Text>My Information</Text>
                <Text onClick={handleUpdateUserInfo}>
                  <Box
                    color="#E9B82A"
                    component="span"
                    sx={{ cursor: "pointer !important" }}
                  >
                    Update Information
                  </Box>
                </Text>
              </Box>
              <Box
                className="membership-flex"
                sx={{
                  marginBottom: "16px",
                  marginTop: "16px",
                }}
              >
                <Box
                  className="membership-flex"
                  sx={{
                    flex: { xs: "100%", sm: "1" },
                    marginRight: { xs: "0", sm: "16px" },
                    marginBottom: { xs: "16px", md: "0" },
                  }}
                >
                  <input
                    type="text"
                    name="search"
                    placeholder="First name"
                    onChange={handleChange("firstName")}
                    value={formData.firstName}
                    className="membership-input"
                  />
                  {userInfoErrors["firstName"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {userInfoErrors["firstName"]}
                    </Text>
                  )}
                </Box>
                <Box className="membership-flex">
                  <input
                    type="text"
                    name="search"
                    placeholder="Last name"
                    onChange={handleChange("lastName")}
                    value={formData.lastName}
                    className="membership-input"
                  />
                  {userInfoErrors["lastName"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {userInfoErrors["lastName"]}
                    </Text>
                  )}
                </Box>
              </Box>
              <Box
                className="membership-flex"
                sx={{
                  marginBottom: "16px",
                  marginTop: "16px",
                }}
              >
                <Box
                  className="membership-flex"
                  sx={{
                    flex: { xs: "100%", sm: "1" },
                    marginRight: { xs: "0", sm: "16px" },
                    marginBottom: { xs: "16px", md: "0" },
                  }}
                >
                  <input
                    type="text"
                    name="search"
                    placeholder="Position"
                    onChange={handleChange("position")}
                    value={formData.position}
                    className="membership-input"
                  />
                  {userInfoErrors["position"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {userInfoErrors["position"]}
                    </Text>
                  )}
                </Box>
                <Box className="membership-flex">
                <input
                  type="email"
                  name="search"
                  placeholder="Email"
                  onChange={handleChange("email")}
                  value={formData.email}
                  className="membership-input"
                />
                {userInfoErrors["email"] && (
                  <Text
                    styles={{ flex: "0 0 100%" }}
                    fontSize="14px"
                    color="#ffcccc"
                  >
                    {userInfoErrors["email"]}
                  </Text>
                )}
                </Box>
              </Box>
            </Box>

            <Box sx={{ ...boxStyle }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "12px",
                }}
              >
                <Text styles={{ marginBottom: "16px" }}>Password</Text>
                <Text onClick={handleUpdatePassword}>
                  <Box
                    color="#E9B82A"
                    component="span"
                    sx={{ cursor: "pointer !important" }}
                  >
                    Update Password
                  </Box>
                </Text>
              </Box>
              <Box
                className="membership-flex"
                sx={{
                  alignItems: "flex-start !important",
                  marginBottom: "16px",
                  marginTop: "16px",
                }}
              >
                <Box
                  className="membership-flex"
                  sx={{
                    flex: { xs: "100%", sm: "1" },
                    marginRight: { xs: "0", sm: "16px" },
                    marginBottom: { xs: "16px", md: "0" },
                  }}
                >
                  <input
                    type="password"
                    name="search"
                    placeholder="Password"
                    onChange={handlePasswordChange("password")}
                    value={formData.password}
                    className="membership-input"
                  />
                  {passwordErrors["password"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {passwordErrors["password"]}
                    </Text>
                  )}
                </Box>
                <Box className="membership-flex">
                  <input
                    type="password"
                    name="password"
                    placeholder="Repeat password"
                    onChange={handlePasswordChange("passwordRepeat")}
                    value={formData.passwordRepeat}
                    className="membership-input"
                  />
                  {passwordErrors["passwordRepeat"] && (
                    <Text
                      styles={{ flex: "0 0 100%" }}
                      fontSize="14px"
                      color="#ffcccc"
                    >
                      {passwordErrors["passwordRepeat"]}
                    </Text>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Section>
      <CompleteCommandModal open={openSuccessModal} setOpen={setOpenSuccessModal} error={modalErrorProp} 
          clearError={() => { 
            if(modalErrorProp !== {}) 
              setModalErrorProp({}) 
            }}  
      />
    </>
  );
};

export default AuthedAccountInformation;
