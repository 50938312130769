import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import Heading from "../components/Heading";
import Section from "../components/Section";
import Text from "../components/Text";

const PageNotFound = () => {
  return (
    <>
              <Helmet>
        <title>Deep Tech Jobs - Page Not Found</title>
      </Helmet>
      <Box
        backgroundColor="DARK"
        padding={{ xs: "140px 10px 20px", md: "160px 72px 72px" }}
      >
        <Heading
          styles={{
            lineHeight: "1.1em",
            margin: "auto",
            textAlign: "center",
            justifyContent: "center",
            display: 'flex'
          }}
          fontSize="calc((6 - 1) * 1.2vh + 1rem)"
        >
          Page Not Found
        </Heading>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <NavLink
            to="/"
            style={{
              background: "transparent",
              border: "none",
              margin: "auto",
            }}
          >
            <Text
              style={{ justifyContent: "center", cursor: "pointer" }}
              fontSize="18px"
              color="#E9B82A"
            >
              Navigate To Home?
            </Text>
          </NavLink>
        </Box>
      </Box>
    </>
  );
};

export default PageNotFound;
