import validator from "validator";
import { validateImage } from "image-validator";
import { convertMedia } from "../../../../utils/utils";
import { createObjectToValidate } from "../../../../utils/validations/helpers";
import { Company_Profile_Upload } from "../../../../constants/company";


const handleValidation = async (formData) => {
    let errors = {};
    let convertedMediaUrl = null;
    let formIsValid = true;
    const hasLogoImageFile =
        formData.logoImageFile && (await validateImage(formData.logoImageFile));


    if ( createObjectToValidate(formData.companyName).isEmpty ) {
      formIsValid = false;
      errors["companyName"] = "Please fill in this field";
    }

    
    if ( createObjectToValidate(formData.logo).isEmpty && !hasLogoImageFile) {
      formIsValid = false;
      errors["logo"] = "Please add image type jpg, png, jpeg";
    }

    if (createObjectToValidate(formData.companyCity).isEmpty) {
      formIsValid = false;
      errors["companyCity"] = "Please fill in this field";
    }

    
    if (createObjectToValidate(formData.companyLocation).isEmpty) {
      formIsValid = false;
      errors["companyLocation"] = "Please fill in this field";
    }

    if (createObjectToValidate(formData.technologyClassification).isEmpty) {
      formIsValid = false;
      errors["technologyClassification"] = "Please fill in this field";
    }
    
    if (createObjectToValidate(formData.problemStatement).isEmpty) {
      formIsValid = false;
      errors["problemStatement"] = "Please fill in this field";
    }

    if(formData.companyProfileUpload === Company_Profile_Upload.Video) {
      const mediaUrlValidationObj = createObjectToValidate(formData.mediaUrl);
      if (mediaUrlValidationObj.isEmpty) {
        formIsValid = false;
        errors["mediaUrl"] = "Please fill in this field";
      }
      else {
        convertedMediaUrl = await convertMedia(mediaUrlValidationObj.value);
        if (!convertedMediaUrl) {
          formIsValid = false;
          errors["mediaUrl"] =
            "Please enter an embedded link or a share link, for YouTube or Vimeo";  
        }
        if (convertedMediaUrl && !validator.isURL(convertedMediaUrl)) {
          formIsValid = false;
          errors["mediaUrl"] = "Please add a valid URL";
        }
      } 
    }
    else if(formData.companyProfileUpload === Company_Profile_Upload.Image) {
      const hasCompanyImageFile =
        formData.companyImageFile && (await validateImage(formData.companyImageFile));
        if(createObjectToValidate(formData.companyImageFile).isEmpty && !hasCompanyImageFile) {
          formIsValid = false;
          errors["companyImage"] = "Please add image type jpg, png, jpeg";
        }
    }

    const websiteUrlValidationObj = createObjectToValidate(formData.websiteUrl);
    if(!websiteUrlValidationObj.isEmpty) {
      const websiteUrl = websiteUrlValidationObj.value;
      if(!validator.isURL(websiteUrl)) {
        formIsValid = false;
        errors["websiteUrl"] = "Please add a valid URL";
      }
    }

    const linkedInUrlValidationObj = createObjectToValidate(formData.linkedInUrl);
    if(!linkedInUrlValidationObj.isEmpty) {
      const linkedInUrl = linkedInUrlValidationObj.value;
      if(!validator.isURL(linkedInUrl)) {
        formIsValid = false;
        errors["linkedInUrl"] = "Please add a valid URL";
      }
      else {
        const hasLinkedIn = linkedInUrl.toLowerCase().includes('linkedin');
        if(!hasLinkedIn) {
          formIsValid = false;
          errors["linkedInUrl"] = "Please add the linkedIn url";
        }
      }
    }
    const twitterUrlValidationObj = createObjectToValidate(formData.twitterUrl);
    if(!twitterUrlValidationObj.isEmpty) {
      const twitterUrl = twitterUrlValidationObj.value;
      if(!validator.isURL(twitterUrl)) {
        formIsValid = false;
        errors["twitterUrl"] = "Please add a valid URL";
      }
      else {
        const hasTwitter = twitterUrl.toLowerCase().includes('twitter');
        if(!hasTwitter) {
          formIsValid = false;
          errors["twitterUrl"] = "Please add the twitter url";
        }
      }
    }
    const atsNameValidationObj = createObjectToValidate(formData.atsName);
    if(atsNameValidationObj.isEmpty){
      formIsValid = false;
      errors["atsName"] = "Please choose the ats name";
    }
    if(atsNameValidationObj.value !== 'teamtailor') {
      if(createObjectToValidate(formData.atsUserName).isEmpty){
        formIsValid = false;
        errors["atsUserName"] = "Please specify the username for the ats";
      }
    } 
    else {
      if(createObjectToValidate(formData.atsApiKey).isEmpty) {
        formIsValid = false;
        errors["atsApiKey"] = "Please specify the token";
      }
    } 
    return { formIsValid, convertedMediaUrl, errors };
  };

  export default handleValidation;