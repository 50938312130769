import React from "react";
import { Box, Container } from "@mui/material";

import Heading from "../components/Heading";
import Section from "../components/Section";
import Text from "../components/Text";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Deep Tech Jobs - Privacy Policy</title>
      </Helmet>
      <Section padding="180px 72px 72px">
        <Container
          sx={{
            color: "#fff",
          }}
        >
          <Text fontSize="12px">Effective date: 09/05/2022 </Text>
          <Heading
            style={{ lineHeight: "1.1em" }}
            fontSize="calc((6 - 1) * 1.2vh + 1rem)"
          >
            Privacy Policy
          </Heading>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>1. Introduction</Text>
            <p style={{ marginBottom: "10px" }}>
              Welcome to <b>Deep Tech Jobs Limited</b>.
            </p>
            <p style={{ marginBottom: "10px" }}>
              Deep Tech Jobs Limited (“us”, “we”, or “our”) operates{" "}
              <a
                style={{
                  color: "#edbb2a",
                }}
                href="https://www.deeptechjobs.com"
              >
                https://www.deeptechjobs.com
              </a>{" "}
              (hereinafter referred to as <b>“Service”</b>).
            </p>

            <p style={{ marginBottom: "10px" }}>
              Our Privacy Policy governs your visit to{" "}
              <a
                style={{
                  color: "#edbb2a",
                  textDecoration: "underline !important",
                }}
                href="https://www.deeptechjobs.com"
              >
                https://www.deeptechjobs.com
              </a>
              , and explains how we collect, safeguard and disclose information
              that results from your use of our Service.
            </p>
            <p>
              We use your data to provide and improve Service. By using Service,
              you agree to the collection and use of information in accordance
              with this policy. Unless otherwise defined in this Privacy Policy,
              the terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions.
            </p>
            <p>
              Our Terms and Conditions (“<b>Terms</b>”) govern all use of our
              Service and together with the Privacy Policy constitutes your
              agreement with us (“<b>agreement</b>”).
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>2. Definitions</Text>
            <p>
              <b>SERVICE</b> means the{" "}
              <a
                style={{
                  color: "#edbb2a",
                  textDecoration: "underline !important",
                }}
                href="https://www.deeptechjobs.com"
              >
                https://www.deeptechjobs.com
              </a>{" "}
              website operated by Deep Tech Jobs Limited.
            </p>
            <p>
              <b>PERSONAL DATA</b>  means data about a living individual who can
              be identified from those data (or from those and other information
              either in our possession or likely to come into our possession).
            </p>
            <p>
              <b>USAGE DATA</b> is data collected automatically either generated
              by the use of Service or from Service infrastructure itself (for
              example, the duration of a page visit).
            </p>
            <p>
              <b>COOKIES</b> are small files stored on your device (computer or
              mobile device).
            </p>
            <p>
              <b>DATA CONTROLLER</b> means a natural or legal person who (either
              alone or jointly or in common with other persons) determines the
              purposes for which and the manner in which any personal data are,
              or are to be, processed. For the purpose of this Privacy Policy,
              we are a Data Controller of your data.
            </p>
            <p>
              <b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any natural or
              legal person who processes the data on behalf of the Data
              Controller. We may use the services of various Service Providers
              in order to process your data more effectively.
            </p>
            <p>
              <b>DATA SUBJECT</b> is any living individual who is the subject of
              Personal Data.
            </p>
            <p>
              <b>THE USER</b> is the individual using our Service. The User
              corresponds to the Data Subject, who is the subject of Personal
              Data.
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>
              3. Information Collection and Use{" "}
            </Text>
            <p>
              We collect several different types of information for various
              purposes to provide and improve our Service to you.{" "}
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>
              4. Types of Data Collected{" "}
            </Text>
            <p>
              <b>Personal Data </b>
            </p>
            <p>
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you (“<b>Personal Data</b>”). Personally identifiable
              information may include, but is not limited to:{" "}
            </p>
            <p>(a) Email address</p>
            <p>(b) First name and last name</p>
            <p>(c) Address, such as Street name, City and Country details</p>
            <p>(d) Cookies and Usage Data</p>
            <p>
              We may use your Personal Data to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. You may opt out of receiving any, or all,
              of these communications from us by following the unsubscribe link.{" "}
            </p>
            <p>
              <b>Usage Data </b>
            </p>
            <p>
              We may also collect information that your browser sends whenever
              you visit our Service or when you access Service by or through a
              mobile device (“<b>Usage Data</b>”).
            </p>
            <p>
              This Usage Data may include information such as your computer's
              Internet Protocol address (e.g. IP address), browser type, browser
              version, the pages of our Service that you visit, the time and
              date of your visit, the time spent on those pages, unique device
              identifiers and other diagnostic data.{" "}
            </p>
            <p>
              When you access Service with a mobile device, this Usage Data may
              include information such as the type of mobile device you use,
              your mobile device unique ID, the IP address of your mobile
              device, your mobile operating system, the type of mobile Internet
              browser you use, unique device identifiers and other diagnostic
              data.{" "}
            </p>
            <p>
              <b>Location Data </b>
            </p>
            <p>
              We may use and store information about your location if you give
              us permission to do so (“<b>Location Data</b>”). We use this data
              to provide features of our Service, to improve and customize our
              Service.{" "}
            </p>
            <p>
              You can enable or disable location services when you use our
              Service at any time by way of your device settings.{" "}
            </p>
            <p>
              <b>Tracking Cookies Data </b>
            </p>
            <p>
              We use cookies and similar tracking technologies to track the
              activity on our Service and we hold certain information.{" "}
            </p>
            <p>
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyse our Service.{" "}
            </p>
            <p>
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.{" "}
            </p>
            <p>Examples of Cookies we use: </p>
            <p>
              (a) <b>Session Cookies:</b> We use Session Cookies to operate our
              Service.{" "}
            </p>
            <p>
              (b) <b>Preference Cookies:</b> We use Preference Cookies to
              remember your preferences and various settings.{" "}
            </p>
            <p>
              (c) <b>Security Cookies:</b> We use Security Cookies for security
              purposes
            </p>
            <p>
              (d) <b>Advertising Cookies:</b> Advertising Cookies are used to
              serve you with advertisements that may be relevant to you and your
              interests.
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>5. Use of Data</Text>
            <p>
              Deep Tech Jobs Limited uses the collected data for various
              purposes:{" "}
            </p>
            <p>(a) to provide and maintain our Service; </p>
            <p>(b) to notify you about changes to our Service; </p>
            <p>
              (c) to allow you to participate in interactive features of our
              Service when you choose to do so;{" "}
            </p>
            <p>(d) to provide customer support; </p>
            <p>
              (e) to gather analysis or valuable information so that we can
              improve our Service;{" "}
            </p>
            <p>(f) to monitor the usage of our Service; </p>
            <p>(g) to detect, prevent and address technical issues; </p>
            <p>(h) to fulfil any other purpose for which you provide it; </p>
            <p>
              (i) to carry out our obligations and enforce our rights arising
              from any contracts entered into between you and us, including for
              billing and collection;{" "}
            </p>
            <p>
              (j) to provide you with notices about your account and/or
              subscription, including expiration and renewal notices,
              email-instructions, etc.;{" "}
            </p>
            <p>
              (k) to provide you with news, special offers and general
              information about other goods, services and events which we offer
              that are similar to those that you have already purchased or
              enquired about unless you have opted not to receive such
              information;{" "}
            </p>
            <p>
              (l) in any other way we may describe when you provide the
              information;{" "}
            </p>
            <p>(m) for any other purpose with your consent. </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>6. Retention of Data </Text>
            <p>
              We will retain your Personal Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain
              and use your Personal Data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain
              your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.{" "}
            </p>

            <p>
              We will also retain Usage Data for internal analysis purposes.
              Usage Data is generally retained for a shorter period, except when
              this data is used to strengthen the security or to improve the
              functionality of our Service, or we are legally obligated to
              retain this data for longer time periods.{" "}
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>7. Transfer of Data </Text>
            <p>
              Your information, including Personal Data, may be transferred to –
              and maintained on – computers located outside of your city,
              country or other governmental jurisdiction where the data
              protection laws may differ from those of your jurisdiction.{" "}
            </p>

            <p>
              Please note that we transfer data, including Personal Data, to the
              United Kingdom and process it there.{" "}
            </p>

            <p>
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.{" "}
            </p>

            <p>
              Deep Tech Jobs Limited will take all the steps reasonably
              necessary to ensure that your data is treated securely and in
              accordance with this Privacy Policy and no transfer of your
              Personal Data will take place to an organisation or a country
              unless there are adequate controls in place including the security
              of your data and other personal information.{" "}
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>8. Disclosure of Data </Text>
            <p>
              We may disclose personal information that we collect, or you
              provide:{" "}
            </p>

            <p>
              (a) <b>Disclosure for Law Enforcement.</b>{" "}
            </p>

            <p>
              Under certain circumstances, we may be required to disclose your
              Personal Data if required to do so by law or in response to valid
              requests by public authorities.{" "}
            </p>

            <p>
              (b) <b>Business Transaction.</b>{" "}
            </p>

            <p>
              If we or our subsidiaries are involved in a merger, acquisition or
              asset sale, your Personal Data may be transferred.{" "}
            </p>

            <p>
              (c) <b>Other cases. We may disclose your information also: </b>
            </p>

            <p>(i) to our subsidiaries and affiliates; </p>

            <p>(ii) to fulfil the purpose for which you provide it; </p>

            <p>
              (iii) for the purpose of including your company’s logo on our
              website;{" "}
            </p>

            <p>
              (iv) for any other purpose disclosed by us when you provide the
              information;{" "}
            </p>

            <p>
              (v) if we believe disclosure is necessary or appropriate to
              protect the rights, property, or safety of the Company, our
              customers, or others.{" "}
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>9. Security of Data </Text>
            <p>
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.{" "}
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>
              10. Your Data Protection Rights Under General Data Protection
              Regulation (GDPR){" "}
            </Text>
            <p>
              If you are a resident of the European Union (EU) and European
              Economic Area (EEA), you have certain data protection rights,
              covered by GDPR.{" "}
            </p>
            <p>
              We will process your Personal Information in accordance with all
              applicable data protection legislation from time to time in force
              in the UK including:{" "}
            </p>
            <ul>
              <li>- the General Data Protection Regulation ((EU) 2016/679);</li>
              <li>- the Data Protection Act 2018;</li>
              <li>
                - the Privacy and Electronic Communications Directive 2002/58/EC
                (as updated by Directive 2009/136/EC) and;{" "}
              </li>
              <li>
                - the Privacy and Electronic Communications Regulations 2003 (SI
                2003/2426) as amended (the “Data Protection Legislation”).{" "}
              </li>
            </ul>
            <p>
              We are registered with the ICO in the UK as required by the Data
              Protection Act 2018, which implemented into English law the
              General Data Protection Regulation (EU) 2016/679 (‘GDPR’).{" "}
            </p>
            <p>
              In accordance with GDPR, we have appointed an EU representative
              for data protection purposes who is the point of contact for any
              data processing complaints by data subjects located within the EU.{" "}
            </p>
            <p>The details of our EU representative are as follows: </p>
            Buckworths (Ireland) Ltd, c/o Workhub, 77 Lower Camden Street,
            Dublin D02 XE80 Ireland (
            <a
              style={{
                color: "#edbb2a",
                textDecoration: "underline !important",
              }}
              href="mailto: gdpr@buckworths.com"
            >
              gdpr@buckworths.com
            </a>
            )
            <p>
              We aim to take reasonable steps to allow you to correct, amend,
              delete, or limit the use of your Personal Data.{" "}
            </p>
            If you wish to be informed what Personal Data we hold about you and
            if you want it to be removed from our systems, please email us at{' '}
            <a
              style={{
                color: "#edbb2a",
                textDecoration: "underline !important",
              }}
              href="mailto: support@deeptechjobs.com"
            >
              support@deeptechjobs.com
            </a>
            <p>
              In certain circumstances, you have the following data protection
              rights:{" "}
            </p>
            <p>
              (a) the right to access, update or to delete the information we
              have on you;{" "}
            </p>
            <p>
              (b) the right of rectification. You have the right to have your
              information rectified if that information is inaccurate or
              incomplete;{" "}
            </p>
            <p>
              (c) the right to object. You have the right to object to our
              processing of your Personal Data;{" "}
            </p>
            <p>
              (d) the right of restriction. You have the right to request that
              we restrict the processing of your personal information;{" "}
            </p>
            <p>
              (e) the right to data portability. You have the right to be
              provided with a copy of your Personal Data in a structured,
              machine-readable and commonly used format;{" "}
            </p>
            <p>
              (f) the right to withdraw consent. You also have the right to
              withdraw your consent at any time where we rely on your consent to
              process your personal information;{" "}
            </p>
            <p>
              Please note that we may ask you to verify your identity before
              responding to such requests. Please note, we may not able to
              provide Service without some necessary data.{" "}
            </p>
            <p>
              You have the right to complain to a Data Protection Authority
              about our collection and use of your Personal Data. For more
              information, please contact your local data protection authority
              in the European Economic Area (EEA).{" "}
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>
              11. Your Data Protection Rights under the California Privacy
              Protection Act (CalOPPA)
            </Text>

            <p>
              If you are based in the United States, you should know that
              CalOPPA is the first state law in the nation to require commercial
              websites and online services to post a privacy policy. The law’s
              reach stretches well beyond California to require a person or
              company in the United States (and conceivable the world) that
              operates websites collecting personally identifiable information
              from California consumers to post a conspicuous privacy policy on
              its website stating exactly the information being collected and
              those individuals with whom it is being shared, and to comply with
              this policy. – See more at: 
              <a
                style={{
                  color: "#edbb2a",
                  textDecoration: "underline !important",
                }}
                href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/"
              >
                https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
              </a>
            </p>

            <p>According to CalOPPA we agree to the following: </p>

            <p>(a) users can visit our site anonymously; </p>

            <p>
              (b) our Privacy Policy link includes the word “Privacy”, and can
              easily be found on the page specified above on the home page of
              our website;{" "}
            </p>

            <p>
              (c) users will be notified of any privacy policy changes on our
              Privacy Policy Page;{" "}
            </p>

            <p>
              (d) users are able to change their personal information by
              emailing us at{" "}
              <a
                style={{
                  color: "#edbb2a",
                  textDecoration: "underline !important",
                }}
                href="mailto: support@deeptechjobs.com"
              >
                support@deeptechjobs.com
              </a>
              .
            </p>

            <p>Our Policy on “Do Not Track” Signals: </p>

            <p>
              We honor Do Not Track signals and do not track, plant cookies, or
              use advertising when a Do Not Track browser mechanism is in place.
              Do Not Track is a preference you can set in your web browser to
              inform websites that you do not want to be tracked.{" "}
            </p>

            <p>
              You can enable or disable Do Not Track by visiting the Preferences
              or Settings page of your web browser.{" "}
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>
              12. Your Data Protection Rights under the California Consumer
              Privacy Act (CCPA)
            </Text>

            <p>
              If you are a California resident, you are entitled to learn what
              data we collect about you, ask to delete your data and not to sell
              (share) it. To exercise your data protection rights, you can make
              certain requests and ask us:{" "}
            </p>

            <p>
              (a) <b>What personal information we have about you.</b> If you
              make this request, we will return to you:{" "}
            </p>

            <p>
              (i) The categories of personal information we have collected about
              you.{" "}
            </p>

            <p>
              (ii) The categories of sources from which we collect your personal
              information.{" "}
            </p>

            <p>
              (iii) The business or commercial purpose for collecting or selling
              your personal information.{" "}
            </p>

            <p>
              (iv) The categories of third parties with whom we share personal
              information.{" "}
            </p>

            <p>
              (v) The specific pieces of personal information we have collected
              about you.{" "}
            </p>

            <p>
              (vi) A list of categories of personal information that we have
              sold, along with the category of any other company we sold it to.
              If we have not sold your personal information, we will inform you
              of that fact.{" "}
            </p>

            <p>
              (vii)A list of categories of personal information that we have
              disclosed for a business purpose, along with the category of any
              other company we shared it with.{" "}
            </p>

            <p>
              Please note, you are entitled to ask us to provide you with this
              information up to two times in a rolling twelve-month period. When
              you make this request, the information provided may be limited to
              the personal information we collected about you in the previous 12
              months.{" "}
            </p>

            <p>
              (b) <b>To delete your personal information.</b> If you make this
              request, we will delete the personal information we hold about you
              as of the date of your request from our records and direct any
              service providers to do the same. In some cases, deletion may be
              accomplished through de-identification of the information. If you
              choose to delete your personal information, you may not be able to
              use certain functions that require your personal information to
              operate.{" "}
            </p>

            <p>
              (c) <b>To stop selling your personal information.</b> We don't
              sell or rent your personal information to any third parties for
              any purpose. You are the only owner of your Personal Data and can
              request disclosure or deletion at any time.{" "}
            </p>

            <p>
              Please note, if you ask us to delete or stop selling your data, it
              may impact your experience with us, and you may not be able to
              participate in certain programs or membership services which
              require the usage of your personal information to function. But in
              no circumstances, we will discriminate against you for exercising
              your rights.{" "}
            </p>

            <p>
              To exercise your California data protection rights described
              above, please send your request(s) by one of the following means:{" "}
            </p>

            <p>
              By email:{" "}
              <a
                style={{
                  color: "#edbb2a",
                  textDecoration: "underline !important",
                }}
                href="mailto: support@deeptechjobs.com"
              >
                support@deeptechjobs.com
              </a>{" "}
            </p>

            <p>
              Your data protection rights, described above, are covered by the
              CCPA, short for the California Consumer Privacy Act. To find out
              more, visit the 
              <a
                style={{
                  color: "#edbb2a",
                  textDecoration: "underline !important",
                }}
                href="http://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180AB375"
              >
                official California Legislative Information website
              </a>
              . The CCPA took effect on 01/01/2020.{" "}
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>13. Service Providers</Text>

            <p>
              We may employ third party companies and individuals to facilitate
              our Service (“<b>Service Providers</b>”), provide Service on our
              behalf, perform Service-related services or assist us in analysing
              how our Service is used.{" "}
            </p>

            <p>
              These third parties have access to your Personal Data only to
              perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose.{" "}
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>14. Analytics</Text>
            <p>
              We may use third-party Service Providers to monitor and analyze
              the use of our Service.{" "}
            </p>

            <p>Google Analytics </p>

            <p>
              Google Analytics is a web analytics service offered by Google that
              tracks and reports website traffic. Google uses the data collected
              to track and monitor the use of our Service. This data is shared
              with other Google services. Google may use the collected data to
              contextualise and personalise the ads of its own advertising
              network.{" "}
            </p>

            <p>
              For more information on the privacy practices of Google, please
              visit the Google Privacy Terms web page: 
              <a
                style={{
                  color: "#edbb2a",
                  textDecoration: "underline !important",
                }}
                href="https://policies.google.com/privacy?hl=en"
              >
                {" "}
                https://policies.google.com/privacy?hl=en
              </a>{" "}
            </p>

            <p>
              We also encourage you to review the Google's policy for
              safeguarding your data: 
              <a
                style={{
                  color: "#edbb2a",
                  textDecoration: "underline !important",
                }}
                href="https://support.google.com/analytics/answer/6004245"
              >
                {" "}
                https://support.google.com/analytics/answer/6004245
              </a>
              .{" "}
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>15. Payments</Text>
            <p>
              We may provide paid products and/or services within Service. In
              that case, we use third-party services for payment processing
              (e.g. payment processors).{" "}
            </p>

            <p>
              We will not store or collect your payment card details. That
              information is provided directly to our third-party payment
              processors whose use of your personal information is governed by
              their Privacy Policy. These payment processors adhere to the
              standards set by PCI-DSS as managed by the PCI Security Standards
              Council, which is a joint effort of brands like Visa, Mastercard,
              American Express and Discover. PCI-DSS requirements help ensure
              the secure handling of payment information.{" "}
            </p>

            <p>The payment processors we work with are: </p>

            <p>Stripe: </p>

            <p>
              Their Privacy Policy can be viewed at:{" "}
              <a
                style={{
                  color: "#edbb2a",
                  textDecoration: "underline !important",
                }}
                href="https://stripe.com/us/privacy"
              >
                https://stripe.com/us/privacy
              </a>
               {" "}
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>
              19. Links to Other Sites{" "}
            </Text>
            <p>
              Our Service may contain links to other sites that are not operated
              by us. If you click a third party link, you will be directed to
              that third party's site. We strongly advise you to review the
              Privacy Policy of every site you visit.{" "}
            </p>

            <p>
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.{" "}
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>
              20. Children's Privacy{" "}
            </Text>
            <p>
              Our Services are not intended for use by children under the age of
              13 (“Children”).{" "}
            </p>

            <p>
              We do not knowingly collect personally identifiable information
              from Children under 13. If you become aware that a Child has
              provided us with Personal Data, please contact us. If we become
              aware that we have collected Personal Data from Children without
              verification of parental consent, we take steps to remove that
              information from our servers.{" "}
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>
              21. Changes to This Privacy Policy
            </Text>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.{" "}
            </p>

            <p>
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update
              “effective date” at the top of this Privacy Policy.{" "}
            </p>

            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.{" "}
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>22. Contact Us</Text>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us:{" "}
            </p>

            <p>
              By email:{" "}
              <a
                style={{
                  color: "#edbb2a",
                  textDecoration: "underline !important",
                }}
                href="mailto: support@deeptechjobs.com"
              >
                support@deeptechjobs.com
              </a>{" "}
            </p>
          </Box>
        </Container>
      </Section>
    </>
  );
};

export default PrivacyPolicy;
