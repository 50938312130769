const path = window.location.href.split("?")[0];
const queryString = window.location.search;
const query = new URLSearchParams(window.location.search);
const entries = query.entries();

let currentPage;
let searchedQuery = "";

const cfg = {
  query: "",
  currentPage: 1,
};

const loadMore = async () => {
  await updateCurrentPage();
};

const updateCurrentPage = () => {
  cfg.currentPage++;
  let uri = updateQueryStringParameter(cfg.query, "page", cfg.currentPage);
  cfg.query = uri;
};

const updateQueryStringParameter = (uri, key, value) => {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
};

const removeURLParameter = (uri, parameter) => {
  //prefer to use l.search if you have a location/link object

  var prefix = encodeURIComponent(parameter) + "=";
  var pars = uri.split(/[&;]/g);

  //reverse iteration as may be destructive
  for (var i = pars.length; i-- > 0; ) {
    //idiom for string.startsWith
    if (pars[i].lastIndexOf(prefix, 0) !== -1) {
      pars.splice(i, 1);
    }
  }

  return pars.join("&");
};

export const searchQuery = (query, _formData) => {
  cfg.currentPage = 1;
  let uri = "";

  const data = Object.entries(_formData);
  
  for (let el of data) {
    if (el[1].length) {
      uri = updateQueryStringParameter(
        uri,
        encodeURIComponent(el[0]),
        encodeURIComponent(el[1])
      );
    } else {
      uri = removeURLParameter(uri, el[0]);
    }
  }

  return uri;
};
