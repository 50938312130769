import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import agents from "../../agents";
import { useUser } from "../../context/UserContext";
import MembershipSection from "./MembershipSection";
import { Company_Profile_Upload } from "../../constants/company";

const MembershipConversion = (data) => {
  const { setUser } = useUser();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState({});
  const [formData, setFormData] = useState({});

  const navigate = useNavigate();

  const updateCompany = (data) => {
    setCompany({
      ...data,
      logoImageFile: "",
    });
  };

  const updateForm = (data) => {
    const formData = {
      id: data?.id || "",
      companyName: data?.companyName || "",
      logo: data?.logo || "",
      logoImageFile: "",
      technologyClassification: data?.technologyClassification || "",
      companyCity: data?.companyCity || "",
      companyLocation: data?.companyLocation || "",
      mediaUrl: data?.mediaUrl || "",
      problemStatement: data?.problemStatement || "",
      websiteUrl: data?.website || "",
      linkedInUrl: data?.linkedIn || "",
      twitterUrl: data?.twitter || "",
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      position: data?.position || "",
      userEmail: data?.userEmail || "",
      password: data?.password || "",
      isNewCompany: data?.isNewCompany,
      atsName: data?.atsName || "",
      atsUserName: data?.atsUserName || "", 
      atsApiKey: data?.atsApiKey || "" 
    };
    
    formData.companyProfileUpload = Company_Profile_Upload.Video;
    if(formData.mediaUrl)
      formData.companyProfileUpload = Company_Profile_Upload.Video;
    else if(formData.companyProfileImage)
      formData.companyProfileUpload = Company_Profile_Upload.Image;

    setFormData(formData);
  };

  const fetchData = async () => {
    setIsLoading(true);
    const response = await agents.Companies.getCompaniesMembership();
    setCompanies(response.data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();

    return () => {
      setFormData({
        id: "",
        companyName: "",
        logo: "",
        logoImageFile: "",
        //technologyClassification: "",
        companyLocation: "",
        companyCity: "",
        mediaUrl: "",
        problemStatement: "",
        atsName: "",
        atsUserName: "",
        atsApiKey: ""

      });
    };
  
  }, []);

  return (
    <MembershipSection
      open={open}
      isLoading={isLoading}
      companies={companies}
      company={company}
      formData={formData}
      setOpen={setOpen}
      setIsLoading={setIsLoading}
      setCompanies={setCompanies}
      setFormData={setFormData}
      updateForm={updateForm}
    />
  );
};

export default MembershipConversion;
