import React, { useEffect, useRef, useState } from "react";
import { ThemeProvider } from "@mui/material";
import { useLocation } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/Header";
import defaultTheme from "../theme";
import LoginModal from "../components/LoginModal";
import { ModalProvider } from "../context/ModalContext";

const PageTemplate = (data) => {
  const scrollRef = useRef(null);

  const noShows = ["/insights"];

  const location = useLocation();
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const { children } = data;
  const user = data.user || undefined;

  useEffect(() => {
    window.scrollTo(0, 0);
    scrollRef.current.scrollIntoView();
  }, [location]);

  const onLoginClicked = () => setOpenLoginModal(true);

  return (
    <>
      <ModalProvider>
        <Header onLoginClicked={onLoginClicked} />

        <main
          ref={scrollRef}
          id="main"
          style={{
            minHeight: "100vh",
            background: "#161a21",
            overflow: noShows.includes(location.pathname) && "hidden",
          }}
        >
          {children}
        </main>
        {noShows.includes(location.pathname) ? null : <Footer />}
        <LoginModal open={openLoginModal} setOpen={setOpenLoginModal} />
      </ModalProvider>
    </>
  );
};

export default PageTemplate;
