import axios from 'axios';
import { deleteLocalStorage } from "./utils/localStorage";
import { auth } from "./firebase";

const firebaseKeys = {
  accessTokenHeader: 'fb-access-token'
};

const contentTypeHeader = { 
  name: 'Content-Type',
  values: {
    multipartFormData: 'multipart/form-data',
    json: 'application/json'
  } 
};


const createReqHeaders = (obj) => {
  const headersToSend = {
        headers: {}
  };
  for (const [key, value] of Object.entries(obj)) {
       headersToSend.headers[key] = value;
 }
 return headersToSend;
};



const env = {
  development: "http://localhost:8080",
  staging: "https://stage.api.deeptechjobs.com",
  production: "https://api2.deeptechjobs.com",
};

const API_ROOT = env[process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV];

let token = null;
let backendToken = null;

const getAuthHeadersObj = () => { 
    const _token = getToken();
    const authHeaders = {};
    if (_token.t || token) {
      authHeaders['authorization'] = `Bearer ${_token.t || token}`;
    }
  
    if (_token.bt || backendToken) {
      authHeaders['x-access-token'] = `Bearer ${_token.bt || token}`
    }
    return authHeaders;
}

const storeToken = (token) => {
  token.token && localStorage.setItem("T", token.token);
  token.backendToken && localStorage.setItem("BT", token.backendToken);
};

const getToken = () => {
  const t = localStorage.getItem("T");
  const bt = localStorage.getItem("BT");

  return { t, bt };
};

const deleteToken = () => {
  localStorage.removeItem("T");
  localStorage.removeItem("BT");
};

const errorHandler = async (error) => {
  console.log("errorHandler");
  console.log(error);
  if (error.message === 'Unauthorized') {
    console.log('Unauthorized')
    return auth.signOut();
  }

  return error.response.body;
};


const jobsUrl = `${API_ROOT}/jobs`;
const Jobs = {
  getJobs: async(query) => await axios.get(`${jobsUrl}${query}`)
};

const companiesUrl = `${API_ROOT}/companies`;
const Companies = {
  getHomeCompanies: async () => await axios.get(`${companiesUrl}/h`),
  getCompanies: async (query) => await axios.get(`${companiesUrl}${query}`), //requests.get(`/companies${query}`),
  getCompany: async (query) => { 
    const authHeadersObj = getAuthHeadersObj();
    const headers = createReqHeaders(authHeadersObj);  
    return await axios.get(`${API_ROOT}/companies/${query.companyId}`, headers);
  },
  getCompanyJobs: async (companyId, query) => { 
    return await axios.get(`${companiesUrl}/${companyId}/jobs`, { params: query }); 
  },
  getCompaniesMembership: async () => await axios.get(`${companiesUrl}/hint-profile-creation`),
  userUpdatePredefinedCompany: async (data) => { 
    const contentTypeObj = {
      [contentTypeHeader.name]: contentTypeHeader.values.multipartFormData
    };
    const headers = createReqHeaders(contentTypeObj);
    return await axios.put(`${companiesUrl}/user-update-predefined-company`, data, headers); 
  }  
};

const authUrl = `${API_ROOT}/auth`;
const Auth = {
  login: async (data, fbToken) => { 
    const headers = createReqHeaders({ 
      [firebaseKeys.accessTokenHeader]: fbToken 
    });
    return await axios.post(`${authUrl}/login`, data, headers); 
  }
};

const companyProfileUrl = `${API_ROOT}/company-profile`;
const CompanyProfile = {
  postNewCompany: async (data, fbToken) => {
      const headers = createReqHeaders({ 
        [firebaseKeys.accessTokenHeader]: fbToken,
        [contentTypeHeader.name]: contentTypeHeader.values.multipartFormData 
      });
      return await axios.post(`${companyProfileUrl}/new-company`, data, headers);
  },    
  postClaimCompanyProfile: async () => {
    const authHeadersObj = getAuthHeadersObj();
    const headers = createReqHeaders(authHeadersObj);
    return await axios.post(`${companyProfileUrl}/claim`, {}, headers); 
  },
  getCompanyProfile: async () => { 
    const authHeadersObj = getAuthHeadersObj();
    const headers = createReqHeaders(authHeadersObj);
    return await axios.get(companyProfileUrl, headers); 
  },
  updateCompanyProfile: async (data) => {
    const authHeadersObj = getAuthHeadersObj();
    const contentTypeObj = {
      [contentTypeHeader.name]: contentTypeHeader.values.multipartFormData
    };
    const headers = createReqHeaders({...authHeadersObj, ...contentTypeObj});
    return await axios.put(companyProfileUrl, data, headers);
  },
  updateCompanyProfileOnPreview: async (data) => {
    const authHeadersObj = getAuthHeadersObj();
    const contentTypeObj = {
      [contentTypeHeader.name]: contentTypeHeader.values.multipartFormData
    };
    const headers = createReqHeaders({...authHeadersObj, ...contentTypeObj});
    return await axios.put(`${companyProfileUrl}/preview`, data, headers);  
  },
  updateCompanyProfileSendJson: async (data) => {
    const authHeadersObj = getAuthHeadersObj();
    const headers = createReqHeaders(authHeadersObj);
    return await axios.put(companyProfileUrl, data, headers);
  }, 

  checkAtsDetails: async (data) => {
     return await axios.post(`${companyProfileUrl}/ats/isValid`, data);
  }
};

const companyUserUrl = `${API_ROOT}/company-user`;
const CompanyUser = {
  getCompanyUser: async () => { 
    const authHeadersObj = getAuthHeadersObj();
    const headers = createReqHeaders(authHeadersObj);
    return await axios.get(companyUserUrl, headers); 
  },
  postCompanyUser: async (companyId, data, fbToken) => { 
    const headers = createReqHeaders({ 
      [firebaseKeys.accessTokenHeader]: fbToken 
    });
    return await axios.post(`${companyUserUrl}/${companyId}`, data, headers); 
  },
  deleteCompanyUser: async (userId) => await axios.delete(`${companyUserUrl}/${userId}`),
  putCompanyUser: async (data) => {
    const authHeadersObj = getAuthHeadersObj();
    const headers = createReqHeaders(authHeadersObj); 
    return await axios.put(companyUserUrl, data, headers); 
  },
};

const Admin = {
  updateJobs: async (data) =>  await axios.post(`${API_ROOT}/admin/refresh/jobs`, data),
  updateCompanies: async (data) => await axios.post(`${API_ROOT}/admin/refresh/companies`, data)
};  

// COMPANY USER

const removeTokens = () => {
  deleteLocalStorage("companyId");
  deleteLocalStorage("newCompany");

  deleteToken();
};

export default {
  Jobs,
  Companies,
  Auth,
  CompanyProfile,
  CompanyUser,
  Admin,
  setToken: (_token) => {
    storeToken({ token: _token });
    token = _token;
  },
  setBackendToken: (_token) => {
    storeToken({ backendToken: _token });
    backendToken = _token;
  },
  removeTokens: removeTokens,
};


