function saveLocalStorage(key, value) {
    localStorage.setItem(key, value);
}

function getLocalStorage(key) {
    return localStorage.getItem(key);
}

function deleteLocalStorage(key) {
    localStorage.removeItem(key);
}

export { saveLocalStorage, getLocalStorage, deleteLocalStorage };
