import React from "react";
import { Box, Container } from "@mui/material";

import Heading from "../components/Heading";
import Section from "../components/Section";
import Text from "../components/Text";
import { Helmet } from "react-helmet";

const TermsOfUse = () => {
  return (
    <>
      <Helmet>
        <title>Deep Tech Jobs - Terms of Use</title>
      </Helmet>
      <Section padding="180px 72px 72px">
        <Container
          sx={{
            color: "#fff",
          }}
        >
          <Text fontSize="12px">Effective date: 09/05/2022 </Text>
          <Heading
            style={{ lineHeight: "1.1em" }}
            fontSize="calc((6 - 1) * 1.2vh + 1rem)"
          >
            Terms of Use
          </Heading>

          <Box sx={{ marginBottom: "60px" }}>
            <p>
              <i> For Talent </i>
            </p>

            <p>
              <i>
                We are here to help you get the most out of this site. If we can
                help you in any way, or if you have any questions, please let us
                know.{" "}
              </i>
            </p>

            <p>
              <i>
                What follows is a description of how you may use this site. We
                also discuss how we will and will not use your data.{" "}
              </i>
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>1. INTRODUCTION </Text>
            <p>
              1.1 Our aim is to provide you with information about companies and
              roles that may be relevant to you now, or that may simply be of
              interest, so that you can monitor the market.{" "}
            </p>

            <p>
              1.2 Regardless, your interactions with us are strictly
              confidential and we will never disclose your details to a Company
              without your prior express written consent.{" "}
            </p>

            <p>
              1.3 We aim to work with individuals in a straightforward fashion
              and intend this document to reflect this approach. By necessity,
              however, some of the provisions in this document use more legal
              terminology than others. This is to ensure that the legal rights
              and obligations for each of us is clear and effective, protecting
              us both.{" "}
            </p>

            <p>
              1.4 The objective of these terms is to form a legally binding
              contract between you and us that will cover the provision of all
              services (the “<b>Services</b>”) by us including, without
              limitation{" "}
            </p>

            <p>
              (a) granting you access to our website and any applications for
              smart devices published by us (together the “<b>Platform</b>”),{" "}
            </p>

            <p>
              (b) providing you with information about roles which we believe
              may be of interest to you,{" "}
            </p>

            <p>
              (c) providing you with links to the websites of the relevant
              businesses looking to recruit the roles (each an “<b>Company</b>”)
              and,{" "}
            </p>

            <p>
              (d) only with your prior express written consent, making an
              introduction of you to one or more Companies.{" "}
            </p>

            <p>
              1.5 The entity that provides the Platform and the Services is Deep
              Tech Jobs Limited (trading as ‘Deep Tech Jobs’) which is a limited
              company incorporated in England and Wales with registered number
              13163773.{" "}
            </p>

            <p>
              1.6 We refer to ourselves as “<b>Deep Tech Jobs</b>”, “<b>we</b>”
              and “<b>us</b>” and to you as the “<b>Talent</b>”, “<b>you</b>”
              and “<b>your</b>”.{" "}
            </p>

            <p>
              1.7 In these Terms, any reference to any gender includes reference
              to other genders, and a reference to a person includes a natural
              person and any corporate or unincorporated body.{" "}
            </p>

            <p>
              1.8 We may modify these Terms by giving you at least fourteen (14)
              days’ prior notice. We will notify you of any changes by emailing
              you or by posting a notice on the Platform.{" "}
            </p>

            <p>
              1.9 You may be asked to agree to these Terms again from time to
              time when they are changed.{" "}
            </p>

            <p>
              1.10 By continuing to use the Services and/or the Platform after
              changes to these Terms have been made and notified to you, you
              will be deemed to have agreed to be bound by such changes. So make
              sure to read the Terms from time to time.{" "}
            </p>
          </Box>
          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>
              2. HOW TO USE OUR SERVICES{" "}
            </Text>
            <p>
              2.1 The Platform and the Services are free to use. We will not
              charge you any fee for using our Services, including applying for
              any role brought to your attention via the Platform.{" "}
            </p>
            <p>
              2.2 You can view roles offered by Companies across the Platform.
              If you wish to apply for a role you will be directed to the
              Company’s website and may contact the Company or complete any
              application via their website. All communications between you and
              a Company take place on the Company’s website and Deep Tech Jobs
              does not receive a copy of any such communication.{" "}
            </p>
            <p>
              2.3 By using this Website, you confirm that you agree to become
              legally bound to any/all obligations in these Terms.{" "}
            </p>
          </Box>

          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>
              3. AVAILABILITY OF THE PLATFORM AND SERVICES
            </Text>
            <p>
              3.1 We may change, suspend or discontinue the Platform and/or any
              of the Services.{" "}
            </p>

            <p>
              3.2 Advertisements for roles (each an “<b>Advertisement</b>”) are
              created and provided by third parties over whom we have no
              control. You acknowledge and agree that we are not responsible for
              the content of any Advertisement including its accuracy and/or the
              lawfulness, reasonableness or appropriateness of any terms and
              conditions imposed by any Company.{" "}
            </p>

            <p>
              3.3 We do not review Advertisements and do not offer any
              representation, warranty or promise as to the suitability of any
              role for you.{" "}
            </p>

            <p>
              3.4 To apply for a role and/or find further information on a
              Company, you may be redirected to the Company’s (or some other
              third party’s) website.{" "}
            </p>

            <p>
              3.5 Applying for a role does not guarantee that you will be
              offered an interview or that you will secure the role.{" "}
            </p>

            <p>
              3.6 We may highlight roles to you by sending you an email and/or
              message. These communications may be automated or manual.{" "}
            </p>

            <p>
              3.7 We may determine which Advertisements to highlight to you on
              the basis of a variety of factors, including but not limited to
              any Profile Information we hold about you and Advertisements you
              have looked at and/or interacted with on the Platform.{" "}
            </p>

            <p>
              3.8 Even where we highlight a role to you, we assume no
              responsibility and disclaim all liability for the content,
              accuracy, completeness, legality, reliability, or availability of
              the Advertisement.{" "}
            </p>
          </Box>

          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>4. RESTRICTIONS</Text>

            <p>
              4.1 Except as expressly set out in these Terms or as permitted by
              applicable law you undertake:{" "}
            </p>

            <p>
              (a) not to use our Platform (or the software underlying our
              Platform, the “Software”) or its content other than for reviewing
              and applying for roles with Companies and keeping up-to-date on
              roles in the market for the purpose of planning and managing your
              career;{" "}
            </p>

            <p>
              (b) not to rent, lease, sub-license, loan, translate, merge,
              adapt, vary, alter or modify, the whole or any part of the
              Software or Platform nor permit the Software or any part of it to
              be combined with, or become incorporated in, any other programs;
              and/or{" "}
            </p>

            <p>
              (c) not to copy, disassemble, de-compile, reverse engineer or
              create derivative works based on the Software and/or Platform.{" "}
            </p>
          </Box>

          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>
              5. OUR USE OF YOUR PERSONAL DATA
            </Text>

            <p>
              5.1 We understand that your personal data (together the “Personal
              Information”) is important to you. Consequently it is important to
              us that you understand what we may do with Personal Information,
              and your rights with regards to your Personal Information.{" "}
            </p>

            <p>
              5.2 You will at all times own your Personal Information and you
              are responsible for its accuracy.{" "}
            </p>

            <p>
              5.3 By providing Personal Information to us, you confirm that it
              relates to you and that you are permitted to provide it to us.{" "}
            </p>

            <p>
              5.4 We will process your Personal Information in accordance with
              all applicable data protection legislation from time to time in
              force in the UK including:{" "}
            </p>

            <p>(a) the General Data Protection Regulation ((EU) 2016/679); </p>

            <p>(a) the Data Protection Act 2018; </p>

            <p>
              (a) the Privacy and Electronic Communications Directive 2002/58/EC
              (as updated by Directive 2009/136/EC) and;{" "}
            </p>

            <p>
              (a) the Privacy and Electronic Communications Regulations 2003 (SI
              2003/2426) as amended (the “Data Protection Legislation”).{" "}
            </p>

            <p>
              5.5 We are registered with the ICO in the UK as required by the
              Data Protection Act 2018, which implemented into English law the
              General Data Protection Regulation (EU) 2016/679 (‘GDPR’).{" "}
            </p>

            <p>
              5.6 In accordance with GDPR, we have appointed an EU
              representative for data protection purposes who is the point of
              contact for any data processing complaints by data subjects
              located within the EU.{" "}
            </p>

            <p>5.7 The details of our EU representative are as follows: </p>

            <p>
              (a) Buckworths (Ireland) Ltd, c/o Workhub, 77 Lower Camden Street,
              Dublin D02 XE80 Ireland{" "}
              <a
                style={{
                  color: "#edbb2a",
                  textDecoration: "underline !important",
                }}
                href="mailto: gdpr@buckworths.com"
              >
                gdpr@buckworths.com
              </a>{" "}
            </p>

            <p>
              5.8 By applying for a role, you may disclose personal data to the
              Company.{" "}
            </p>

            <p>
              5.9 We may email or message you, notifying you of Advertisements
              that we consider are of interest to you, materials that we believe
              may be of relevance to you and/or updating you on matters relating
              to the Platform. The legal condition on which we send these
              communications to you is “legitimate interest”.{" "}
            </p>

            <p>
              5.10 Each email sent to you notifying you of an Advertisement or
              containing materials we believe are of relevance to you (together
              “Information Emails”) will contain an “opt out” link. You may
              click this link at any time. If you click the link, you will no
              longer receive further Information Emails from us. However, you
              will continue to have access to our Platform and Services.{" "}
            </p>
          </Box>

          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>
              6. INTELLECTUAL PROPERTY RIGHTS
            </Text>

            <p>
              6.1 We are registered with the ICO in the UK as required by the
              Data Protection Act 2018, which implemented into English law the
              General Data Protection Regulation (EU) 2016/679 (‘GDPR’).{" "}
            </p>
            <p>
              6.2 In accordance with GDPR, we have appointed an EU
              representative for data protection purposes who is the point of
              contact for any data processing complaints by data subjects
              located within the EU.{" "}
            </p>
          </Box>

          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>
              7. HOW WE LIMIT OUR LIABILITY
            </Text>

            <p>
              7.1 We do not exclude or limit in any way our liability to you
              where it would be unlawful to do so. This includes liability for
              death or personal injury caused by our negligence or the
              negligence of our employees, agents or subcontractors or for fraud
              or fraudulent misrepresentation.{" "}
            </p>

            <p>
              7.2 You acknowledge that the Platform has not been developed to
              meet your individual requirements, and that it is therefore your
              responsibility to ensure that the device(s) via which you access
              the Platform are appropriate.{" "}
            </p>

            <p>
              7.3 You are responsible for maintaining suitable anti-virus
              software on the device(s) via which you access the Platform. We
              take reasonable steps to prevent viruses being disseminated via
              the Platform but exclude all liability for any damage caused to
              your device(s) arising from any virus or other malicious code made
              available directly or indirectly via the Platform.{" "}
            </p>

            <p>
              7.4 We have no obligation to present you with any Advertisement.
              We are not liable for any failure to present with you any
              Advertisement.{" "}
            </p>

            <p>
              7.5 We assume no responsibility, and disclaim all liability for,
              the content, accuracy, completeness, legality, reliability, or
              availability of any Advertisement, screening question, assessment
              and/or other information provided by or relating to any Company.{" "}
            </p>

            <p>
              7.6 We are not liable if any role secured by you via the Platform
              is not suitable for you, or if you suffer any loss (howsoever
              arising) by reason of viewing, applying for and/or accepting any
              role made available to you via the Platform.{" "}
            </p>

            <p>
              7.7 We may reformat the way in which you see any content in order
              to optimise it for the device(s) you are viewing such content on.
              We are not liable if the information you see as a result of us
              reformatting it is incomplete or incorrect.{" "}
            </p>

            <p>
              7.8 To apply for a role and/or find further information on a
              Company, you may be redirected to the Company’s (or some other
              third party’s) website. We are not liable for the content of any
              such website.{" "}
            </p>

            <p>
              7.9 You click any link to access such website at your own risk and
              may become subject to any terms and/or privacy policy imposed by
              that third-party.{" "}
            </p>

            <p>
              7.10 Neither Deep Tech Jobs nor any of our directors, officers,
              employees, consultants or agents will be liable to you for any
              loss, liability, damage, cost, claim, expense or inconvenience
              whatsoever suffered or incurred by you or any other third party,
              arising out of or in connection with your use of the Platform, you
              viewing any Advertisement, applying for, and/or accepting any
              role, including whether arising under contract, tort (including
              negligence), or otherwise and whether arising from any
              misrepresentation of, or by a Company, or incorrect or misleading
              information provided by a Company, or any act or omission of a
              Company, or otherwise.{" "}
            </p>

            <p>
              7.11 We will not be liable for any losses suffered by you, whether
              arising under contract, tort (including negligence) including
              without limitation loss of or corruption of data, loss of profits
              or of contracts, loss of earnings, goodwill or anticipated savings
              and damage to reputation.{" "}
            </p>

            <p>
              7.12 Our total liability to you (whether in contract, tort,
              including negligence, or otherwise) under or in connection with
              these Terms will not exceed in aggregate the sum of £500.00. You
              agree that this Section 7 sets out our entire liability to you. We
              both acknowledge and agree that the allocation of risk contained
              in this Section 7 is reasonable and is reflected in the fact that
              the Platform and Services are provided to you free of charge.{" "}
            </p>
          </Box>

          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>8. TERMINATION</Text>

            <p>
              8.1 We may terminate these Terms (including your use of the
              Platform and the Services) immediately by written notice to you if
              you commit a material or persistent breach of these Terms, or
              otherwise by giving 7 days prior written notice (which may be by
              email).{" "}
            </p>

            <p>
              8.2 Nothing shall prevent you from continuing any application you
              have made with an Company or from making any application, or
              otherwise engaging with, a Company via their own website and
              methods of communication.{" "}
            </p>
          </Box>

          <Box sx={{ marginBottom: "60px" }}>
            <Text style={{ marginBottom: "10px" }}>9. GENERAL</Text>

            <p>
              9.1 You may contact Deep Tech Jobs by sending an email to{" "}
              <a
                style={{
                  color: "#edbb2a",
                  textDecoration: "underline !important",
                }}
                href="mailto: support@deeptechjobs.com"
              >
                support@deeptechjobs.com
              </a>
              . We may communicate with you by email or other electronic
              communication unless you tell us not to.{" "}
            </p>

            <p>
              9.2 Communication by email is not entirely secure. We will not be
              liable to you for any losses you suffer by reason of corruption of
              any information contained in an electronic communication.{" "}
            </p>

            <p>
              9.3 Any failure or delay by a party to exercise any right or
              remedy provided under the Agreement or by law shall not constitute
              a waiver of that or any other right or remedy, nor shall it
              preclude or restrict the further exercise of that or any other
              right or remedy.{" "}
            </p>

            <p>
              9.4 No single or partial exercise of such right or remedy shall
              preclude or restrict the further exercise of that or any other
              right or remedy.{" "}
            </p>

            <p>
              9.5 If any provision of these Terms (or part of any provision) is
              found by any court or other authority of competent jurisdiction to
              be invalid, illegal or unenforceable, that provision or
              part-provision shall, to the extent required, be deemed not to
              form part of these Terms, and the validity and enforceability of
              the other provisions of these Terms will not be affected.{" "}
            </p>

            <p>
              9.6 We will not be liable for any delay in performing or failure
              to perform our obligations under these Terms due to causes outside
              our reasonable control.{" "}
            </p>

            <p>
              9.7 These Terms are a complete and exhaustive statement of the
              agreement between us relating to the Services. In the absence of
              fraud, all previous communications, representations and other
              arrangements, oral or written are terminated and cannot be relied
              on by either party.{" "}
            </p>

            <p>
              9.8 You shall not, without our prior written consent, assign,
              transfer, charge, mortgage, subcontract or deal in any other
              manner with all or any of your rights or obligations under these
              Terms. We may at any time assign, transfer, charge, mortgage,
              subcontract or deal in any other manner with all or any of our
              rights or obligations under these Terms.{" "}
            </p>

            <p>
              9.9 These Terms do not create a partnership between us, or
              constitute either of us as the agent for the other. Neither of us
              has authority to act in the name or on behalf of or otherwise to
              bind the other in any way.{" "}
            </p>

            <p>
              9.10 A person who is not a party to these Terms shall not have any
              rights under or in connection with them.{" "}
            </p>

            <p>
              9.11 These Terms and any dispute or claim arising out of or in
              connection with them or their subject matter, shall be governed
              by, and construed in accordance with the law of England and Wales
              and we both irrevocably submit to the exclusive jurisdiction of
              the courts of England and Wales.{" "}
            </p>
          </Box>
        </Container>
      </Section>
    </>
  );
};

export default TermsOfUse;
